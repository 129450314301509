import {
  encryptAsymmetric,
  generateAsymmetricKeys,
  type Either,
} from '@uniqkey-frontend/shared-app';
import type { IPartnerKeys, IPartnerUserKeys } from '../../../common/interfaces';
import {
  KeysManagerFailedToCreatePartnerKeysError,
  type TKeysManagerCreatePartnerKeysOperationErrors,
} from './errors';
import ApiClientsProvider from '../../../../../common/apiClientsProvider';
import { axiosInstance } from '../../../../../axios';

interface ICreatePartnerKeysOperationParams {
  partnerUserPublicKey: IPartnerUserKeys['publicKey'];
}

const createPartnerKeysOperation = async (
  params: ICreatePartnerKeysOperationParams,
): Promise<Either<IPartnerKeys, TKeysManagerCreatePartnerKeysOperationErrors>> => {
  try {
    const { partnerUserPublicKey } = params;

    const {
      privateKey: generatedPrivateKey,
      publicKey: generatedPublicKey,
    } = await generateAsymmetricKeys();

    const encryptedPrivateKey = await encryptAsymmetric({
      string: generatedPrivateKey,
      publicKey: partnerUserPublicKey,
    });

    await ApiClientsProvider.getPartnerKeysAPIClient(axiosInstance)
      .apiV1PartnerKeysPut({
        privatePartnerKey: encryptedPrivateKey,
        publicPartnerKey: generatedPublicKey,
      });

    return {
      privateKey: generatedPrivateKey,
      publicKey: generatedPublicKey,
    };
  } catch (e) {
    return new KeysManagerFailedToCreatePartnerKeysError();
  }
};

export default createPartnerKeysOperation;
