import { memo, useCallback, useState } from 'react';
import {
  WidgetContainer,
  Grid,
  Typography,
  Button,
  useTranslations,
  RenderIf,
} from '@uniqkey-frontend/shared-app';
import GroupOrganizationsSelectorModal from '../../../GroupOrganizationsSelectorModal';
import { useUser } from '../../../../../../contexts/UserContext';
import ACLEnum from '../../../../../../enums/ACLEnum';

interface IAddOrganizationToGroupWidgetNoDataProps {
  groupId: string;
  isForAdmin: boolean;
}

const AddOrganizationToGroupWidgetNoData = (props: IAddOrganizationToGroupWidgetNoDataProps) => {
  const { groupId, isForAdmin } = props;
  const { t } = useTranslations();
  const { userCan } = useUser();

  const [
    isGroupOrganizationsSelectorModalOpen,
    setIsGroupOrganizationsSelectorModalOpen,
  ] = useState(false);
  const handleGroupOrganizationsSelectorModalOpen = useCallback(
    () => setIsGroupOrganizationsSelectorModalOpen(true),
    [],
  );
  const handleGroupOrganizationsSelectorModalClose = useCallback(
    () => setIsGroupOrganizationsSelectorModalOpen(false),
    [],
  );

  return (
    <>
      <WidgetContainer
        container
        py={3}
        withShadow
        minHeight={176}
        flexDirection="column"
        justifyContent="space-between"
      >
        <Grid item>
          <Typography variant="h6">{t('addOrganizationToGroupWidget.title')}</Typography>
        </Grid>
        <Grid item container justifyContent="center">
          <Typography>{t('addOrganizationToGroupWidget.noData')}</Typography>
        </Grid>
        <Grid item container>
          <RenderIf condition={userCan(ACLEnum.GroupAddOrganizations)}>
            <Button variant="outlined" onClick={handleGroupOrganizationsSelectorModalOpen}>
              {t('addOrganizationToGroupWidget.addOrganization')}
            </Button>
          </RenderIf>
        </Grid>
      </WidgetContainer>
      {isGroupOrganizationsSelectorModalOpen && (
        <GroupOrganizationsSelectorModal
          isOpen={isGroupOrganizationsSelectorModalOpen}
          onClose={handleGroupOrganizationsSelectorModalClose}
          groupId={groupId}
          isForAdmin={isForAdmin}
        />
      )}
    </>
  );
};

export default memo(AddOrganizationToGroupWidgetNoData);
