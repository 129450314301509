import { memo, useState, useCallback } from 'react';
import {
  PanelContent,
  useTranslations,
  useSnackbar,
  Grid,
  Tooltip,
  ExportButton,
  Divider,
} from '@uniqkey-frontend/shared-app';
import useOrganizationsTable from '../../../../hooks/tables/useOrganizationsTable';
import OrganizationsTable from '../../../../components/tables/OrganizationsTable';
import { logException } from '../../../../services/sentryService';
import usePartnerOrganizationsAPI from '../../../../hooks/usePartnerOrganizationsAPI';
import DownloadCSVModal, {
  IDownloadCSVModalSubmitResult,
} from '../../../../components/DownloadCSVModal';
import useSubscribeToExportLicenseDataResultNotificationEvent
  from '../../../../hooks/useSubscribeToExportLicenseDataResultNotificationEvent';
import ACLEnum from '../../../../enums/ACLEnum';
import { useUser } from '../../../../contexts/UserContext';

interface IPartnerOrganizationsTabProps {
  partnerId: string;
}

const PartnerOrganizationsTab = (props: IPartnerOrganizationsTabProps) => {
  const { partnerId } = props;
  const { t } = useTranslations();
  const { showError } = useSnackbar();
  const { userCan } = useUser();

  const [isDownloadCSVModalOpen, setIsDownloadCSVModalOpen] = useState(false);
  const [isDownloadCSVLoading, setIsDownloadCSVLoading] = useState(false);
  const handleDownloadCSVModalOpen = useCallback(() => setIsDownloadCSVModalOpen(true), []);
  const handleDownloadCSVModalClose = useCallback(() => setIsDownloadCSVModalOpen(false), []);
  const handleExportLicenseDataSuccess = useCallback(() => {
    handleDownloadCSVModalClose();
    setIsDownloadCSVLoading(false);
  }, [handleDownloadCSVModalClose]);
  const handleExportLicenseDataError = useCallback(() => {
    setIsDownloadCSVLoading(false);
  }, []);

  const {
    selectedOrganizations,
    ...restTableProps
  } = useOrganizationsTable({
    persistentFilters: { partnerId },
    noDataMessageKey: 'partnerPage.organizationsTab.noData',
  });

  const { requestOrganizationForPartnerLicenseDataExport } = usePartnerOrganizationsAPI();

  const handleDownloadCSV = useCallback(async (value: IDownloadCSVModalSubmitResult) => {
    try {
      setIsDownloadCSVLoading(true);
      await requestOrganizationForPartnerLicenseDataExport({ partnerId, ...value });
    } catch (e) {
      showError({ text: t('common.somethingWentWrong') });
      logException(e, {
        message: 'PartnerOrganizationsTab/handleDownloadCSV exception',
      });
      setIsDownloadCSVLoading(false);
    }
  }, [partnerId, requestOrganizationForPartnerLicenseDataExport, showError, t]);

  useSubscribeToExportLicenseDataResultNotificationEvent({
    from: 'PartnerOrganizationsTab',
    onSuccess: handleExportLicenseDataSuccess,
    onError: handleExportLicenseDataError,
  });

  return (
    <PanelContent p={0}>
      {userCan(ACLEnum.OrganizationExportList) && (
        <>
          <Grid container p={1}>
            <Tooltip title={t('downloadCSVModal.tooltip')}>
              <ExportButton onClick={handleDownloadCSVModalOpen} />
            </Tooltip>
          </Grid>
          <Divider />
        </>
      )}
      <OrganizationsTable
        partnerId={partnerId}
        selectedOrganizations={selectedOrganizations}
        {...restTableProps}
      />
      {isDownloadCSVModalOpen && (
        <DownloadCSVModal
          isOpen={isDownloadCSVModalOpen}
          isLoading={isDownloadCSVLoading}
          onSubmit={handleDownloadCSV}
          onClose={handleDownloadCSVModalClose}
          partnerId={partnerId}
          selectedOrganizations={selectedOrganizations}
        />
      )}
    </PanelContent>
  );
};

export default memo(PartnerOrganizationsTab);
