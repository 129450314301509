import { useCallback, memo, SyntheticEvent } from 'react';
import {
  Panel, PanelHeader, Tabs, Tab, useTranslations, RenderIf,
} from '@uniqkey-frontend/shared-app';
import { useNavigate, useOutletContext } from 'react-router-dom';
import PageRouteEnum from '../../../../enums/PageRouteEnum';
import { publishTablePreservationReset } from '../../../../helpers/tablePreservation/reset';
import {
  useGetOrganizationById,
  useGetOrganizationEmployeeAccountById,
  useGetGroupById,
} from '../../../../hooks/reactQuery';
import ACLEnum from '../../../../enums/ACLEnum';
import { useUser } from '../../../../contexts/UserContext';
import OrganizationEmployeeDetailsTab
  from '../../../OrganizationEmployeePage/components/OrganizationEmployeeDetailsTab';
import OrganizationEmployeeLoginsTab
  from '../../../OrganizationEmployeePage/components/OrganizationEmployeeLoginsTab';
import OrganizationEmployeeAuditLogsTab
  from '../../../OrganizationEmployeePage/components/OrganizationEmployeeAuditLogsTab';

export enum GroupOrganizationEmployeeModuleTabEnum {
  Details = 'details',
  Logins = 'logins',
  AuditLogs = 'audit',
}
interface IGroupOrganizationEmployeeModuleProps {
  activeTab: GroupOrganizationEmployeeModuleTabEnum;
}
interface IGroupOrganizationEmployeeModuleContext {
  groupId: string;
  organizationId: string;
  employeeAccountId: string;
}

const GroupOrganizationEmployeeModule = (props: IGroupOrganizationEmployeeModuleProps) => {
  const { activeTab } = props;
  const { userCan } = useUser();
  const { t } = useTranslations();
  const {
    groupId,
    organizationId,
    employeeAccountId,
  } = useOutletContext<IGroupOrganizationEmployeeModuleContext>();
  const navigate = useNavigate();

  const { isLoading: isGroupLoading } = useGetGroupById(
    { groupId },
  );

  const { isLoading: isOrganizationLoading } = useGetOrganizationById(
    { organizationId },
  );

  const { isLoading: isEmployeeLoading } = useGetOrganizationEmployeeAccountById(
    { employeeAccountId },
  );

  const isLoading = isGroupLoading || isOrganizationLoading || isEmployeeLoading;

  const handleTabClick = useCallback((
    event: SyntheticEvent,
    nextActiveTab: GroupOrganizationEmployeeModuleTabEnum,
  ) => {
    const nextPath = `${
      PageRouteEnum.Groups
    }/${groupId}/${organizationId}/${employeeAccountId}/${nextActiveTab}`;
    if (activeTab !== nextActiveTab) {
      navigate(nextPath);
      return;
    }
    navigate(nextPath, { replace: true });
    publishTablePreservationReset();
  }, [groupId, organizationId, employeeAccountId, activeTab, navigate]);

  const hidePanelHeaderBottomDivider = (
    activeTab === GroupOrganizationEmployeeModuleTabEnum.Details
  );

  const canViewLogins = userCan(ACLEnum.EmployeeAccountViewVaultPasswords);
  const canViewAuditLogs = userCan(ACLEnum.EmployeeAccountViewAuditLogs);

  return (
    <Panel className="height-100-percent flex-column">
      <PanelHeader px={3} hideBottomDivider={hidePanelHeaderBottomDivider}>
        <Tabs value={activeTab} isLoading={isLoading}>
          <Tab
            label={t('organizationEmployeePage.tabs.employeeDetails')}
            value={GroupOrganizationEmployeeModuleTabEnum.Details}
            onClick={handleTabClick}
          />
          {canViewLogins && (
            <Tab
              label={t('organizationEmployeePage.tabs.logins')}
              value={GroupOrganizationEmployeeModuleTabEnum.Logins}
              onClick={handleTabClick}
            />
          )}
          {canViewAuditLogs && (
            <Tab
              label={t('organizationEmployeePage.tabs.auditLogs')}
              value={GroupOrganizationEmployeeModuleTabEnum.AuditLogs}
              onClick={handleTabClick}
            />
          )}
        </Tabs>
      </PanelHeader>
      <RenderIf condition={activeTab === GroupOrganizationEmployeeModuleTabEnum.Details}>
        <OrganizationEmployeeDetailsTab
          employeeAccountId={employeeAccountId}
          organizationId={organizationId}
        />
      </RenderIf>
      <RenderIf
        condition={canViewLogins && activeTab === GroupOrganizationEmployeeModuleTabEnum.Logins}
      >
        <OrganizationEmployeeLoginsTab employeeAccountId={employeeAccountId} />
      </RenderIf>
      <RenderIf
        condition={
          canViewAuditLogs && activeTab === GroupOrganizationEmployeeModuleTabEnum.AuditLogs
        }
      >
        <OrganizationEmployeeAuditLogsTab employeeAccountId={employeeAccountId} />
      </RenderIf>
    </Panel>
  );
};

export default memo(GroupOrganizationEmployeeModule);
