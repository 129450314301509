import { BaseEitherError } from '@uniqkey-frontend/shared-app';
import type {
  TKeysManagerFetchPartnerUserKeysOperationErrors,
} from '../fetchPartnerUserKeysOperation/errors';
import type {
  TKeysManagerCreatePartnerUserKeysOperationErrors,
} from '../createPartnerUserKeysOperation/errors';
import type {
  TKeysManagerDecryptPartnerUserPrivateKeyOperationErrors,
} from '../decryptPartnerUserPrivateKeyOperation/errors';

export class KeysManagerSetupPartnerUserKeysUnknownError extends BaseEitherError {}

export type TKeysManagerSetupPartnerUserKeysOperationErrors =
  KeysManagerSetupPartnerUserKeysUnknownError
  | TKeysManagerFetchPartnerUserKeysOperationErrors
  | TKeysManagerCreatePartnerUserKeysOperationErrors
  | TKeysManagerDecryptPartnerUserPrivateKeyOperationErrors;
