import { memo, useCallback, useState } from 'react';
import {
  PanelContent,
  Divider,
  ExportButton,
  Grid,
  Tooltip,
  useSnackbar,
  useTranslations,
} from '@uniqkey-frontend/shared-app';
import useOrganizationLicensesHistoryTable
  from '../../../../hooks/tables/useOrganizationLicensesHistoryTable';
import OrganizationLicensesHistoryTable
  from '../../../../components/tables/OrganizationLicensesHistoryTable';
import { logException } from '../../../../services/sentryService';
import usePartnerOrganizationsAPI from '../../../../hooks/usePartnerOrganizationsAPI';
import DownloadCSVModal, {
  IDownloadCSVModalSubmitResult,
} from '../../../../components/DownloadCSVModal';
import useSubscribeToExportLicenseDataResultNotificationEvent
  from '../../../../hooks/useSubscribeToExportLicenseDataResultNotificationEvent';
import ACLEnum from '../../../../enums/ACLEnum';
import { useUser } from '../../../../contexts/UserContext';

interface IOrganizationLicensesHistoryTabProps {
  organizationId: string;
}

const OrganizationLicensesHistoryTab = (props: IOrganizationLicensesHistoryTabProps) => {
  const { organizationId } = props;
  const { t } = useTranslations();
  const { showError } = useSnackbar();
  const { userCan } = useUser();

  const [isDownloadCSVModalOpen, setIsDownloadCSVModalOpen] = useState(false);
  const [isDownloadCSVLoading, setIsDownloadCSVLoading] = useState(false);
  const handleDownloadCSVModalOpen = useCallback(() => setIsDownloadCSVModalOpen(true), []);
  const handleDownloadCSVModalClose = useCallback(() => setIsDownloadCSVModalOpen(false), []);
  const handleExportLicenseDataSuccess = useCallback(() => {
    handleDownloadCSVModalClose();
    setIsDownloadCSVLoading(false);
  }, [handleDownloadCSVModalClose]);
  const handleExportLicenseDataError = useCallback(() => {
    setIsDownloadCSVLoading(false);
  }, []);

  const tableProps = useOrganizationLicensesHistoryTable({
    persistentFilters: { organizationId },
    noDataMessageKey: 'organizationPage.licensesHistoryTab.noData',
  });

  const { requestOrganizationLicenseDataExport } = usePartnerOrganizationsAPI();

  const handleDownloadCSV = useCallback(async (value: IDownloadCSVModalSubmitResult) => {
    try {
      setIsDownloadCSVLoading(true);
      await requestOrganizationLicenseDataExport(value);
    } catch (e) {
      showError({ text: t('common.somethingWentWrong') });
      logException(e, {
        message: 'OrganizationLicensesHistoryTab/handleDownloadCSV exception',
      });
      setIsDownloadCSVLoading(false);
    }
  }, [requestOrganizationLicenseDataExport, showError, t]);

  useSubscribeToExportLicenseDataResultNotificationEvent({
    from: 'OrganizationLicensesHistoryTab',
    onSuccess: handleExportLicenseDataSuccess,
    onError: handleExportLicenseDataError,
  });

  return (
    <PanelContent p={0}>
      {userCan(ACLEnum.OrganizationExportList) && (
        <>
          <Grid container p={1}>
            <Tooltip title={t('downloadCSVModal.tooltip')}>
              <ExportButton onClick={handleDownloadCSVModalOpen} />
            </Tooltip>
          </Grid>
          <Divider />
        </>
      )}
      <OrganizationLicensesHistoryTable
        {...tableProps}
      />
      {isDownloadCSVModalOpen && (
        <DownloadCSVModal
          isOpen={isDownloadCSVModalOpen}
          isLoading={isDownloadCSVLoading}
          onSubmit={handleDownloadCSV}
          onClose={handleDownloadCSVModalClose}
          organizationId={organizationId}
        />
      )}
    </PanelContent>
  );
};

export default memo(OrganizationLicensesHistoryTab);
