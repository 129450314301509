import { BaseEitherError } from '@uniqkey-frontend/shared-app';
import type {
  TKeysManagerFetchPartnerKeysOperationErrors,
} from '../fetchPartnerKeysOperation/errors';
import type {
  TKeysManagerCreatePartnerKeysOperationErrors,
} from '../createPartnerKeysOperation/errors';
import type {
  TKeysManagerDecryptPartnerPrivateKeyOperationErrors,
} from '../decryptPartnerPrivateKeyOperation/errors';

export class KeysManagerSetupPartnerKeysUnknownError extends BaseEitherError {}

export type TKeysManagerSetupPartnerKeysOperationErrors =
  KeysManagerSetupPartnerKeysUnknownError
  | TKeysManagerFetchPartnerKeysOperationErrors
  | TKeysManagerCreatePartnerKeysOperationErrors
  | TKeysManagerDecryptPartnerPrivateKeyOperationErrors;
