import createPartnerKeysOperation from './createPartnerKeysOperation';
import fetchPartnerKeysOperation from './fetchPartnerKeysOperation';
import decryptPartnerPrivateKeyOperation from './decryptPartnerPrivateKeyOperation';
import sharePartnerPrivateKeyOperation from './sharePartnerPrivateKeyOperation';
import setupPartnerKeysOperation from './setupPartnerKeysOperation';

export default {
  createPartnerKeysOperation,
  fetchPartnerKeysOperation,
  decryptPartnerPrivateKeyOperation,
  sharePartnerPrivateKeyOperation,
  setupPartnerKeysOperation,
};
