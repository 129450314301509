import { useMemo, memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TypographyWithTooltip,
  useTranslations,
  IUseTableReturn,
  IUseQueryTableFetchReturn,
  IUseTableLocalizationReturn,
  IMaterialTableOptions,
  IMaterialTableProps,
} from '@uniqkey-frontend/shared-app';
import { GetRolesResponseModel, PartnerUserRole } from '@uniqkey-backend-partner/api-client';
import { USER_ROLES_TRANSLATION_KEYS } from '../../../constants';
import PageRouteEnum from '../../../enums/PageRouteEnum';
import { TGetRolesMethod } from '../../../hooks/usePermissionsAPI/interfaces';

export interface IRolesTableRow extends GetRolesResponseModel {}

interface IRolesTableProps extends
  Omit<IUseTableReturn<IRolesTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Omit<IUseQueryTableFetchReturn<TGetRolesMethod>, 'data' | 'resetQuery' | 'isFetchedAfterMount'>
{
  roles: IUseQueryTableFetchReturn<TGetRolesMethod>['data'];
  selectedRoles: IUseTableReturn<IRolesTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
}

const TABLE_OPTIONS: IMaterialTableOptions<IRolesTableRow> = {
  selection: false,
  sorting: false,
};

const ROLES_DESCRIPTIONS_TRANSLATION_KEYS = {
  [PartnerUserRole.Admin]: 'rolesPage.userRolesDescriptions.admin',
  [PartnerUserRole.LimitedAdmin]: 'rolesPage.userRolesDescriptions.limitedAdmin',
  [PartnerUserRole.Partner]: 'rolesPage.userRolesDescriptions.partner',
  [PartnerUserRole.Support]: 'rolesPage.userRolesDescriptions.support',
  [PartnerUserRole.Audit]: 'rolesPage.userRolesDescriptions.audit',
};

const RolesTable = (props: IRolesTableProps) => {
  const {
    roles,
    isLoading,
    total,
    localization,
    activePage,
    setActivePage,
    perPage,
    onPerPageChange,
    columnOrderBy,
    onColumnOrderByChange,
    columnOrderDirection,
    onColumnOrderDirectionChange,
    selectedRoles,
    onRowSelectionChange,
    createColumns,
  } = props;

  const { t } = useTranslations();
  const navigate = useNavigate();

  const columns = useMemo(() => createColumns([
    {
      title: t('rolesPage.name'),
      field: 'role',
      render: (rowData) => {
        const translationKey = USER_ROLES_TRANSLATION_KEYS[
          rowData.role as keyof typeof USER_ROLES_TRANSLATION_KEYS
        ];
        return (
          <TypographyWithTooltip variant="body2">
            {translationKey ? t(translationKey) : rowData.role}
          </TypographyWithTooltip>
        );
      },
      width: '20%',
    },
    {
      title: t('rolesPage.description'),
      field: 'description',
      render: (rowData) => {
        const translationKey = ROLES_DESCRIPTIONS_TRANSLATION_KEYS[
          rowData.role as keyof typeof ROLES_DESCRIPTIONS_TRANSLATION_KEYS
        ];
        return (
          <TypographyWithTooltip variant="body2">
            {translationKey ? t(translationKey) : rowData.description}
          </TypographyWithTooltip>
        );
      },
      width: '80%',
    },
  ]), [createColumns, t]);

  const handleRoleRowClick = useCallback<
    NonNullable<IMaterialTableProps<IRolesTableRow>['onRowClick']>
  >((event, role) => {
    navigate(`${PageRouteEnum.Roles}/${role!.id}`);
  }, [navigate]);

  return (
    <Table
      t={t}
      totalItems={total}
      columns={columns}
      data={roles}
      isLoading={isLoading}
      options={TABLE_OPTIONS}
      activePage={activePage}
      onActivePageChange={setActivePage}
      perPage={perPage}
      onPerPageChange={onPerPageChange}
      columnOrderBy={columnOrderBy}
      onColumnOrderByChange={onColumnOrderByChange}
      columnOrderDirection={columnOrderDirection}
      onColumnOrderDirectionChange={onColumnOrderDirectionChange}
      selectedRows={selectedRoles}
      onSelectionChange={onRowSelectionChange}
      localization={localization}
      onRowClick={handleRoleRowClick}
      rowSelectByKey="id"
      rowHeight={51}
    />
  );
};

export default memo(RolesTable);
