import {
  Navigate, Outlet, useMatch, type RouteProps,
} from 'react-router-dom';
import PageRouteEnum from '../../enums/PageRouteEnum';
import { type IUserContext } from '../../contexts/UserContext';
import UnauthorizedLayout from '../../layouts/UnauthorizedLayout';

type TUnauthenticatedRouteProps = RouteProps & Pick<
  IUserContext,
  'isAuthenticated' | 'masterPasswordSet' | 'twoFactorSet' | 'loginVerified' | 'stateChanged'
> & {
  defaultAuthorizedRoute: PageRouteEnum;
}

const getRouteToRender = (navigateTo: PageRouteEnum | null) => {
  if (navigateTo) {
    return <Navigate to={navigateTo} />;
  }
  return (
    <UnauthorizedLayout>
      <Outlet />
    </UnauthorizedLayout>
  );
};

const UnauthenticatedRoute = (props: TUnauthenticatedRouteProps) => {
  const {
    isAuthenticated,
    masterPasswordSet,
    twoFactorSet,
    loginVerified,
    stateChanged,
    defaultAuthorizedRoute,
  } = props;

  const isLoginPage = !!useMatch(PageRouteEnum.Login);
  const isSetupMasterPasswordPage = !!useMatch(PageRouteEnum.SetupMasterPassword);
  const isSetupTwoFAPage = !!useMatch(PageRouteEnum.SetupTwoFA);
  const isLoginTwoFAPage = !!useMatch(PageRouteEnum.LoginTwoFA);

  if (!isAuthenticated) {
    return getRouteToRender(isLoginPage ? null : PageRouteEnum.Login);
  }

  if (stateChanged && (!masterPasswordSet || !twoFactorSet)) {
    // if state was changed and master password/2FA was reset - redirect directly to the login page
    return getRouteToRender(isLoginPage ? null : PageRouteEnum.Login);
  }

  if (!masterPasswordSet) {
    return getRouteToRender(isSetupMasterPasswordPage ? null : PageRouteEnum.SetupMasterPassword);
  }

  if (!twoFactorSet) {
    return getRouteToRender(isSetupTwoFAPage ? null : PageRouteEnum.SetupTwoFA);
  }

  if (!loginVerified) {
    return getRouteToRender(isLoginTwoFAPage ? null : PageRouteEnum.LoginTwoFA);
  }

  return getRouteToRender(defaultAuthorizedRoute);
};

export default UnauthenticatedRoute;
