import { TUseQueryOptions } from '@uniqkey-frontend/shared-app';
import { GetEmployeeAccountByIdResponse } from '@uniqkey-backend-partner/api-client';
import { useQuery, type QueryKey } from 'react-query';
import useEmployeeAccountsAPI from '../../useEmployeeAccountsAPI';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';

export const REACT_QUERY_ORGANIZATION_EMPLOYEE_KEY = [ReactQueryKeyEnum.OrganizationEmployee];

interface IUseGetOrganizationEmployeeAccountByIdParams {
  employeeAccountId: string;
}
export const useGetOrganizationEmployeeAccountById = (
  params: IUseGetOrganizationEmployeeAccountByIdParams,
  options: TUseQueryOptions<GetEmployeeAccountByIdResponse> = {},
) => {
  const { employeeAccountId } = params;
  const { getOrganizationEmployeeAccountById } = useEmployeeAccountsAPI();
  return useQuery<GetEmployeeAccountByIdResponse>(
    (REACT_QUERY_ORGANIZATION_EMPLOYEE_KEY as QueryKey[]).concat([employeeAccountId]),
    ({ signal }) => getOrganizationEmployeeAccountById(employeeAccountId, { signal }),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};
