import { memo } from 'react';
import { PanelContent } from '@uniqkey-frontend/shared-app';
import useOrganizationVerifiedDomainsTable from
  '../../../../hooks/tables/useOrganizationVerifiedDomainsTable';
import OrganizationVerifiedDomainsTable from
  '../../../../components/tables/OrganizationVerifiedDomainsTable';

interface IOrganizationVerifiedDomainsTabProps {
  organizationId: string;
}

const OrganizationVerifiedDomainsTab = (props: IOrganizationVerifiedDomainsTabProps) => {
  const { organizationId } = props;

  const tableProps = useOrganizationVerifiedDomainsTable({
    persistentFilters: { organizationId },
    noDataMessageKey: 'table.noData.default',
  });

  return (
    <PanelContent p={0}>
      <OrganizationVerifiedDomainsTable
        {...tableProps}
      />
    </PanelContent>
  );
};

export default memo(OrganizationVerifiedDomainsTab);
