import { useCallback, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  useSnackbar,
  useTranslations,
  validateEmail,
  Panel,
  InputAdornment,
  IconButton,
  ClosedEyeIcon,
  EyeIcon,
  LogoIcon,
  B1,
  ReactHookFormTextField,
  BaseEitherError,
} from '@uniqkey-frontend/shared-app';
import { Message, useForm } from 'react-hook-form';
import { login } from '../../services/authService';
import { logException } from '../../services/sentryService';
import useAuthAPI from '../../hooks/useAuthAPI';
import KeysManager from '../../services/keysManager';
import { isAdmin } from '../../helpers/userRole';

interface ILoginForm {
  email: string;
  password: string;
}

const LoginPage = () => {
  const { t } = useTranslations();
  const { showError } = useSnackbar();
  const { fetchMe } = useAuthAPI();
  const [isLoading, setIsLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const {
    handleSubmit, formState: { errors }, control,
  } = useForm<ILoginForm>({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const handleOnSubmit = useCallback(async (form: ILoginForm) => {
    try {
      setIsLoading(true);
      const { email, password } = form;
      await login({
        email: email.toLowerCase().trim(),
        password,
      });
      const { id, masterPasswordSet, role } = await fetchMe();
      if (!masterPasswordSet) {
        return;
      }
      const setupPartnerUserKeysResult = await KeysManager
        .PartnerUserKeys.setupPartnerUserKeysOperation({ userId: id, password });
      if (setupPartnerUserKeysResult instanceof BaseEitherError) {
        logException(setupPartnerUserKeysResult, {
          message: 'LoginPage/handleOnSubmit/setupPartnerUserKeysOperation exception',
        });
        return;
      }
      if (!isAdmin(role)) {
        return;
      }
      const {
        privateKey: partnerUserPrivateKey,
        publicKey: partnerUserPublicKey,
      } = setupPartnerUserKeysResult;
      const setupPartnerKeysResult = await KeysManager.PartnerKeys.setupPartnerKeysOperation({
        partnerUserPrivateKey,
        partnerUserPublicKey,
      });
      if (setupPartnerKeysResult instanceof BaseEitherError) {
        logException(setupPartnerKeysResult, {
          message: 'LoginPage/handleOnSubmit/setupPartnerKeysOperation exception',
        });
      }
    } catch (e) {
      setIsLoading(false);
      showError({ text: t('toasts.usernameOrEmailIsIncorrect') });
      logException(e, {
        message: 'LoginPage/handleOnSubmit exception',
      });
    }
  }, [fetchMe, showError, t]);

  const toggleShowPassword = useCallback(() => setShowPassword(!showPassword), [showPassword]);

  return (
    <Grid
      container
      className="min-height-100-vh"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Grid item xs={5}>
        <Panel>
          <form name="login" onSubmit={handleSubmit(handleOnSubmit)}>
            <Box p={4}>
              <Grid container alignItems="center" justifyContent="center">
                <LogoIcon height={70} width={250} color={B1} />
              </Grid>
              <Box mt={4} />
              <Grid container alignItems="center" justifyContent="center" rowSpacing={4}>
                <Grid item xs={12}>
                  <ReactHookFormTextField
                    name="email"
                    control={control}
                    fullWidth
                    error={!!errors.email?.message}
                    label={t('loginPage.email')}
                    helperText={errors.email?.message}
                    rules={{
                      required: t('validation.required'),
                      validate: (email): Message | boolean => {
                        const isValid = validateEmail(email);
                        if (!isValid) {
                          return t('validation.invalidFormat');
                        }
                        return true;
                      },
                    }}
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ReactHookFormTextField
                    name="password"
                    control={control}
                    fullWidth
                    type={showPassword ? 'text' : 'password'}
                    error={!!errors.password?.message}
                    label={t('loginPage.password')}
                    helperText={errors.password?.message}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton edge="end" onClick={toggleShowPassword}>
                            {showPassword ? <ClosedEyeIcon /> : <EyeIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    rules={{ required: t('validation.required') }}
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Button isLoading={isLoading} fullWidth type="submit">
                    {t('loginPage.login')}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Panel>
      </Grid>
    </Grid>
  );
};

export default LoginPage;
