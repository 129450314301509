import type { Either } from '@uniqkey-frontend/shared-app';
import type {
  GetUsersPublicKeysRequest,
  GetUsersPublicKeysResponse,
} from '@uniqkey-backend-partner/api-client';
import {
  KeysManagerFetchPartnerUsersPublicKeysError,
  type TKeysManagerFetchPartnerUsersPublicKeysOperationErrors,
} from './errors';
import ApiClientsProvider from '../../../../../common/apiClientsProvider';
import { axiosInstance } from '../../../../../axios';
import { dataExtractor } from '../../../../../helpers/apiClients';

interface IFetchPartnerUsersPublicKeysOperationParams {
  publicUserIds: GetUsersPublicKeysRequest['publicUserIds'];
}

export const fetchPartnerUsersPublicKeysOperation = async (
  params: IFetchPartnerUsersPublicKeysOperationParams,
): Promise<
  Either<GetUsersPublicKeysResponse, TKeysManagerFetchPartnerUsersPublicKeysOperationErrors>
> => {
  try {
    const { publicUserIds } = params;
    const result = await ApiClientsProvider.getPartnerUserKeysAPIClient(axiosInstance)
      .apiV1PartnerUserKeysGetUsersPublicKeysPost({ publicUserIds })
      .then(dataExtractor<GetUsersPublicKeysResponse>());
    return result;
  } catch (e) {
    throw new KeysManagerFetchPartnerUsersPublicKeysError();
  }
};

export default fetchPartnerUsersPublicKeysOperation;
