import { useQuery } from 'react-query';
import { TUseQueryOptions } from '@uniqkey-frontend/shared-app';
import { AccessRightsResponse, MeResponse } from '@uniqkey-backend-partner/api-client';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';
import useAuthAPI from '../../useAuthAPI';

export const REACT_QUERY_CURRENT_USER_KEY = [ReactQueryKeyEnum.CurrentUser];

export const REACT_QUERY_ACLS_KEY = [ReactQueryKeyEnum.ACLs];

export const useGetCurrentUser = (
  options: TUseQueryOptions<MeResponse> = {},
) => {
  const { fetchMe } = useAuthAPI();
  return useQuery<MeResponse>(
    REACT_QUERY_CURRENT_USER_KEY,
    ({ signal }) => fetchMe({ signal }),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};

export const useGetCurrentUserACLs = (
  options: TUseQueryOptions<AccessRightsResponse> = {},
) => {
  const { getCurrentUserACLs } = useAuthAPI();
  return useQuery<AccessRightsResponse>(
    REACT_QUERY_ACLS_KEY,
    ({ signal }) => getCurrentUserACLs({ signal }),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};
