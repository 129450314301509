import { Outlet, useParams } from 'react-router-dom';
import { Grid } from '@uniqkey-frontend/shared-app';
import GroupOrganizationPageBreadcrumbs from './components/GroupOrganizationPageBreadcrumbs';

const GroupOrganizationPage = () => {
  const params = useParams();
  const {
    groupId,
    organizationId,
  } = params;

  return (
    <Grid container flexDirection="column" className="height-100-percent">
      <Grid item mb={1}>
        <GroupOrganizationPageBreadcrumbs
          groupId={groupId!}
          organizationId={organizationId!}
        />
      </Grid>
      <Grid item xs>
        <Outlet context={{ groupId, organizationId }} />
      </Grid>
    </Grid>
  );
};

export default GroupOrganizationPage;
