import { Navigate, Outlet, type RouteProps } from 'react-router-dom';
import PageRouteEnum from '../../enums/PageRouteEnum';
import { type IUserContext } from '../../contexts/UserContext';
import AuthorizedLayout from '../../layouts/AuthorizedLayout';

type TAuthenticatedRouteProps = RouteProps & Pick<
  IUserContext, 'isAuthenticated' | 'masterPasswordSet' | 'twoFactorSet' | 'loginVerified'
> & {
  withLayout: boolean;
}

const AuthenticatedRoute = (props: TAuthenticatedRouteProps) => {
  const {
    isAuthenticated,
    masterPasswordSet,
    twoFactorSet,
    loginVerified,
    withLayout,
  } = props;

  if (!isAuthenticated || !masterPasswordSet || !twoFactorSet || !loginVerified) {
    return <Navigate to={PageRouteEnum.Login} />;
  }

  if (withLayout) {
    return (
      <AuthorizedLayout>
        <Outlet />
      </AuthorizedLayout>
    );
  }

  return (
    <Outlet />
  );
};

export default AuthenticatedRoute;
