import { memo, useCallback, useState } from 'react';
import {
  ListItem,
  Grid,
  S5,
  IconButton,
  Divider,
  CloseIcon,
  B1,
  PromptModal,
  useTranslations,
  useSnackbar,
  TypographyWithTooltip,
  RenderIf,
} from '@uniqkey-frontend/shared-app';
import { GroupLatestOrganizationsResponseModel } from '@uniqkey-backend-partner/api-client';
import { useNavigate } from 'react-router-dom';
import { logException } from '../../../../../../../../services/sentryService';
import { useRemoveOrganizationsFromGroup } from '../../../../../../../../hooks/reactQuery';
import PageRouteEnum from '../../../../../../../../enums/PageRouteEnum';
import ACLEnum from '../../../../../../../../enums/ACLEnum';
import { useUser } from '../../../../../../../../contexts/UserContext';

interface IAddOrganizationToGroupWidgetListItemProps {
  organization: GroupLatestOrganizationsResponseModel;
  groupId: string;
}

const ICON_BUTTON_SX = { padding: 0 };
const GRID_SX = { cursor: 'pointer' };

const AddOrganizationToGroupWidgetListItem = (
  props: IAddOrganizationToGroupWidgetListItemProps,
) => {
  const { organization, groupId } = props;
  const { name, organizationId } = organization;
  const navigate = useNavigate();
  const { userCan } = useUser();

  const { t } = useTranslations();
  const { showError, showSuccess } = useSnackbar();
  const {
    mutate: mutateRemoveOrganizationFromGroup,
    isLoading: isRemoveOrganizationFromGroupLoading,
  } = useRemoveOrganizationsFromGroup({ isForAdmin: false });

  const [
    isRemoveOrganizationFromGroupModalOpen,
    setIsRemoveOrganizationFromGroupModalOpen,
  ] = useState(false);
  const handleRemoveOrganizationFromGroupModalOpen = useCallback(
    () => setIsRemoveOrganizationFromGroupModalOpen(true),
    [],
  );
  const handleRemoveOrganizationFromGroupModalClose = useCallback(
    () => setIsRemoveOrganizationFromGroupModalOpen(false),
    [],
  );

  const handleRemoveOrganizationFromGroup = useCallback(async () => {
    mutateRemoveOrganizationFromGroup(
      { groupId, organizationIds: [organizationId] },
      {
        onSuccess: () => {
          showSuccess({ text: t('removeOrganizationFromGroupModal.successMessage') });
          handleRemoveOrganizationFromGroupModalClose();
        },
        onError: (e) => {
          showError({ text: t('removeOrganizationFromGroupModal.errorMessage') });
          logException(e, {
            message: 'AddOrganizationToGroupWidget/handleRemoveOrganizationFromGroup exception',
          });
        },
      },
    );
  }, [
    mutateRemoveOrganizationFromGroup,
    handleRemoveOrganizationFromGroupModalClose,
    groupId,
    organizationId,
    showError,
    showSuccess,
    t,
  ]);

  const handleNavigate = useCallback(() => {
    navigate(`${PageRouteEnum.Groups}/${groupId}/${organizationId}`);
  }, [groupId, navigate, organizationId]);

  return (
    <>
      <ListItem>
        <Grid item container>
          <Grid item container justifyContent="space-between" wrap="nowrap" mt={1}>
            <Grid item xs sx={GRID_SX} onClick={handleNavigate}>
              <TypographyWithTooltip variant="body2" color={S5} cursorPointer breakWord>
                {name}
              </TypographyWithTooltip>
            </Grid>
            <RenderIf condition={userCan(ACLEnum.GroupRemoveOrganizations)}>
              <IconButton
                disabled={isRemoveOrganizationFromGroupLoading}
                onClick={handleRemoveOrganizationFromGroupModalOpen}
                sx={ICON_BUTTON_SX}
              >
                <CloseIcon color={B1} width={16} height={16} />
              </IconButton>
            </RenderIf>
          </Grid>
          <Grid item xs={12} mt={1}>
            <Divider />
          </Grid>
        </Grid>
      </ListItem>
      {isRemoveOrganizationFromGroupModalOpen && (
        <PromptModal
          open={isRemoveOrganizationFromGroupModalOpen}
          onClose={handleRemoveOrganizationFromGroupModalClose}
          onSubmit={handleRemoveOrganizationFromGroup}
          title={t('removeOrganizationFromGroupModal.title')}
          description={t('removeOrganizationFromGroupModal.description', { name })}
          approvalButtonText="common.remove"
          isLoading={isRemoveOrganizationFromGroupLoading}
        />
      )}
    </>
  );
};

export default memo(AddOrganizationToGroupWidgetListItem);
