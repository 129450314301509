import { Outlet, useParams } from 'react-router-dom';
import { Grid } from '@uniqkey-frontend/shared-app';
import GroupPageBreadcrumbs from './components/GroupPageBreadcrumbs';

const GroupPage = () => {
  const params = useParams();
  const { groupId } = params;

  return (
    <Grid container flexDirection="column" className="height-100-percent">
      <Grid item mb={1}>
        <GroupPageBreadcrumbs groupId={groupId!} />
      </Grid>
      <Grid item xs>
        <Outlet context={{ groupId }} />
      </Grid>
    </Grid>
  );
};

export default GroupPage;
