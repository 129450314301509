import { useCallback, useState } from 'react';
import {
  Grid,
  Panel,
  Box,
  Button,
  OrganizationIcon,
  FilterButton,
  useTranslations,
  useSnackbar,
  usePopper,
  SearchableTextField,
  Tooltip,
  ISearchableTextFieldProps,
  IFilterButtonProps,
} from '@uniqkey-frontend/shared-app';
import { CreatePartnerOrganizationRequest } from '@uniqkey-backend-partner/api-client';
import usePartnerOrganizationsAPI from '../../hooks/usePartnerOrganizationsAPI';
import useOrganizationsExtendedTable from '../../hooks/tables/useOrganizationsExtendedTable';
import { useLanguages } from '../../hooks/reactQuery';
import OrganizationsExtendedTable from '../../components/tables/OrganizationsExtendedTable';
import CreateOrganizationModal from '../OrganizationsPage/components/CreateOrganizationModal';
import OrganizationsExtendedListFilter, {
  IOrganizationsExtendedListFilterSubmitResult,
} from './components/OrganizationsExtendedListFilter';
import { logException } from '../../services/sentryService';

const FIRST_ADMIN_NOT_ACTIVATED_ERROR = 'First_admin_not_activated';

const OrganizationsExtendedPage = () => {
  const [isCreateOrganizationModalOpen, setIsCreateOrganizationModalOpen] = useState(false);
  const handleCreateOrganizationModalOpen = useCallback(
    () => setIsCreateOrganizationModalOpen(true),
    [],
  );
  const handleCreateOrganizationModalClose = useCallback(
    () => setIsCreateOrganizationModalOpen(false),
    [],
  );
  const [isCreateOrganizationLoading, setIsCreateOrganizationLoading] = useState(false);

  // preload for create organization modal
  useLanguages({ includeOnlyAllowedLanguages: false });

  const {
    isOpen: isFilterOpen,
    anchorEl: filterAnchorEl,
    setPopperIsOpen: setIsFilterOpen,
  } = usePopper();
  const toggleIsFilterOpen = useCallback<NonNullable<IFilterButtonProps['onChange']>>(
    (event) => setIsFilterOpen(!isFilterOpen, event),
    [setIsFilterOpen, isFilterOpen],
  );

  const handleFilterClose = useCallback(
    () => setIsFilterOpen(false),
    [setIsFilterOpen],
  );

  const { t } = useTranslations();
  const { createOrganization } = usePartnerOrganizationsAPI();
  const { showSuccess, showError } = useSnackbar();

  const {
    selectedOrganizationsExtended,
    searchQuery,
    setSearchQuery,
    filterValues,
    setFilterValues,
    isFilterActive,
    numberOfActiveFilters,
    resetQuery,
    resetActivePage,
    ...restTableProps
  } = useOrganizationsExtendedTable({
    noDataMessageKey: 'organizationsPage.noData',
  });

  const handleCreateOrganization = useCallback(async (
    organization: CreatePartnerOrganizationRequest,
  ) => {
    try {
      setIsCreateOrganizationLoading(true);
      await createOrganization(organization);
      showSuccess({
        text: t('createOrganizationModal.organizationCreated'),
      });
      handleCreateOrganizationModalClose();
      resetQuery();
    } catch (e: any) {
      let key = 'common.somethingWentWrong';
      if (e?.response?.data?.includes(FIRST_ADMIN_NOT_ACTIVATED_ERROR)) {
        key = 'createOrganizationModal.firstAdminNotActivatedError';
      }
      showError({ text: t(key) });
      logException(e, {
        message: 'OrganizationsExtendedPage/handleCreateOrganization exception',
      });
    } finally {
      setIsCreateOrganizationLoading(false);
    }
  }, [
    createOrganization,
    handleCreateOrganizationModalClose,
    resetQuery,
    showError,
    showSuccess,
    t,
  ]);

  const handleSearchChange = useCallback<ISearchableTextFieldProps['onChange']>(
    (debouncedValue) => {
      setSearchQuery(debouncedValue);
      resetActivePage();
    },
    [setSearchQuery, resetActivePage],
  );

  const handleFilterSubmit = useCallback((
    updatedValues: IOrganizationsExtendedListFilterSubmitResult,
  ) => {
    setFilterValues(updatedValues);
    resetActivePage();
  }, [setFilterValues, resetActivePage]);

  return (
    <Grid container flexDirection="column" className="min-height-100-percent">
      <Grid item mb={1}>
        <Panel>
          <Box p={1}>
            <Grid container justifyContent="space-between" alignItems="stretch">
              <Grid item xs={3} container flexWrap="nowrap">
                <Grid item>
                  <Tooltip title={t('common.filter')}>
                    <FilterButton
                      isFilterActive={isFilterActive}
                      numberOfActiveFilters={numberOfActiveFilters}
                      selected={isFilterOpen}
                      onChange={toggleIsFilterOpen}
                    />
                  </Tooltip>
                </Grid>
                {/* TODO: actions here */}
              </Grid>
              <Grid item xs={9} container justifyContent="flex-end" flexWrap="nowrap">
                <Grid item>
                  <SearchableTextField
                    value={searchQuery}
                    onChange={handleSearchChange}
                    placeholder={t('common.search')}
                  />
                </Grid>
                <Box mr={3} />
                <Grid item>
                  <Button
                    icon={<OrganizationIcon />}
                    onClick={handleCreateOrganizationModalOpen}
                  >
                    {t('organizationsExtendedPage.createOrganizationButton')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Panel>
      </Grid>
      <Grid item xs>
        <Panel>
          <OrganizationsExtendedTable
            selectedOrganizationsExtended={selectedOrganizationsExtended}
            {...restTableProps}
          />
        </Panel>
      </Grid>
      {isCreateOrganizationModalOpen && (
        <CreateOrganizationModal
          isOpen={isCreateOrganizationModalOpen}
          isLoading={isCreateOrganizationLoading}
          onSubmit={handleCreateOrganization}
          onClose={handleCreateOrganizationModalClose}
        />
      )}
      <OrganizationsExtendedListFilter
        isOpen={isFilterOpen}
        anchorEl={filterAnchorEl}
        onSubmit={handleFilterSubmit}
        onClose={handleFilterClose}
        initialValues={filterValues}
      />
    </Grid>
  );
};

export default OrganizationsExtendedPage;
