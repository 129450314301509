/* eslint-disable max-len */
enum PageRouteEnum {
  Login = '/login',
  SetupMasterPassword = '/setup-password',
  SetupTwoFA = '/setup-2fa',
  LoginTwoFA = '/login-2fa',
  SetupPermanentPassword = '/setup-password',
  Partners = '/partners',
  Partner = `${PageRouteEnum.Partners}/:partnerId/*`,
  PartnerOrganization = `${PageRouteEnum.Partners}/:partnerId/:organizationId/*`,
  PartnersDeep = `${PageRouteEnum.Partners}/deep`,
  PartnerDeep = `${PageRouteEnum.PartnersDeep}/:partnerId1/:partnerId2/:partnerId3/*`,
  Organizations = '/organizations',
  Organization = `${PageRouteEnum.Organizations}/:organizationId/*`,
  Groups = '/groups',
  Group = `${PageRouteEnum.Groups}/:groupId/*`,
  GroupOrganization = `${PageRouteEnum.Groups}/:groupId/:organizationId/*`,
  GroupOrganizationEmployee = `${PageRouteEnum.Groups}/:groupId/:organizationId/:employeeAccountId/*`,
  Employee = '/employee',
  OrganizationEmployee = `${PageRouteEnum.Employee}/:organizationId/:employeeAccountId/*`,
  PartnerOrganizationEmployee = `${PageRouteEnum.Partners}/:partnerId/:organizationId/:employeeAccountId/*`,
  User = '/user',
  PartnerPartnerUser = `${PageRouteEnum.User}/:partnerId1/:partnerId2/:partnerId3/:partnerUserId/*`,
  PartnerUsers = '/partner-users',
  PartnerUser = `${PageRouteEnum.PartnerUsers}/:partnerUserId/*`,
  Audit = '/audit',
  Profile = '/profile',
  Roles = '/roles',
  Role = `${PageRouteEnum.Roles}/:role`,
}

export default PageRouteEnum;
