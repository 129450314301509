import { createContext, useContext } from 'react';
import {
  PartnerOrganizationsApi,
  PartnersApi,
  PartnerUsersApi,
  ContactPersonApi,
  LoginApi,
  AuditLogApi,
  UserProfileApi,
  EmployeeAccountsApi,
  PermissionsApi,
  GroupsApi,
  PartnerUserKeysApi,
  PartnerKeysApi,
} from '@uniqkey-backend-partner/api-client';
import { AxiosInstance } from 'axios';
import { axiosInstance } from '../../axios';
import config from '../../config';
import ApiClientsProvider from '../../common/apiClientsProvider';

const PARTNER_API_BASE_URL = config.getPartnerApiUrl();

export interface IAPIClientsContext {
  axiosInstance: AxiosInstance;
  partnerOrganizationsAPIClient: PartnerOrganizationsApi;
  partnerUsersAPIClient: PartnerUsersApi;
  partnersAPIClient: PartnersApi;
  contactPersonAPIClient: ContactPersonApi;
  authAPIClient: LoginApi;
  auditLogsAPIClient: AuditLogApi;
  userProfileAPIClient: UserProfileApi;
  employeeAccountsAPIClient: EmployeeAccountsApi;
  permissionsAPIClient: PermissionsApi;
  groupsAPIClient: GroupsApi;
  partnerUserKeysAPIClient: PartnerUserKeysApi;
  partnerKeysAPIClient: PartnerKeysApi;
}

export const APIClientDefaultContext: IAPIClientsContext = {
  axiosInstance,
  partnerOrganizationsAPIClient: new PartnerOrganizationsApi(
    undefined,
    PARTNER_API_BASE_URL,
    axiosInstance,
  ),
  partnerUsersAPIClient: new PartnerUsersApi(
    undefined,
    PARTNER_API_BASE_URL,
    axiosInstance,
  ),
  partnersAPIClient: new PartnersApi(
    undefined,
    PARTNER_API_BASE_URL,
    axiosInstance,
  ),
  contactPersonAPIClient: new ContactPersonApi(
    undefined,
    PARTNER_API_BASE_URL,
    axiosInstance,
  ),
  authAPIClient: new LoginApi(
    undefined,
    PARTNER_API_BASE_URL,
    axiosInstance,
  ),
  auditLogsAPIClient: new AuditLogApi(
    undefined,
    PARTNER_API_BASE_URL,
    axiosInstance,
  ),
  userProfileAPIClient: new UserProfileApi(
    undefined,
    PARTNER_API_BASE_URL,
    axiosInstance,
  ),
  employeeAccountsAPIClient: new EmployeeAccountsApi(
    undefined,
    PARTNER_API_BASE_URL,
    axiosInstance,
  ),
  permissionsAPIClient: new PermissionsApi(
    undefined,
    PARTNER_API_BASE_URL,
    axiosInstance,
  ),
  groupsAPIClient: new GroupsApi(
    undefined,
    PARTNER_API_BASE_URL,
    axiosInstance,
  ),
  partnerUserKeysAPIClient: ApiClientsProvider.getPartnerUserKeysAPIClient(axiosInstance),
  partnerKeysAPIClient: ApiClientsProvider.getPartnerKeysAPIClient(axiosInstance),
};

const ApiClientsContext = createContext<IAPIClientsContext>(APIClientDefaultContext);

ApiClientsContext.displayName = 'APIClientsContext';

export default ApiClientsContext;

export const useApiClients = (): IAPIClientsContext => useContext<IAPIClientsContext>(
  ApiClientsContext,
);
