import {
  BaseEitherError,
  type Either,
} from '@uniqkey-frontend/shared-app';
import type { IPartnerKeys, IPartnerUserKeys } from '../../../common/interfaces';
import {
  KeysManagerSetupPartnerKeysUnknownError,
  type TKeysManagerSetupPartnerKeysOperationErrors,
} from './errors';
import Operations from '../index';

interface ISetupPartnerKeysOperationParams {
  partnerUserPrivateKey: IPartnerUserKeys['privateKey'];
  partnerUserPublicKey: IPartnerUserKeys['publicKey'];
}

/**
 * @returns {string} privateKey - the current user has access to partner keys
 * @returns {null} privateKey - the current user doesn't have access to partner keys
 * */
const setupPartnerKeysOperation = async (
  params: ISetupPartnerKeysOperationParams,
): Promise<Either<IPartnerKeys, TKeysManagerSetupPartnerKeysOperationErrors>> => {
  try {
    const { partnerUserPrivateKey, partnerUserPublicKey } = params;

    const fetchPartnerKeysResult = await Operations.fetchPartnerKeysOperation();
    if (fetchPartnerKeysResult instanceof BaseEitherError) {
      const createPartnerKeysResult = await Operations.createPartnerKeysOperation({
        partnerUserPublicKey,
      });
      return createPartnerKeysResult;
    }

    const { privateKey: encryptedPrivateKey, publicKey } = fetchPartnerKeysResult;
    if (encryptedPrivateKey === null) { // no access to partner keys
      return {
        privateKey: encryptedPrivateKey,
        publicKey,
      };
    }

    // has access to partner keys
    const decryptPartnerPrivateKeyResult = await Operations.decryptPartnerPrivateKeyOperation({
      encryptedPrivateKey,
      partnerUserPrivateKey,
      partnerUserPublicKey,
    });
    if (decryptPartnerPrivateKeyResult instanceof BaseEitherError) {
      const createPartnerKeysResult = await Operations.createPartnerKeysOperation({
        partnerUserPublicKey,
      });
      return createPartnerKeysResult;
    }

    const { privateKey: decryptedPrivateKey } = decryptPartnerPrivateKeyResult;

    return {
      privateKey: decryptedPrivateKey,
      publicKey,
    };
  } catch (e) {
    return new KeysManagerSetupPartnerKeysUnknownError();
  }
};

export default setupPartnerKeysOperation;
