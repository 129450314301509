import { useMemo, memo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BreadcrumbsContainer,
  Breadcrumbs,
  useSnackbar,
  useTranslations,
} from '@uniqkey-frontend/shared-app';
import { GetPartnerByIdResponse } from '@uniqkey-backend-partner/api-client';
import PageRouteEnum from '../../../../enums/PageRouteEnum';
import { useGetPartnerUserById, useGetPartnerById } from '../../../../hooks/reactQuery';
import { parseIdParam } from '../../../../helpers/parseIdParam';

interface IPartnerPartnerUserPageBreadcrumbsProps {
  partnerId1: string;
  partnerId2: string;
  partnerId3: string;
  partnerUserId: string;
}

interface IPartnerUserConfig {
  url: string;
  partnerInfo: GetPartnerByIdResponse;
}

const PartnerPartnerUserPageBreadcrumbs = (props: IPartnerPartnerUserPageBreadcrumbsProps) => {
  const {
    partnerId1, partnerId2, partnerId3, partnerUserId,
  } = props;
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { showError } = useSnackbar();

  const { parsedPartnerId1, parsedPartnerId2, parsedPartnerId3 } = useMemo(() => ({
    parsedPartnerId1: parseIdParam(partnerId1),
    parsedPartnerId2: parseIdParam(partnerId2),
    parsedPartnerId3: parseIdParam(partnerId3),
  }), [partnerId1, partnerId2, partnerId3]);

  const {
    data: partner1,
    isLoading: isLoading1,
    isError: isPartnerError1,
  } = useGetPartnerById(
    { partnerId: partnerId1 },
    { enabled: !!parsedPartnerId1 },
  );

  const {
    data: partner2,
    isLoading: isLoading2,
    isError: isPartnerError2,
  } = useGetPartnerById(
    { partnerId: partnerId2 },
    { enabled: !!parsedPartnerId2 },
  );

  const {
    data: partner3,
    isLoading: isLoading3,
    isError: isPartnerError3,
  } = useGetPartnerById(
    { partnerId: partnerId3 },
    { enabled: !!parsedPartnerId3 },
  );

  const {
    data: partnerUser,
    isLoading: isPartnerUserLoading,
    isError: isPartnerUserError,
  } = useGetPartnerUserById(
    { partnerUserId },
  );

  const isLoading = isLoading1 || isLoading2 || isLoading3 || isPartnerUserLoading;
  const isPartnerError = isPartnerError1 || isPartnerError2 || isPartnerError3;

  const breadcrumbsNodes = useMemo(() => {
    if (isLoading || !partnerUser) {
      return [];
    }
    const partnerUsersHomeNode = {
      title: t('navigation.partners'),
      onClick: () => navigate(PageRouteEnum.Partners),
    };
    const partnerUsersLastNode = {
      title: partnerUser.name,
    };
    const partnersNode = [
      {
        partnerInfo: partner1,
        url: `${PageRouteEnum.Partners}/${partnerId1}`,
      },
      {
        partnerInfo: partner2,
        url: `${PageRouteEnum.PartnersDeep}/${partnerId1}/${partnerId2}/${null}`,
      },
      {
        partnerInfo: partner3,
        url: `${PageRouteEnum.PartnersDeep}/${partnerId1}/${partnerId2}/${partnerId3}`,
      },
    ].filter((partnerConfig): partnerConfig is IPartnerUserConfig => !!partnerConfig.partnerInfo)
      .map((partnerConfig) => ({
        title: partnerConfig.partnerInfo.name,
        onClick: () => navigate(`${partnerConfig.url}`),
      }));
    return [
      partnerUsersHomeNode,
      ...partnersNode,
      partnerUsersLastNode,
    ];
  }, [
    isLoading,
    partner1,
    partner2,
    partner3,
    partnerId1,
    partnerId2,
    partnerId3,
    partnerUser,
    navigate,
    t,
  ]);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (isPartnerError) {
      showError({ text: t('common.somethingWentWrong') });
      navigate(PageRouteEnum.Partners);
      return;
    }
    if (isPartnerUserError) {
      showError({ text: t('common.somethingWentWrong') });
      navigate(`${PageRouteEnum.Partners}/${partnerId1}`);
    }
  }, [isLoading, isPartnerError, isPartnerUserError, partnerId1, navigate, showError, t]);

  return (
    <BreadcrumbsContainer>
      <Breadcrumbs
        nodes={breadcrumbsNodes}
        isLoading={isLoading}
      />
    </BreadcrumbsContainer>
  );
};

export default memo(PartnerPartnerUserPageBreadcrumbs);
