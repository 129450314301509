import {
  useCallback, memo, useMemo, SyntheticEvent,
} from 'react';
import {
  Panel, PanelHeader, Tabs, Tab, useTranslations, RenderIf,
} from '@uniqkey-frontend/shared-app';
import { useNavigate, useOutletContext } from 'react-router-dom';
import PageRouteEnum from '../../../../enums/PageRouteEnum';
import { publishTablePreservationReset } from '../../../../helpers/tablePreservation/reset';
import { useGetPartnerById } from '../../../../hooks/reactQuery';
import ACLEnum from '../../../../enums/ACLEnum';
import PartnerDetailsTab from '../../../PartnerPage/components/PartnerDetailsTab';
import PartnerOrganizationsTab from '../../../PartnerPage/components/PartnerOrganizationsTab';
import PartnerLicensesHistoryTab from '../../../PartnerPage/components/PartnerLicensesHistoryTab';
import PartnerPartnerUsersTab from '../../../PartnerPage/components/PartnerPartnerUsersTab';
import PartnerPartnersTab from '../../../PartnerPage/components/PartnerPartnersTab';
import { useUser } from '../../../../contexts/UserContext';
import { parseIdParam } from '../../../../helpers/parseIdParam';

export enum PartnerPartnerModuleTabEnum {
  Details = 'details',
  Organizations = 'organizations',
  Licenses = 'licenses',
  PartnerUsers = 'partner-users',
  Partners = 'partners'
}
interface IPartnerPartnerModuleProps {
  activeTab: PartnerPartnerModuleTabEnum;
}
interface IPartnerPartnerModuleContext {
  partnerId1: string;
  partnerId2: string;
  partnerId3: string;
}

const PartnerPartnerModule = (props: IPartnerPartnerModuleProps) => {
  const { activeTab } = props;
  const { t } = useTranslations();
  const context = useOutletContext<IPartnerPartnerModuleContext>();
  const navigate = useNavigate();
  const { userCan } = useUser();

  const { partnerId1, partnerId2, partnerId3 } = context;

  const { parsedPartnerId1, parsedPartnerId2, parsedPartnerId3 } = useMemo(() => ({
    parsedPartnerId1: parseIdParam(context.partnerId1),
    parsedPartnerId2: parseIdParam(context.partnerId2),
    parsedPartnerId3: parseIdParam(context.partnerId3),
  }), [context]);

  const { isLoading: isLoading1 } = useGetPartnerById(
    { partnerId: partnerId1 },
    { enabled: !!parsedPartnerId1 },
  );

  const { isLoading: isLoading2 } = useGetPartnerById(
    { partnerId: partnerId2 },
    { enabled: !!parsedPartnerId2 },
  );

  const { isLoading: isLoading3 } = useGetPartnerById(
    { partnerId: partnerId3 },
    { enabled: !!parsedPartnerId3 },
  );
  const isLoading = isLoading1 || isLoading2 || isLoading3;

  const handleTabClick = useCallback((
    event: SyntheticEvent,
    nextActiveTab: PartnerPartnerModuleTabEnum,
  ) => {
    let nextPath = `${PageRouteEnum.PartnersDeep}/${partnerId1}/${null}/${null}/${nextActiveTab}`;
    if (parsedPartnerId2) {
      nextPath = `${
        PageRouteEnum.PartnersDeep
      }/${partnerId1}/${partnerId2}/${null}/${nextActiveTab}`;
    }
    if (parsedPartnerId3) {
      nextPath = `${
        PageRouteEnum.PartnersDeep
      }/${partnerId1}/${partnerId2}/${partnerId3}/${nextActiveTab}`;
    }
    if (activeTab !== nextActiveTab) {
      navigate(nextPath);
      return;
    }
    navigate(nextPath, { replace: true });
    publishTablePreservationReset();
  }, [partnerId1, partnerId2, partnerId3, parsedPartnerId2, parsedPartnerId3, activeTab, navigate]);

  const hidePanelHeaderBottomDivider = activeTab === PartnerPartnerModuleTabEnum.Details;

  const canViewOrganizations = userCan(ACLEnum.PartnerViewOrganizations);
  const canViewLicensesHistory = userCan(ACLEnum.PartnerViewLicenseHistory);
  const canViewPartnerUsers = userCan(ACLEnum.PartnerUserView);
  const canViewPartners = userCan(ACLEnum.PartnerView);

  let id = partnerId1;
  if (parsedPartnerId2) {
    id = partnerId2;
  }
  if (parsedPartnerId3) {
    id = partnerId3;
  }

  return (
    <Panel className="height-100-percent flex-column">
      <PanelHeader px={3} hideBottomDivider={hidePanelHeaderBottomDivider}>
        <Tabs value={activeTab} isLoading={isLoading}>
          <Tab
            label={t('partnerPartnerPage.tabs.partnerDetails')}
            value={PartnerPartnerModuleTabEnum.Details}
            onClick={handleTabClick}
          />
          {canViewOrganizations && (
          <Tab
            label={t('partnerPartnerPage.tabs.organizations')}
            value={PartnerPartnerModuleTabEnum.Organizations}
            onClick={handleTabClick}
          />
          )}
          {canViewLicensesHistory && (
          <Tab
            label={t('partnerPartnerPage.tabs.licensesHistory')}
            value={PartnerPartnerModuleTabEnum.Licenses}
            onClick={handleTabClick}
          />
          )}
          {canViewPartnerUsers && (
          <Tab
            label={t('partnerPartnerPage.tabs.partnerUsers')}
            value={PartnerPartnerModuleTabEnum.PartnerUsers}
            onClick={handleTabClick}
          />
          )}
          {(canViewPartners && !parsedPartnerId3) && (
            <Tab
              label={t('partnerPartnerPage.tabs.partners')}
              value={PartnerPartnerModuleTabEnum.Partners}
              onClick={handleTabClick}
            />
          )}
        </Tabs>
      </PanelHeader>
      <RenderIf condition={activeTab === PartnerPartnerModuleTabEnum.Details}>
        <PartnerDetailsTab partnerId={id} />
      </RenderIf>
      <RenderIf
        condition={canViewOrganizations && activeTab === PartnerPartnerModuleTabEnum.Organizations}
      >
        <PartnerOrganizationsTab partnerId={id} />
      </RenderIf>
      <RenderIf
        condition={canViewLicensesHistory && activeTab === PartnerPartnerModuleTabEnum.Licenses}
      >
        <PartnerLicensesHistoryTab partnerId={id} />
      </RenderIf>
      <RenderIf
        condition={canViewPartnerUsers && activeTab === PartnerPartnerModuleTabEnum.PartnerUsers}
      >
        <PartnerPartnerUsersTab partnerId={id} />
      </RenderIf>
      <RenderIf
        condition={canViewPartners && !parsedPartnerId3
          && activeTab === PartnerPartnerModuleTabEnum.Partners}
      >
        <PartnerPartnersTab
          partnerId={id}
          partnerId1={partnerId1}
          partnerId2={partnerId2}
        />
      </RenderIf>
    </Panel>
  );
};

export default memo(PartnerPartnerModule);
