import { memo, useMemo } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  ReactHookFormTextField,
  useTranslations,
  validateEmail,
  RenderIf,
  emptyStringValidator,
} from '@uniqkey-frontend/shared-app';
import { useForm } from 'react-hook-form';
import {
  GetPartnerUserByIdResponse,
  MeResponse,
} from '@uniqkey-backend-partner/api-client';
import ACLEnum from '../../../../../../enums/ACLEnum';
import UserRoleSelect from '../../../../../PartnerUsersPage/components/UserRoleSelect';
import { useUser } from '../../../../../../contexts/UserContext';
import { isAdmin, isLimitedAdmin } from '../../../../../../helpers/userRole';

interface IFormValue {
  email: GetPartnerUserByIdResponse['email'];
  name: GetPartnerUserByIdResponse['name'];
  role: GetPartnerUserByIdResponse['role'];
}

export interface IEditPartnerUserModalReturnValue extends IFormValue {}

interface IEditPartnerUserModalProps {
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: (params: IEditPartnerUserModalReturnValue) => Promise<void> | void;
  partnerUser: GetPartnerUserByIdResponse;
}

const EditPartnerUserModal = (props: IEditPartnerUserModalProps) => {
  const {
    isOpen, isLoading, onClose, onSubmit, partnerUser,
  } = props;

  const { t } = useTranslations();
  const { name, email, role: userRoleToEdit } = partnerUser;
  const { currentUser, userCan } = useUser();
  const { role: currentUserRole } = currentUser ?? {} as MeResponse;

  const {
    handleSubmit, formState: { isDirty, errors }, control,
  } = useForm<IFormValue>({
    defaultValues: { name, email, role: userRoleToEdit },
    mode: 'all',
  });

  const showUserRoleSelect = useMemo(() => {
    const isCurrentUserLimitedAdmin = isLimitedAdmin(currentUserRole);
    const isTargetUserAdmin = isAdmin(userRoleToEdit);
    /*
      Show role selector unless:
        there is no access right for that;
        the current user is Limited Admin, and the selected user is Admin.
    */
    return userCan(
      ACLEnum.PartnerUserEditRoles,
    ) && !(isCurrentUserLimitedAdmin && isTargetUserAdmin);
  }, [currentUserRole, userRoleToEdit, userCan]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      clickOutsideToClose={!isLoading}
      fullWidth
      maxWidth="sm"
    >
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <DialogTitle isLoading={isLoading} onClose={onClose}>
          {t('editPartnerUserModal.title')}
        </DialogTitle>
        <DialogContent>
          <Grid item container flexDirection="column" rowGap={4} pt={1}>
            <Grid item>
              <ReactHookFormTextField
                fullWidth
                autoFocus
                name="name"
                control={control}
                label={`${t('editPartnerUserModal.name.label')}*`}
                placeholder={t('editPartnerUserModal.name.placeholder')}
                error={!!errors.name}
                helperText={errors.name?.message}
                rules={{
                  required: t('validation.required'),
                  validate: (value) => (
                    emptyStringValidator(value) ? t('validation.required') : true
                  ),
                }}
              />
            </Grid>
            <Grid item>
              <ReactHookFormTextField
                fullWidth
                name="email"
                control={control}
                label={`${t('editPartnerUserModal.email.label')}*`}
                placeholder={t('editPartnerUserModal.email.placeholder')}
                error={!!errors.email}
                helperText={errors.email?.message}
                rules={{
                  required: t('validation.required'),
                  validate: {
                    isValid: (value) => validateEmail(value) || t('validation.invalidEmail'),
                  },
                }}
              />
            </Grid>
            <RenderIf condition={showUserRoleSelect}>
              <Grid item>
                <UserRoleSelect
                  role={currentUserRole}
                  control={control}
                  name="role"
                  label={`${t('editPartnerUserModal.role.label')}*`}
                />
              </Grid>
            </RenderIf>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container columnSpacing={2}>
            <Grid item xs={6}>
              <Button fullWidth isLoading={isLoading} disabled={!isDirty} type="submit">
                {t('common.save')}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth variant="outlined" disabled={isLoading} onClick={onClose}>
                {t('common.cancel')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default memo(EditPartnerUserModal);
