import type { Either } from '@uniqkey-frontend/shared-app';
import type {
  SharePrivateKeysResponse,
  SharePrivateKeysRequest,
} from '@uniqkey-backend-partner/api-client';
import {
  KeysManagerFailedToSharePartnerPrivateKeyError,
  type TKeysManagerSharePartnerPrivateKeyOperationErrors,
} from './errors';
import ApiClientsProvider from '../../../../../common/apiClientsProvider';
import { axiosInstance } from '../../../../../axios';
import { dataExtractor } from '../../../../../helpers/apiClients';

interface ISharePartnerPrivateKeyOperationParams {
  partnerKeyId: NonNullable<SharePrivateKeysRequest['partnerKeyId']>;
  data: NonNullable<SharePrivateKeysRequest['data']>;
}

const sharePartnerPrivateKeyOperation = async (
  params: ISharePartnerPrivateKeyOperationParams,
): Promise<Either<SharePrivateKeysResponse, TKeysManagerSharePartnerPrivateKeyOperationErrors>> => {
  try {
    const { partnerKeyId, data } = params;
    const result = await ApiClientsProvider.getPartnerKeysAPIClient(axiosInstance)
      .apiV1PartnerKeysSharePrivateKeysPut({ partnerKeyId, data })
      .then(dataExtractor<SharePrivateKeysResponse>());
    return result;
  } catch (e) {
    return new KeysManagerFailedToSharePartnerPrivateKeyError();
  }
};

export default sharePartnerPrivateKeyOperation;
