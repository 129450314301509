import { FALLBACK_LANGUAGE } from '@uniqkey-frontend/shared-app';
import { isAuthenticated } from '../authService';
import { getMeWithoutLogout } from '../../api/authApi';
import config from '../../config';
import enTranslations from '../../translations/index.json';
import { getActualUILanguageCode } from '../../helpers/languages';

export const getBootstrapLanguage = async () => {
  try {
    if (!isAuthenticated()) return FALLBACK_LANGUAGE;

    const profile = await getMeWithoutLogout();

    return getActualUILanguageCode(profile.language);
  } catch (e) {
    return FALLBACK_LANGUAGE;
  }
};

export const getTranslationsInitOptions = () => {
  let options = {};
  if (config.useLocalTranslations) {
    options = {
      ...options,
      resources: { en: { translation: enTranslations } },
    };
  }
  return options;
};
