import { useCallback, memo, SyntheticEvent } from 'react';
import {
  Panel, PanelHeader, Tabs, Tab, useTranslations, RenderIf,
} from '@uniqkey-frontend/shared-app';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useUser } from '../../../../contexts/UserContext';
import { useGetOrganizationById } from '../../../../hooks/reactQuery';
import PageRouteEnum from '../../../../enums/PageRouteEnum';
import ACLEnum from '../../../../enums/ACLEnum';
import { publishTablePreservationReset } from '../../../../helpers/tablePreservation/reset';
import OrganizationDetailsTab from '../OrganizationDetailsTab';
import OrganizationLicensesHistoryTab from '../OrganizationLicensesHistoryTab';
import OrganizationAdminsTab from '../OrganizationAdminsTab';
import OrganizationVerifiedDomainsTab from '../OrganizationVerifiedDomainsTab';
import OrganizationEmployeesTab from '../OrganizationEmployeesTab';
import OrganizationAuditLogsTab from '../OrganizationAuditLogsTab';

export enum OrganizationModuleTabEnum {
  Details = 'details',
  Domains = 'domains',
  Admins = 'admins',
  Licenses = 'licenses',
  Employees = 'employees',
  AuditLogs = 'audit'
}
interface IOrganizationModuleProps {
  activeTab: OrganizationModuleTabEnum;
}
interface IOrganizationModuleContext {
  organizationId: string;
}

const OrganizationModule = (props: IOrganizationModuleProps) => {
  const { activeTab } = props;
  const { userCan } = useUser();
  const { t } = useTranslations();
  const { organizationId } = useOutletContext<IOrganizationModuleContext>();
  const navigate = useNavigate();

  const { isLoading } = useGetOrganizationById(
    { organizationId },
  );

  const handleTabClick = useCallback((
    event: SyntheticEvent,
    nextActiveTab: OrganizationModuleTabEnum,
  ) => {
    const nextPath = `${PageRouteEnum.Organizations}/${organizationId}/${nextActiveTab}`;
    if (activeTab !== nextActiveTab) {
      navigate(nextPath);
      return;
    }
    navigate(nextPath, { replace: true });
    publishTablePreservationReset();
  }, [organizationId, activeTab, navigate]);

  const hidePanelHeaderBottomDivider = activeTab === OrganizationModuleTabEnum.Details;

  const canViewDomains = userCan(ACLEnum.OrganizationViewDomains);
  const canViewAdmins = userCan(ACLEnum.OrganizationViewAdmins);
  const canViewLicensesHistory = userCan(ACLEnum.OrganizationViewLicenseHistory);
  const canViewEmployees = userCan(ACLEnum.EmployeeAccountView);
  const canViewAuditLogs = userCan(ACLEnum.OrganizationViewAuditLogs);

  return (
    <Panel className="height-100-percent flex-column">
      <PanelHeader px={3} hideBottomDivider={hidePanelHeaderBottomDivider}>
        <Tabs value={activeTab} isLoading={isLoading}>
          <Tab
            label={t('organizationPage.tabs.organizationDetails')}
            value={OrganizationModuleTabEnum.Details}
            onClick={handleTabClick}
          />
          {canViewDomains && (
            <Tab
              label={t('organizationPage.tabs.verifiedDomains')}
              value={OrganizationModuleTabEnum.Domains}
              onClick={handleTabClick}
            />
          )}
          {canViewAdmins && (
            <Tab
              label={t('organizationPage.tabs.admins')}
              value={OrganizationModuleTabEnum.Admins}
              onClick={handleTabClick}
            />
          )}
          {canViewLicensesHistory && (
            <Tab
              label={t('organizationPage.tabs.licensesHistory')}
              value={OrganizationModuleTabEnum.Licenses}
              onClick={handleTabClick}
            />
          )}
          {canViewEmployees && (
            <Tab
              label={t('organizationPage.tabs.employees')}
              value={OrganizationModuleTabEnum.Employees}
              onClick={handleTabClick}
            />
          )}
          {canViewAuditLogs && (
            <Tab
              label={t('organizationPage.tabs.auditLogs')}
              value={OrganizationModuleTabEnum.AuditLogs}
              onClick={handleTabClick}
            />
          )}
        </Tabs>
      </PanelHeader>
      <RenderIf condition={activeTab === OrganizationModuleTabEnum.Details}>
        <OrganizationDetailsTab organizationId={organizationId} />
      </RenderIf>
      <RenderIf condition={canViewDomains && activeTab === OrganizationModuleTabEnum.Domains}>
        <OrganizationVerifiedDomainsTab organizationId={organizationId} />
      </RenderIf>
      <RenderIf condition={canViewAdmins && activeTab === OrganizationModuleTabEnum.Admins}>
        <OrganizationAdminsTab organizationId={organizationId} />
      </RenderIf>
      <RenderIf
        condition={canViewLicensesHistory && activeTab === OrganizationModuleTabEnum.Licenses}
      >
        <OrganizationLicensesHistoryTab organizationId={organizationId} />
      </RenderIf>
      <RenderIf condition={canViewEmployees && activeTab === OrganizationModuleTabEnum.Employees}>
        <OrganizationEmployeesTab organizationId={organizationId} />
      </RenderIf>
      <RenderIf condition={canViewAuditLogs && activeTab === OrganizationModuleTabEnum.AuditLogs}>
        <OrganizationAuditLogsTab organizationId={organizationId} />
      </RenderIf>
    </Panel>
  );
};

export default memo(OrganizationModule);
