import {
  useMutation, useQuery, useQueryClient, type QueryClient, type QueryKey,
} from 'react-query';
import {
  resetReactQuery,
  TUseMutationContext,
  TUseMutationOptions,
  TUseQueryOptions,
} from '@uniqkey-frontend/shared-app';
import {
  GetPartnerUserByIdResponse,
  DeletePartnerUsersBulkRequest,
  DeletePartnerUsersBulkResponse,
  NoContentResult,
  UpdatePartnerUserRequest,
  ChangeRoleRequest,
} from '@uniqkey-backend-partner/api-client';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';
import usePartnerUsersAPI from '../../usePartnerUsersAPI';
import { REACT_QUERY_PARTNER_USERS_KEY } from '../../tables/usePartnerUsersTable';
import { REACT_QUERY_PARTNER_USERS_FOR_ADMIN_KEY } from '../../tables/usePartnerUsersForAdminTable';
import { REACT_QUERY_GROUP_USERS_KEY } from '../../tables/useGroupUsersTable';
import { REACT_QUERY_GROUPS_KEY } from '../../tables/useGroupsTable';

export const REACT_QUERY_PARTNER_USER_KEY = [ReactQueryKeyEnum.PartnerUser];

interface IUseGetPartnerUserByIdParams {
  partnerUserId: string;
}

export const useGetPartnerUserById = (
  params: IUseGetPartnerUserByIdParams,
  options: TUseQueryOptions<GetPartnerUserByIdResponse> = {},
) => {
  const { partnerUserId } = params;
  const { getPartnerUserById } = usePartnerUsersAPI();
  return useQuery<GetPartnerUserByIdResponse>(
    (REACT_QUERY_PARTNER_USER_KEY as QueryKey[]).concat([partnerUserId]),
    ({ signal }) => getPartnerUserById(partnerUserId, { signal }),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};

interface IUseDeletePartnerUsersParams {
  isForAdmin: boolean;
}
export const useDeletePartnerUsers = (
  params: IUseDeletePartnerUsersParams,
  options: TUseMutationOptions<
    DeletePartnerUsersBulkResponse,
    unknown,
    DeletePartnerUsersBulkRequest,
    void
  > = {},
) => {
  const { isForAdmin } = params;
  const queryClient = useQueryClient();
  const { deletePartnerUsers } = usePartnerUsersAPI();
  return useMutation((
    deletePartnerUsersBulkRequest,
  ) => deletePartnerUsers(deletePartnerUsersBulkRequest), {
    onSettled: (data, error, variables) => {
      const removeQueryKey = isForAdmin
        ? REACT_QUERY_PARTNER_USERS_FOR_ADMIN_KEY
        : REACT_QUERY_PARTNER_USERS_KEY;
      resetReactQuery(queryClient, removeQueryKey);
      variables.partnerUserIds.forEach((partnerUserId) => {
        queryClient.removeQueries(
          (REACT_QUERY_PARTNER_USER_KEY as QueryKey[]).concat([partnerUserId]),
        );
      });
      resetReactQuery(queryClient, REACT_QUERY_GROUP_USERS_KEY);
      resetReactQuery(queryClient, REACT_QUERY_GROUPS_KEY);
    },
    ...options,
  });
};

const resetPartnerUserRelatedQueries = (queryClient: QueryClient) => {
  resetReactQuery(queryClient, REACT_QUERY_PARTNER_USERS_KEY);
  resetReactQuery(queryClient, REACT_QUERY_PARTNER_USERS_FOR_ADMIN_KEY);
  resetReactQuery(queryClient, REACT_QUERY_GROUP_USERS_KEY);
};

interface IUseUpdatePartnerUserParams {
  partnerUserId: string;
  useOptimisticUpdates?: boolean;
}
export const useUpdatePartnerUser = (
  params: IUseUpdatePartnerUserParams,
  options: TUseMutationOptions<
    NoContentResult,
    unknown,
    UpdatePartnerUserRequest,
    TUseMutationContext<GetPartnerUserByIdResponse>
  > = {},
) => {
  const { partnerUserId, useOptimisticUpdates = false } = params;
  const queryClient = useQueryClient();
  const { updatePartnerUser } = usePartnerUsersAPI();
  const mutationKey = (REACT_QUERY_PARTNER_USER_KEY as QueryKey[]).concat([partnerUserId]);
  return useMutation(
    mutationKey,
    (partnerUser) => updatePartnerUser(
      { ...partnerUser, partnerUserId },
    ),
    {
      onMutate: async (newPartnerUser) => {
        if (!useOptimisticUpdates) {
          return null;
        }
        await queryClient.cancelQueries(mutationKey);
        const previousValue = queryClient.getQueryData<
          GetPartnerUserByIdResponse
        >(mutationKey);
        queryClient.setQueryData<GetPartnerUserByIdResponse>(
          mutationKey,
          (oldPartnerUser) => ({
            ...oldPartnerUser,
            ...newPartnerUser as GetPartnerUserByIdResponse,
          }),
        );
        return { previousValue: previousValue as GetPartnerUserByIdResponse };
      },
      onError: (err, partnerUser, context) => {
        if (context?.previousValue) {
          queryClient.setQueryData<GetPartnerUserByIdResponse>(
            mutationKey,
            context.previousValue,
          );
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(mutationKey);
        resetPartnerUserRelatedQueries(queryClient);
      },
      ...options,
    },
  );
};

interface IUseUpdatePartnerUserRoleParams {
  partnerUserId: string;
  useOptimisticUpdates?: boolean;
}
export const useUpdatePartnerUserRole = (
  params: IUseUpdatePartnerUserRoleParams,
  options: TUseMutationOptions<
    NoContentResult,
    unknown,
    ChangeRoleRequest,
    TUseMutationContext<GetPartnerUserByIdResponse>
  > = {},
) => {
  const { partnerUserId, useOptimisticUpdates = false } = params;
  const queryClient = useQueryClient();
  const { updatePartnerUserRole } = usePartnerUsersAPI();
  const mutationKey = (REACT_QUERY_PARTNER_USER_KEY as QueryKey[]).concat([partnerUserId]);
  return useMutation(
    mutationKey,
    (partnerUser) => updatePartnerUserRole(
      { ...partnerUser, partnerUserId },
    ),
    {
      onMutate: async (newPartnerUser) => {
        if (!useOptimisticUpdates) {
          return null;
        }
        await queryClient.cancelQueries(mutationKey);
        const previousValue = queryClient.getQueryData<
          GetPartnerUserByIdResponse
        >(mutationKey);
        queryClient.setQueryData<GetPartnerUserByIdResponse>(
          mutationKey,
          (oldPartnerUser) => ({
            ...oldPartnerUser,
            ...newPartnerUser as GetPartnerUserByIdResponse,
          }),
        );
        return { previousValue: previousValue as GetPartnerUserByIdResponse };
      },
      onError: (err, partnerUser, context) => {
        if (context?.previousValue) {
          queryClient.setQueryData<GetPartnerUserByIdResponse>(
            mutationKey,
            context.previousValue,
          );
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(mutationKey);
        resetPartnerUserRelatedQueries(queryClient);
      },
      ...options,
    },
  );
};
