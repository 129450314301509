import { memo, useCallback, useState } from 'react';
import {
  WidgetContainer,
  Grid,
  Typography,
  Button,
  useTranslations,
  RenderIf,
} from '@uniqkey-frontend/shared-app';
import GroupUsersSelectorModal from '../../../../../GroupUsersSelectorModal';
import { useUser } from '../../../../../../../../contexts/UserContext';
import ACLEnum from '../../../../../../../../enums/ACLEnum';

interface IAddUserToGroupWidgetNoDataProps {
  groupId: string;
}

const AddUserToGroupWidgetNoData = (props: IAddUserToGroupWidgetNoDataProps) => {
  const { groupId } = props;
  const { t } = useTranslations();
  const { userCan } = useUser();

  const [isGroupUsersSelectorModalOpen, setIsGroupUsersSelectorModalOpen] = useState(false);
  const handleGroupUsersSelectorModalOpen = useCallback(
    () => setIsGroupUsersSelectorModalOpen(true),
    [],
  );
  const handleGroupUsersSelectorModalClose = useCallback(
    () => setIsGroupUsersSelectorModalOpen(false),
    [],
  );

  return (
    <>
      <WidgetContainer
        container
        py={3}
        withShadow
        minHeight={176}
        flexDirection="column"
        justifyContent="space-between"
      >
        <Grid item>
          <Typography variant="h6">{t('addUserToGroupWidget.title')}</Typography>
        </Grid>
        <Grid item container justifyContent="center">
          <Typography>{t('addUserToGroupWidget.noData')}</Typography>
        </Grid>
        <Grid item container>
          <RenderIf condition={userCan(ACLEnum.GroupAddUsers)}>
            <Button variant="outlined" onClick={handleGroupUsersSelectorModalOpen}>
              {t('addUserToGroupWidget.addUser')}
            </Button>
          </RenderIf>
        </Grid>
      </WidgetContainer>
      {isGroupUsersSelectorModalOpen && (
        <GroupUsersSelectorModal
          isOpen={isGroupUsersSelectorModalOpen}
          onClose={handleGroupUsersSelectorModalClose}
          groupId={groupId}
        />
      )}
    </>
  );
};

export default memo(AddUserToGroupWidgetNoData);
