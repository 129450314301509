import {
  decryptSymmetric,
  type Either,
} from '@uniqkey-frontend/shared-app';
import {
  KeysManagerCannotDecryptPartnerUserPrivateKeyError,
  type TKeysManagerDecryptPartnerUserPrivateKeyOperationErrors,
} from './errors';
import type { IPartnerUserKeys } from '../../../common/interfaces';

interface IDecryptPartnerUserPrivateKeyOperationParams {
  derivedMasterPassword: string;
  encryptedPrivateKey: IPartnerUserKeys['privateKey'];
}

interface IDecryptPartnerUserPrivateKeyOperationReturn {
  privateKey: IPartnerUserKeys['privateKey'];
}

const decryptPartnerUserPrivateKeyOperation = (
  params: IDecryptPartnerUserPrivateKeyOperationParams,
): Either<
  IDecryptPartnerUserPrivateKeyOperationReturn,
  TKeysManagerDecryptPartnerUserPrivateKeyOperationErrors
> => {
  try {
    const { derivedMasterPassword, encryptedPrivateKey } = params;

    const decryptedPrivateKey = decryptSymmetric({
      cipher: encryptedPrivateKey,
      key: derivedMasterPassword,
    });

    if (!decryptedPrivateKey) {
      return new KeysManagerCannotDecryptPartnerUserPrivateKeyError();
    }

    return {
      privateKey: decryptedPrivateKey,
    };
  } catch (e) {
    return new KeysManagerCannotDecryptPartnerUserPrivateKeyError();
  }
};

export default decryptPartnerUserPrivateKeyOperation;
