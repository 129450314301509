import { useCallback, useMemo, memo } from 'react';
import {
  ReactHookFormAutocomplete,
  Button,
  Grid,
  Popper,
  PopperActions,
  PopperContent,
  PopperTitle,
  Typography,
  useTranslations,
  buildFilterOptionsAsObject,
  usePubSub,
} from '@uniqkey-frontend/shared-app';
import { useForm } from 'react-hook-form';
import { PartnerUserRole, KeysState } from '@uniqkey-backend-partner/api-client';
import PubSubEventEnum from '../../../../enums/PubSubEventEnum';
import { getUserRoleOptions } from '../../../../helpers/userRole';
import { IGetPartnerUsersForAdminParams } from '../../../../hooks/usePartnerUsersAPI/interfaces';
import { KEYS_STATE_TRANSLATION_KEYS } from '../../../../constants';

export interface IPartnerUsersForAdminListFilterSubmitResult {
  roleFilter: IGetPartnerUsersForAdminParams['roleFilter'];
  keysStateFilter: IGetPartnerUsersForAdminParams['keysStateFilter'];
}

interface IPartnerUsersForAdminListFilterProps {
  isOpen: boolean;
  anchorEl: HTMLElement | null;
  onSubmit: (result: IPartnerUsersForAdminListFilterSubmitResult) => void;
  onClose: () => void;
  initialValues: IPartnerUsersForAdminListFilterSubmitResult;
  role: PartnerUserRole;
}

type TRoleFilterOption = {
  label: string;
  value: PartnerUserRole;
} | null;

type TKeysStateFilterOption = {
  label: string;
  value: KeysState;
} | null;

interface IFormValues {
  roleFilter: TRoleFilterOption;
  keysStateFilter: TKeysStateFilterOption;
}

const POPPER_MIN_WIDTH = { minWidth: 500 };

const PartnerUsersForAdminListFilter = (props: IPartnerUsersForAdminListFilterProps) => {
  const {
    isOpen, anchorEl, onSubmit, onClose, initialValues, role,
  } = props;
  const { t } = useTranslations();

  const [roleFilterOptions, roleFilterOptionsAsObject] = useMemo(() => {
    const options = getUserRoleOptions(t, role);
    const optionsAsObject = buildFilterOptionsAsObject(options);
    return [options, optionsAsObject];
  }, [role, t]);

  const [keysStateFilterOptions, keysStateFilterOptionsAsObject] = useMemo(() => {
    const options = [
      {
        label: t(KEYS_STATE_TRANSLATION_KEYS[KeysState.AllKeysAvailable]),
        value: KeysState.AllKeysAvailable,
      },
      {
        label: t(KEYS_STATE_TRANSLATION_KEYS[KeysState.UserKeyUnavailable]),
        value: t(KeysState.UserKeyUnavailable),
      },
      {
        label: t(KEYS_STATE_TRANSLATION_KEYS[KeysState.PartnerKeyUnavailable]),
        value: KeysState.PartnerKeyUnavailable,
      },
      {
        label: t(KEYS_STATE_TRANSLATION_KEYS[KeysState.PartnerUserToPartnerKeyUnavailable]),
        value: KeysState.PartnerUserToPartnerKeyUnavailable,
      },
    ];
    const optionsAsObject = buildFilterOptionsAsObject(options);
    return [options, optionsAsObject];
  }, [t]);

  const {
    handleSubmit, control, reset, setValue, formState: { isDirty },
  } = useForm<IFormValues>({
    defaultValues: {
      roleFilter: roleFilterOptionsAsObject[
        initialValues.roleFilter as keyof typeof roleFilterOptionsAsObject
      ] ?? null,
      keysStateFilter: keysStateFilterOptionsAsObject[
        initialValues.keysStateFilter as keyof typeof keysStateFilterOptionsAsObject
      ] ?? null,
    },
  });

  const handleOnSubmit = useCallback((value: IFormValues): void => {
    const { roleFilter, keysStateFilter } = value;
    onSubmit({
      roleFilter: roleFilter?.value,
      keysStateFilter: keysStateFilter?.value,
    });
    onClose();
    reset(value); // set default values to the selected ones to reset isDirty
  }, [reset, onSubmit, onClose]);

  const clearAll = useCallback(() => {
    setValue('roleFilter', null, { shouldDirty: true });
    setValue('keysStateFilter', null, { shouldDirty: true });
  }, [setValue]);

  const handleOnFadeExited = useCallback(() => {
    reset(); // reset form values when Popper is closed and animation has finished
  }, [reset]);

  const handleReset = useCallback(() => {
    reset({
      roleFilter: null,
      keysStateFilter: null,
    });
  }, [reset]);
  usePubSub(PubSubEventEnum.RESET_FILTER, handleReset);

  return (
    <Popper
      anchorEl={anchorEl}
      offsetX={-8}
      offsetY={16}
      placement="bottom-start"
      open={isOpen}
      sx={POPPER_MIN_WIDTH}
      onFadeExited={handleOnFadeExited}
    >
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <PopperTitle onClose={onClose}>
          {t('common.filterBy')}
        </PopperTitle>
        <PopperContent>
          <Grid container direction="column" rowSpacing={2}>
            <Grid item container alignItems="center">
              <Grid item xs={3}>
                <Typography>
                  {t('partnerUsersForAdminListFilter.role.label')}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <ReactHookFormAutocomplete
                  t={t}
                  name="roleFilter"
                  placeholder={t('partnerUsersForAdminListFilter.role.placeholder')}
                  options={roleFilterOptions}
                  control={control}
                />
              </Grid>
            </Grid>
            <Grid item container alignItems="center">
              <Grid item xs={3}>
                <Typography>
                  {t('partnerUsersForAdminListFilter.keysState.label')}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <ReactHookFormAutocomplete
                  t={t}
                  name="keysStateFilter"
                  placeholder={t('partnerUsersForAdminListFilter.keysState.placeholder')}
                  options={keysStateFilterOptions}
                  control={control}
                />
              </Grid>
            </Grid>
          </Grid>
        </PopperContent>
        <PopperActions>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button disabled={!isDirty} fullWidth type="submit">{t('common.apply')}</Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="outlined"
                onClick={clearAll}
              >
                {t('common.clearAll')}
              </Button>
            </Grid>
          </Grid>
        </PopperActions>
      </form>
    </Popper>
  );
};

export default memo(PartnerUsersForAdminListFilter);
