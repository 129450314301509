import { memo, useCallback, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  useTranslations,
  FormHiddenInput,
  ReactHookFormTextField,
  InputAdornment,
  IconButton,
  ClosedEyeIcon,
  EyeIcon, Typography,
} from '@uniqkey-frontend/shared-app';
import { useForm } from 'react-hook-form';

export interface ISharePartnerPrivateKeyModalFormValue {
  password: string;
}

interface ISharePartnerPrivateKeyModalProps {
  isOpen: boolean;
  isLoading: boolean;
  onSubmit: (params: ISharePartnerPrivateKeyModalFormValue) => Promise<void>;
  onClose: () => void;
}

const SharePartnerPrivateKeyModal = (props: ISharePartnerPrivateKeyModalProps) => {
  const {
    isOpen, isLoading, onSubmit, onClose,
  } = props;

  const { t } = useTranslations();

  const [showPassword, setShowPassword] = useState(false);
  const {
    handleSubmit,
    formState: { isDirty },
    control,
  } = useForm<ISharePartnerPrivateKeyModalFormValue>({
    defaultValues: {
      password: '',
    },
  });

  const toggleShowPassword = useCallback(() => setShowPassword(!showPassword), [showPassword]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      clickOutsideToClose={!isLoading}
    >
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" noValidate>
        <DialogTitle isLoading={isLoading} onClose={onClose}>
          {t('sharePartnerPrivateKeyModal.title')}
        </DialogTitle>
        <DialogContent>
          <Grid container direction="row" rowSpacing={3}>
            <Grid item xs={12}>
              <Typography>
                {t('sharePartnerPrivateKeyModal.description')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormHiddenInput />
              <ReactHookFormTextField
                name="password"
                control={control}
                fullWidth
                type={showPassword ? 'text' : 'password'}
                label={t('sharePartnerPrivateKeyModal.password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" onClick={toggleShowPassword}>
                        {showPassword ? <ClosedEyeIcon /> : <EyeIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container columnSpacing={2} justifyContent="center">
            <Grid item xs={6}>
              <Button fullWidth isLoading={isLoading} disabled={!isDirty} type="submit">
                {t('common.share')}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth variant="outlined" disabled={isLoading} onClick={onClose}>
                {t('common.cancel')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default memo(SharePartnerPrivateKeyModal);
