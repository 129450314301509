import { TFunction } from '@uniqkey-frontend/shared-app';
import { PartnerUserRole } from '@uniqkey-backend-partner/api-client';
import { IUserRoleOption } from './interfaces';
import { USER_ROLES_TRANSLATION_KEYS } from '../../constants';

const OPTIONS = {
  [PartnerUserRole.Admin]: {
    labelTranslationKey: USER_ROLES_TRANSLATION_KEYS[PartnerUserRole.Admin],
    value: PartnerUserRole.Admin,
  },
  [PartnerUserRole.LimitedAdmin]: {
    labelTranslationKey: USER_ROLES_TRANSLATION_KEYS[PartnerUserRole.LimitedAdmin],
    value: PartnerUserRole.LimitedAdmin,
  },
  [PartnerUserRole.Partner]: {
    labelTranslationKey: USER_ROLES_TRANSLATION_KEYS[PartnerUserRole.Partner],
    value: PartnerUserRole.Partner,
  },
  [PartnerUserRole.Support]: {
    labelTranslationKey: USER_ROLES_TRANSLATION_KEYS[PartnerUserRole.Support],
    value: PartnerUserRole.Support,
  },
  [PartnerUserRole.Audit]: {
    labelTranslationKey: USER_ROLES_TRANSLATION_KEYS[PartnerUserRole.Audit],
    value: PartnerUserRole.Audit,
  },
};

export const USER_ROLE_TO_OPTIONS = {
  [PartnerUserRole.Admin]: {
    options: [
      OPTIONS[PartnerUserRole.Admin],
      OPTIONS[PartnerUserRole.LimitedAdmin],
      OPTIONS[PartnerUserRole.Partner],
      OPTIONS[PartnerUserRole.Support],
      OPTIONS[PartnerUserRole.Audit],
    ],
    defaultValue: PartnerUserRole.Admin,
  },
  [PartnerUserRole.LimitedAdmin]: {
    options: [
      OPTIONS[PartnerUserRole.LimitedAdmin],
      OPTIONS[PartnerUserRole.Partner],
      OPTIONS[PartnerUserRole.Support],
      OPTIONS[PartnerUserRole.Audit],
    ],
    defaultValue: PartnerUserRole.LimitedAdmin,
  },
  [PartnerUserRole.Partner]: {
    options: [
      OPTIONS[PartnerUserRole.Partner],
      OPTIONS[PartnerUserRole.Support],
      OPTIONS[PartnerUserRole.Audit],
    ],
    defaultValue: PartnerUserRole.Partner,
  },
  [PartnerUserRole.Support]: {
    options: [
      OPTIONS[PartnerUserRole.Support],
      OPTIONS[PartnerUserRole.Audit],
    ],
    defaultValue: PartnerUserRole.Support,
  },
  [PartnerUserRole.Audit]: {
    options: [
      OPTIONS[PartnerUserRole.Audit],
    ],
    defaultValue: PartnerUserRole.Audit,
  },
};

export const getUserRoleOptions = (
  t: TFunction,
  role?: PartnerUserRole,
): IUserRoleOption[] => {
  const { options = [] } = USER_ROLE_TO_OPTIONS[
    role as keyof typeof USER_ROLE_TO_OPTIONS
  ] ?? {};
  return options.map((option) => (
    { label: t(option.labelTranslationKey), value: option.value }
  ));
};

export const isSupport = (role?: PartnerUserRole) => role === PartnerUserRole.Support;
export const isLimitedAdmin = (role?: PartnerUserRole) => role === PartnerUserRole.LimitedAdmin;
export const isAdmin = (role?: PartnerUserRole) => role === PartnerUserRole.Admin;
