import { type AxiosInstance } from 'axios';
import {
  PartnerKeysApi,
  PartnerUserKeysApi,
} from '@uniqkey-backend-partner/api-client';
import config from '../../config';

const API_BASE_URL = config.getPartnerApiUrl();

const getPartnerKeysAPIClient = (instance: AxiosInstance) => new PartnerKeysApi(
  undefined,
  API_BASE_URL,
  instance,
);

const getPartnerUserKeysAPIClient = (instance: AxiosInstance) => new PartnerUserKeysApi(
  undefined,
  API_BASE_URL,
  instance,
);

export default {
  getPartnerKeysAPIClient,
  getPartnerUserKeysAPIClient,
};
