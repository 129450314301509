import { useCallback, useState, useMemo } from 'react';
import {
  Grid,
  Panel,
  Box,
  Button,
  FilterButton,
  useTranslations,
  SearchableTextField,
  Tooltip,
  InviteUserIcon,
  useSnackbar,
  ActionButton,
  Divider,
  DeleteIcon,
  useMapKeyValueExtractor,
  PromptModal,
  usePopper,
  RenderIf,
  ISearchableTextFieldProps,
  IFilterButtonProps,
} from '@uniqkey-frontend/shared-app';
import {
  MeResponse,
} from '@uniqkey-backend-partner/api-client';
import ACLEnum from '../../enums/ACLEnum';
import CreatePartnerUserModal, {
  ICreatePartnerUserModalFormValue,
} from './components/CreatePartnerUserModal';
import { useUser } from '../../contexts/UserContext';
import usePartnerUsersTable from '../../hooks/tables/usePartnerUsersTable';
import PartnerUsersTable, {
  IPartnerUsersTableRow,
} from '../../components/tables/PartnerUsersTable';
import usePartnerUsersAPI from '../../hooks/usePartnerUsersAPI';
import { useDeletePartnerUsers, useLanguages } from '../../hooks/reactQuery';
import PartnerUsersListFilter, {
  IPartnerUsersListFilterSubmitResult,
} from './components/PartnerUsersListFilter';
import { generateTooltipTitle } from '../../helpers/tooltips';
import { isLimitedAdmin, isAdmin } from '../../helpers/userRole';
import { logException } from '../../services/sentryService';

const SUCH_PARTNER_USER_ALREADY_EXISTS_ERROR = 'Such_PartnerUser_already_exists';

const PartnerUsersPage = () => {
  const { t } = useTranslations();
  const { createPartnerUser } = usePartnerUsersAPI();
  const { showSuccess, showError } = useSnackbar();

  const [isCreatePartnerUserModalOpen, setIsCreatePartnerUserModalOpen] = useState(false);
  const [isCreatePartnerUserLoading, setIsCreatePartnerUserLoading] = useState(false);
  const [isDeletePartnerUsersModalOpen, setIsDeletePartnerUsersModalOpen] = useState(false);

  // preload for create partner user modal
  useLanguages({ includeOnlyAllowedLanguages: false });

  const {
    selectedPartnerUsers,
    searchQuery,
    setSearchQuery,
    resetActivePage,
    resetSelectedRows,
    filterValues,
    setFilterValues,
    isFilterActive,
    numberOfActiveFilters,
    resetQuery,
    ...restTableProps
  } = usePartnerUsersTable({
    noDataMessageKey: 'partnerUsersPage.noData',
  });

  const {
    isOpen: isFilterOpen,
    anchorEl: filterAnchorEl,
    setPopperIsOpen: setIsFilterOpen,
  } = usePopper();
  const toggleIsFilterOpen = useCallback<NonNullable<IFilterButtonProps['onChange']>>(
    (event) => setIsFilterOpen(!isFilterOpen, event),
    [setIsFilterOpen, isFilterOpen],
  );
  const handleFilterClose = useCallback(() => setIsFilterOpen(false), [setIsFilterOpen]);

  const {
    values: selectedPartnerUsersAsObjects, keys: selectedPartnerUsersIds,
  } = useMapKeyValueExtractor<
    IPartnerUsersTableRow
  >(selectedPartnerUsers);

  const {
    mutate: deletePartnerUsers,
    isLoading: isDeletePartnerUsersLoading,
  } = useDeletePartnerUsers({ isForAdmin: false });

  const handleCreatePartnerUserModalOpen = useCallback(
    () => setIsCreatePartnerUserModalOpen(true),
    [],
  );

  const handleCreatePartnerUserModalClose = useCallback(
    () => setIsCreatePartnerUserModalOpen(false),
    [],
  );

  const handleDeletePartnerUsersModalOpen = useCallback(
    () => setIsDeletePartnerUsersModalOpen(true),
    [],
  );

  const handleDeletePartnerUsersModalClose = useCallback(
    () => setIsDeletePartnerUsersModalOpen(false),
    [],
  );
  const { currentUser, userCan } = useUser();
  const { partnerId, role, id: currentUserId } = currentUser ?? {} as MeResponse;

  const isCurrentUserSelected = selectedPartnerUsers.has(currentUserId);
  const canDeletePartnerUser = userCan(ACLEnum.PartnerUserDelete);

  const {
    isDeletePartnerUserDisabled,
  } = useMemo(() => {
    if (!selectedPartnerUsersAsObjects.length || isCurrentUserSelected) {
      return {
        isDeletePartnerUserDisabled: true,
      };
    }
    let disableDeletePartnerUser = false;
    if (isLimitedAdmin(role)) {
      disableDeletePartnerUser = selectedPartnerUsersAsObjects.some(
        ({ role: userRole }) => isAdmin(userRole),
      );
    }
    if (!canDeletePartnerUser) {
      disableDeletePartnerUser = true;
    }
    return {
      isDeletePartnerUserDisabled: disableDeletePartnerUser,
    };
  }, [selectedPartnerUsersAsObjects, isCurrentUserSelected, role, canDeletePartnerUser]);

  const {
    deletePartnerUserTooltipTitle,
  } = useMemo(() => {
    const deletePartnerUserTitle = generateTooltipTitle({
      selectedDataLength: selectedPartnerUsersAsObjects.length,
      t,
      isDisabled: isDeletePartnerUserDisabled,
      key: 'partnerUsersPage.delete',
    });

    return {
      deletePartnerUserTooltipTitle: deletePartnerUserTitle,
    };
  }, [selectedPartnerUsersAsObjects.length, t, isDeletePartnerUserDisabled]);

  const TABLE_OPTIONS = useMemo(() => ({
    selection: canDeletePartnerUser,
  }), [canDeletePartnerUser]);

  const handleCreatePartnerUser = useCallback(async (
    partnerUser: ICreatePartnerUserModalFormValue,
  ) => {
    try {
      setIsCreatePartnerUserLoading(true);
      await createPartnerUser({ ...partnerUser, partnerId });
      showSuccess({
        text: t('createPartnerUserModal.partnerUserCreated'),
      });
      handleCreatePartnerUserModalClose();
      resetQuery();
    } catch (e: any) {
      let key = 'common.somethingWentWrong';
      if (e?.response?.data?.includes(SUCH_PARTNER_USER_ALREADY_EXISTS_ERROR)) {
        key = 'createPartnerUserModal.suchPartnerUserAlreadyExists';
      }
      showError({ text: t(key) });
      logException(e, {
        message: 'PartnerUsersPage/handleCreatePartnerUser exception',
      });
    } finally {
      setIsCreatePartnerUserLoading(false);
    }
  }, [
    partnerId,
    createPartnerUser,
    handleCreatePartnerUserModalClose,
    resetQuery,
    showError,
    showSuccess,
    t,
  ]);

  const handleDeletePartnerUsers = useCallback(async () => {
    deletePartnerUsers({
      partnerUserIds: selectedPartnerUsersIds,
    }, {
      onSuccess: ({ failCount, successCount }) => {
        if (successCount) {
          showSuccess({
            text: t(
              'deletePartnerUsersModal.successMessage',
              { count: successCount },
            ),
          });
        }
        if (failCount) {
          showError({ text: t('deletePartnerUsersModal.errorMessage', { count: failCount }) });
        }
        handleDeletePartnerUsersModalClose();
        resetSelectedRows();
        resetActivePage();
      },
      onError: () => showError({ text: t('common.somethingWentWrong') }),
    });
  }, [
    selectedPartnerUsersIds,
    deletePartnerUsers,
    handleDeletePartnerUsersModalClose,
    resetSelectedRows,
    resetActivePage,
    showError,
    showSuccess,
    t,
  ]);

  const handleSearchChange = useCallback<ISearchableTextFieldProps['onChange']>(
    (debouncedValue) => {
      setSearchQuery(debouncedValue);
      resetActivePage();
    },
    [setSearchQuery, resetActivePage],
  );

  const handleFilterSubmit = useCallback((updatedValues: IPartnerUsersListFilterSubmitResult) => {
    setFilterValues(updatedValues);
    resetActivePage();
  }, [setFilterValues, resetActivePage]);

  return (
    <Grid container flexDirection="column" className="min-height-100-percent">
      <Grid item mb={1}>
        <Panel>
          <Box p={1}>
            <Grid container justifyContent="space-between" alignItems="stretch">
              <Grid item xs={4} container flexWrap="nowrap" spacing={1}>
                <Grid item>
                  <Tooltip title={t('common.filter')}>
                    <FilterButton
                      isFilterActive={isFilterActive}
                      numberOfActiveFilters={numberOfActiveFilters}
                      selected={isFilterOpen}
                      onChange={toggleIsFilterOpen}
                    />
                  </Tooltip>
                </Grid>
                <Grid item my={0.5}>
                  <Divider orientation="vertical" />
                </Grid>
                <Grid item alignSelf="center">
                  <Tooltip title={deletePartnerUserTooltipTitle}>
                    <ActionButton
                      width={40}
                      height={40}
                      onClick={handleDeletePartnerUsersModalOpen}
                      disabled={isDeletePartnerUserDisabled}
                    >
                      <DeleteIcon />
                    </ActionButton>
                  </Tooltip>
                </Grid>
                <Grid item my={0.5}>
                  <Divider orientation="vertical" />
                </Grid>
              </Grid>
              <Grid item xs={8} container justifyContent="flex-end" flexWrap="nowrap">
                <Grid item>
                  <SearchableTextField
                    value={searchQuery}
                    onChange={handleSearchChange}
                    placeholder={t('common.search')}
                  />
                </Grid>
                <RenderIf condition={userCan(ACLEnum.PartnerUserCreate)}>
                  <Grid ml={3} item>
                    <Button
                      icon={<InviteUserIcon />}
                      onClick={handleCreatePartnerUserModalOpen}
                    >
                      {t('partnerUsersPage.createPartnerUserButton')}
                    </Button>
                  </Grid>
                </RenderIf>
              </Grid>
            </Grid>
          </Box>
        </Panel>
      </Grid>
      <Grid item xs>
        <Panel>
          <PartnerUsersTable
            options={TABLE_OPTIONS}
            selectedPartnerUsers={selectedPartnerUsers}
            {...restTableProps}
          />
        </Panel>
      </Grid>
      {isCreatePartnerUserModalOpen && (
        <CreatePartnerUserModal
          isOpen={isCreatePartnerUserModalOpen}
          isLoading={isCreatePartnerUserLoading}
          onSubmit={handleCreatePartnerUser}
          onClose={handleCreatePartnerUserModalClose}
          role={role}
        />
      )}
      {isDeletePartnerUsersModalOpen && (
        <PromptModal
          open={isDeletePartnerUsersModalOpen}
          onClose={handleDeletePartnerUsersModalClose}
          onSubmit={handleDeletePartnerUsers}
          title={t(
            'deletePartnerUsersModal.title',
            { count: selectedPartnerUsers.size },
          )}
          description={t('deletePartnerUsersModal.description')}
          approvalButtonText="common.delete"
          list={selectedPartnerUsersAsObjects}
          renderField="name"
          renderKey="partnerUserId"
          isLoading={isDeletePartnerUsersLoading}
        />
      )}
      <PartnerUsersListFilter
        isOpen={isFilterOpen}
        anchorEl={filterAnchorEl}
        onSubmit={handleFilterSubmit}
        onClose={handleFilterClose}
        initialValues={filterValues}
        role={role}
      />
    </Grid>
  );
};

export default PartnerUsersPage;
