import { useMemo, memo } from 'react';
import {
  AG3,
  S4,
  AG2,
  AR2,
  Table,
  useTranslations,
  IUseTableReturn,
  IUseQueryTableFetchReturn,
  IUseTableLocalizationReturn,
  getTimeFromNow,
  Grid,
  LoginAvatar,
  SecurityScoreIcon,
  TypographyWithTooltip,
  IMaterialTableOptions,
} from '@uniqkey-frontend/shared-app';
import {
  Ownership,
  GetVaultPasswordsResponseModel,
} from '@uniqkey-backend-partner/api-client';
import { OWNERSHIP_TRANSLATION_KEYS, MFA_TRANSLATION_KEYS } from '../../../constants';
import {
  TGetOrganizationEmployeeAccountVaultPasswordsMethod,
} from '../../../hooks/useEmployeeAccountsAPI/interfaces';

export interface IOrganizationEmployeeLoginsTableRow extends GetVaultPasswordsResponseModel {
  lastActivity: string | null;
}

interface IOrganizationEmployeeLoginsTableProps extends
  Omit<IUseTableReturn<IOrganizationEmployeeLoginsTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Omit<
    IUseQueryTableFetchReturn<TGetOrganizationEmployeeAccountVaultPasswordsMethod>,
    'data' | 'resetQuery' | 'isFetchedAfterMount'
  >
{
  logins: IUseQueryTableFetchReturn<TGetOrganizationEmployeeAccountVaultPasswordsMethod>['data'];
  selectedLogins: IUseTableReturn<IOrganizationEmployeeLoginsTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
}

const TABLE_OPTIONS: IMaterialTableOptions<IOrganizationEmployeeLoginsTableRow> = {
  selection: false,
};

const OWNERSHIP_COLORS = {
  [Ownership.Employees]: AG3,
  [Ownership.Groups]: AG3,
  [Ownership.Unmanaged]: S4,
};
const MFA_COLORS = {
  true: AG2,
  false: AR2,
};

const OrganizationEmployeeLoginsTable = (props: IOrganizationEmployeeLoginsTableProps) => {
  const {
    logins: preLogins,
    isLoading,
    total,
    localization,
    activePage,
    setActivePage,
    perPage,
    onPerPageChange,
    columnOrderBy,
    onColumnOrderByChange,
    columnOrderDirection,
    onColumnOrderDirectionChange,
    selectedLogins,
    onRowSelectionChange,
    createColumns,
  } = props;

  const { t, currentLanguage } = useTranslations();

  const logins = useMemo(() => preLogins.map((login) => ({
    ...login,
    lastActivity: getTimeFromNow({
      date: login.activityAt,
      locale: currentLanguage,
    }),
  })), [preLogins, currentLanguage]);

  const columns = useMemo(() => createColumns([
    {
      title: t('organizationEmployeePage.loginsTab.service'),
      field: 'applicationName',
      render: (rowData) => (
        <Grid container flexWrap="nowrap" alignItems="center">
          <LoginAvatar src={rowData.iconUrl} />
          <TypographyWithTooltip variant="body2">
            {rowData.applicationName}
          </TypographyWithTooltip>
        </Grid>
      ),
      width: '12%',
    },
    {
      title: t('organizationEmployeePage.loginsTab.loginName'),
      field: 'name',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.name}
        </TypographyWithTooltip>
      ),
      width: '12%',
    },
    {
      title: t('organizationEmployeePage.loginsTab.username'),
      field: 'username',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.username}
        </TypographyWithTooltip>
      ),
      width: '12%',
    },
    {
      title: t('organizationEmployeePage.loginsTab.createdFor'),
      field: 'createdFor',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.createdFor}
        </TypographyWithTooltip>
      ),
      width: '12%',
    },
    {
      title: t('organizationEmployeePage.loginsTab.securityScore'),
      field: 'strength',
      render: (rowData) => (
        <SecurityScoreIcon percentage={rowData.strength} />
      ),
      width: '15%',
    },
    {
      title: t('organizationEmployeePage.loginsTab.mfa'),
      field: 'hasTotp',
      render: (rowData) => {
        const is2FAEnabledAsString = rowData.hasTotp.toString();
        return (
          <TypographyWithTooltip
            variant="body2"
            color={MFA_COLORS[is2FAEnabledAsString as keyof typeof MFA_COLORS]}
          >
            {t(MFA_TRANSLATION_KEYS[is2FAEnabledAsString as keyof typeof MFA_TRANSLATION_KEYS])}
          </TypographyWithTooltip>
        );
      },
      width: '10%',
    },
    {
      title: t('organizationEmployeePage.loginsTab.managedBy'),
      field: 'ownership',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2" color={OWNERSHIP_COLORS[rowData.ownership]}>
          {t(OWNERSHIP_TRANSLATION_KEYS[rowData.ownership])}
        </TypographyWithTooltip>
      ),
      width: '13%',
    },
    {
      title: t('organizationEmployeePage.loginsTab.lastActivity'),
      field: 'activityAt',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.lastActivity}
        </TypographyWithTooltip>
      ),
      width: '14%',
    },
  ]), [createColumns, t]);

  return (
    <Table
      t={t}
      totalItems={total}
      columns={columns}
      data={logins}
      isLoading={isLoading}
      options={TABLE_OPTIONS}
      activePage={activePage}
      onActivePageChange={setActivePage}
      perPage={perPage}
      onPerPageChange={onPerPageChange}
      columnOrderBy={columnOrderBy}
      onColumnOrderByChange={onColumnOrderByChange}
      columnOrderDirection={columnOrderDirection}
      onColumnOrderDirectionChange={onColumnOrderDirectionChange}
      selectedRows={selectedLogins}
      onSelectionChange={onRowSelectionChange}
      localization={localization}
      rowSelectByKey="vaultId"
      rowHeight={51}
    />
  );
};

export default memo(OrganizationEmployeeLoginsTable);
