import { memo } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  maxLengthValidator,
  useTranslations,
  validateEmail,
  validatePhone,
  FormHiddenInput,
  emptyStringValidator,
  ReactHookFormTextField,
} from '@uniqkey-frontend/shared-app';
import { useForm } from 'react-hook-form';
import {
  GetContactPersonByIdResponse,
} from '@uniqkey-backend-partner/api-client';

interface IEditContactPersonDetailsModalFormValue extends GetContactPersonByIdResponse {}

export interface IEditContactPersonDetailsModalReturnValue
  extends IEditContactPersonDetailsModalFormValue {}

interface IEditContactPersonDetailsModalProps {
  isOpen: boolean;
  isLoading: boolean;
  onSubmit: (params: IEditContactPersonDetailsModalReturnValue) => Promise<void> | void;
  onClose: () => void;
  contactPerson: GetContactPersonByIdResponse;
}

const EditContactPersonDetailsModal = (props: IEditContactPersonDetailsModalProps) => {
  const {
    isOpen, isLoading, onSubmit, onClose, contactPerson,
  } = props;

  const { t } = useTranslations();

  const { name, email, phone } = contactPerson;

  const {
    handleSubmit,
    formState: { isDirty, errors },
    control,
  } = useForm<IEditContactPersonDetailsModalFormValue>({
    defaultValues: {
      name,
      email,
      phone,
    },
    mode: 'all',
  });

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      clickOutsideToClose={!isLoading}
    >
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" noValidate>
        <DialogTitle isLoading={isLoading} onClose={onClose}>
          {t('editContactPersonDetailsModal.title')}
        </DialogTitle>
        <DialogContent>
          <Grid container direction="row" rowSpacing={4} columnSpacing={2}>
            <Grid item xs={6}>
              <FormHiddenInput />
              <ReactHookFormTextField
                name="name"
                control={control}
                fullWidth
                autoFocus
                autoComplete="none"
                label={`${t('editContactPersonDetailsModal.name.label')}*`}
                placeholder={t('editContactPersonDetailsModal.name.placeholder')}
                error={!!errors.name}
                helperText={errors.name?.message}
                rules={{
                  required: t('validation.required'),
                  validate: (value) => (
                    emptyStringValidator(value) ? t('validation.required') : true
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <ReactHookFormTextField
                name="email"
                control={control}
                fullWidth
                autoComplete="none"
                label={`${t('editContactPersonDetailsModal.email.label')}*`}
                placeholder={t('editContactPersonDetailsModal.email.placeholder')}
                error={!!errors.email}
                helperText={errors.email?.message}
                rules={{
                  required: t('validation.required'),
                  validate: {
                    isValid: (v) => validateEmail(v as string) || t('validation.invalidEmail'),
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <ReactHookFormTextField
                name="phone"
                control={control}
                fullWidth
                autoComplete="none"
                label={`${t('editContactPersonDetailsModal.phone.label')}*`}
                placeholder={t('editContactPersonDetailsModal.phone.placeholder')}
                error={!!errors.phone}
                helperText={errors.phone?.message}
                rules={{
                  required: t('validation.required'),
                  validate: {
                    isValid: (v) => validatePhone(v as string)
                        || t('validation.invalidPhone'),
                    maxLength: (v) => maxLengthValidator(19, v as string)
                        || t('validation.maxLength', { length: 19 }),
                  },
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container columnSpacing={2} justifyContent="flex-end">
            <Grid item xs={2.5}>
              <Button fullWidth isLoading={isLoading} disabled={!isDirty} type="submit">
                {t('common.save')}
              </Button>
            </Grid>
            <Grid item xs={2.5}>
              <Button fullWidth variant="outlined" disabled={isLoading} onClick={onClose}>
                {t('common.cancel')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default memo(EditContactPersonDetailsModal);
