import {
  Dispatch, SetStateAction, useCallback, useState,
} from 'react';
import { upperFirst } from 'lodash';
import {
  useSnackbar,
  useTable,
  useQueryTableFetch,
  useTranslations,
  useTableLocalization,
  useFilterButton,
  useTablePreserverActivePageValidator,
  useTablePreserverDecoder,
  useTablePreserverEncoder,
  usePubSub,
  buildTablePreserverDecoderConfigItem,
  TablePreserverCommonInitialTableValues,
  IUseTableReturn,
  IUseTableResetTableMethodsReturn,
  IUseQueryTableFetchReturn,
  IUseTableLocalizationReturn,
  IUseFilterButtonReturn,
  TUseTablePreserverDecoderConfigs,
  TablePreserverDecoderConfigItemTypeEnum,
  TSetSearchParams,
} from '@uniqkey-frontend/shared-app';
import {
  PartnerOrderProperty,
  PartnerType,
} from '@uniqkey-backend-partner/api-client';
import { useSearchParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import PubSubEventEnum from '../../../enums/PubSubEventEnum';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';
import {
  IGetPartnersParams,
  TGetPartnersMethod,
} from '../../usePartnersAPI/interfaces';
import { IPartnersTableRow } from '../../../components/tables/PartnersTable';
import usePartnersAPI from '../../usePartnersAPI';
import {
  IPartnersListFilterSubmitResult,
} from '../../../pages/PartnersPage/components/PartnersListFilter';

export const REACT_QUERY_PARTNERS_KEY = [ReactQueryKeyEnum.Partners];

interface IPersistentFilters extends Pick<
  IGetPartnersParams, 'partnerId'
> {}

export interface IUsePartnersTableParams {
  persistentFilters?: IPersistentFilters;
  noDataMessageKey: string;
}

export interface IUsePartnersTableReturn extends
  Omit<IUseTableReturn<IPartnersTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Pick<IUseTableResetTableMethodsReturn, 'resetActivePage'>,
  Omit<IUseQueryTableFetchReturn<TGetPartnersMethod>, 'data' | 'isFetchedAfterMount'>,
  IUseFilterButtonReturn
{
  partners: IUseQueryTableFetchReturn<TGetPartnersMethod>['data'];
  selectedPartners: IUseTableReturn<IPartnersTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
  searchQuery: IGetPartnersParams['searchQuery'];
  setSearchQuery: Dispatch<SetStateAction<IGetPartnersParams['searchQuery']>>;
  filterValues: IPartnersListFilterSubmitResult;
  setFilterValues: Dispatch<SetStateAction<IPartnersListFilterSubmitResult>>;
}

interface ITablePreservationConfigs extends
  Omit<IGetPartnersParams,
    'page' | 'pageLength' | 'partnerId' | 'orderPropertyName' | 'isDescending'
  >,
  Pick<IUseTableReturn<IPartnersTableRow>, 'columnOrderBy' | 'columnOrderDirection'>
{
  activePage: IGetPartnersParams['page'];
  perPage: IGetPartnersParams['pageLength'];
}

const PRESERVATION_CONFIGS: TUseTablePreserverDecoderConfigs<ITablePreservationConfigs> = {
  activePage: true,
  perPage: true,
  searchQuery: true,
  columnOrderBy: buildTablePreserverDecoderConfigItem(
    TablePreserverDecoderConfigItemTypeEnum.ENUM,
    { enumToCheck: PartnerOrderProperty },
  ),
  columnOrderDirection: true,
  typeFilter: buildTablePreserverDecoderConfigItem(
    TablePreserverDecoderConfigItemTypeEnum.ENUM,
    { enumToCheck: PartnerType },
  ),
  countryFilter: buildTablePreserverDecoderConfigItem(
    TablePreserverDecoderConfigItemTypeEnum.STRING,
  ),
};

const usePartnersTable = (
  params: IUsePartnersTableParams,
): IUsePartnersTableReturn => {
  const {
    persistentFilters,
    noDataMessageKey,
  } = params;

  const queryClient = useQueryClient();
  const { t } = useTranslations();
  const { showError } = useSnackbar();
  const { getPartners } = usePartnersAPI();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOnRequestError = useCallback(() => {
    showError({ text: t('common.somethingWentWrong') });
  }, [showError, t]);

  const {
    activePage: initialActivePage,
    perPage: initialPerPage,
    countryFilter: initialCountryFilter,
    typeFilter: initialTypeFilter,
    searchQuery: initialSearchQuery,
    columnOrderBy: initialColumnOrderBy,
    columnOrderDirection: initialColumnOrderDirection,
  } = useTablePreserverDecoder<ITablePreservationConfigs>(searchParams, PRESERVATION_CONFIGS);

  const [searchQuery, setSearchQuery] = useState<
    IGetPartnersParams['searchQuery']
  >(() => initialSearchQuery);
  const [filterValues, setFilterValues] = useState<
    IPartnersListFilterSubmitResult
  >({
    countryFilter: initialCountryFilter,
    typeFilter: initialTypeFilter,
  });

  const { isFilterActive, numberOfActiveFilters } = useFilterButton(filterValues);

  const {
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedRows: selectedPartners,
    resetTableMethods,
    ...restTableProps
  } = useTable<IPartnersTableRow>({
    initialActivePage,
    initialPerPage,
    initialColumnOrderBy,
    initialColumnOrderDirection,
  });

  const {
    resetTable,
    resetActivePage,
  } = resetTableMethods;

  const handleTableReset = useCallback(() => {
    resetTable();
    setSearchQuery(TablePreserverCommonInitialTableValues.searchQuery);
    setFilterValues({
      countryFilter: undefined,
      typeFilter: undefined,
    });
  }, [resetTable]);
  usePubSub(PubSubEventEnum.RESET_TABLE, handleTableReset);

  const {
    data: partners, isLoading, isFetchedAfterMount, total, resetQuery,
  } = useQueryTableFetch({
    queryKey: REACT_QUERY_PARTNERS_KEY,
    queryClient,
    request: getPartners,
    params: {
      page: activePage,
      pageLength: perPage,
      typeFilter: filterValues.typeFilter,
      countryFilter: filterValues.countryFilter,
      searchQuery,
      orderPropertyName: PartnerOrderProperty[
        upperFirst(columnOrderBy) as keyof typeof PartnerOrderProperty
      ],
      isDescending: columnOrderDirection ? columnOrderDirection === 'desc' : undefined,
      ...persistentFilters,
    },
    onRequestError: handleOnRequestError,
    autoRefetch: true,
  });

  const { localization } = useTableLocalization({
    searchQuery,
    isFilterActive,
    noDataMessageKey,
    isLoading,
  });

  useTablePreserverActivePageValidator({
    activePage, resetActivePage, perPage, total, isFetchedAfterMount,
  });

  useTablePreserverEncoder<ITablePreservationConfigs>(setSearchParams as TSetSearchParams, {
    activePage,
    perPage,
    searchQuery,
    columnOrderBy,
    columnOrderDirection,
    countryFilter: filterValues.countryFilter,
    typeFilter: filterValues.typeFilter,
  });

  return {
    partners,
    isLoading,
    total,
    resetQuery,
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedPartners,
    localization,
    searchQuery,
    setSearchQuery,
    filterValues,
    setFilterValues,
    isFilterActive,
    numberOfActiveFilters,
    resetActivePage,
    ...restTableProps,
  };
};

export default usePartnersTable;
