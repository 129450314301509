import {
  decryptAsymmetric,
  type Either,
} from '@uniqkey-frontend/shared-app';
import {
  KeysManagerCannotDecryptPartnerPrivateKeyError,
  type TKeysManagerDecryptPartnerPrivateKeyOperationErrors,
} from './errors';
import type { IPartnerKeys, IPartnerUserKeys } from '../../../common/interfaces';

type TPartnerPrivateKey = NonNullable<IPartnerKeys['privateKey']>;

interface IDecryptPartnerPrivateKeyOperationParams {
  encryptedPrivateKey: TPartnerPrivateKey;
  partnerUserPrivateKey: IPartnerUserKeys['privateKey'];
  partnerUserPublicKey: IPartnerUserKeys['publicKey'];
}

interface IDecryptPartnerPrivateKeyOperationReturn {
  privateKey: TPartnerPrivateKey;
}

const decryptPartnerPrivateKeyOperation = async (
  params: IDecryptPartnerPrivateKeyOperationParams,
): Promise<Either<
  IDecryptPartnerPrivateKeyOperationReturn,
  TKeysManagerDecryptPartnerPrivateKeyOperationErrors
>> => {
  try {
    const { encryptedPrivateKey, partnerUserPrivateKey, partnerUserPublicKey } = params;

    const decryptedPrivateKey = await decryptAsymmetric({
      cipher: encryptedPrivateKey,
      privateKey: partnerUserPrivateKey,
      publicKey: partnerUserPublicKey,
    });

    return {
      privateKey: decryptedPrivateKey,
    };
  } catch (e) {
    return new KeysManagerCannotDecryptPartnerPrivateKeyError();
  }
};

export default decryptPartnerPrivateKeyOperation;
