import { useMemo, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BreadcrumbsContainer,
  Breadcrumbs,
  useSnackbar,
  useTranslations,
} from '@uniqkey-frontend/shared-app';
import PageRouteEnum from '../../../../enums/PageRouteEnum';
import { useGetGroupById } from '../../../../hooks/reactQuery';

interface IGroupPageBreadcrumbsProps {
  groupId: string;
}

const NO_ACCESS_TO_GROUP_ERROR = 'No_access_to_group';

const GroupPageBreadcrumbs = (props: IGroupPageBreadcrumbsProps) => {
  const { groupId } = props;
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { showError } = useSnackbar();

  const { data: group, isLoading } = useGetGroupById(
    { groupId },
    {
      onError: (e: any) => {
        let key = 'common.somethingWentWrong';
        if (e?.response?.data?.includes(NO_ACCESS_TO_GROUP_ERROR)) {
          key = 'groupPage.noAccessToGroup';
        }
        showError({ text: t(key) });
        navigate(PageRouteEnum.Groups);
      },
    },
  );

  const breadcrumbsNodes = useMemo(() => {
    if (isLoading || !group) {
      return [];
    }
    return [
      {
        title: t('navigation.groups'),
        onClick: () => navigate(PageRouteEnum.Groups),
      },
      { title: group.name },
    ];
  }, [isLoading, group, navigate, t]);

  return (
    <BreadcrumbsContainer>
      <Breadcrumbs
        nodes={breadcrumbsNodes}
        isLoading={isLoading}
      />
    </BreadcrumbsContainer>
  );
};

export default memo(GroupPageBreadcrumbs);
