import {
  derivePasswordKey,
  BaseEitherError,
  type Either,
} from '@uniqkey-frontend/shared-app';
import type { IPartnerUserKeys } from '../../../common/interfaces';
import {
  KeysManagerSetupPartnerUserKeysUnknownError,
  type TKeysManagerSetupPartnerUserKeysOperationErrors,
} from './errors';
import Operations from '../index';

interface ISetupPartnerUserKeysOperationParams {
  userId: string;
  password: string;
}

const setupPartnerUserKeysOperation = async (
  params: ISetupPartnerUserKeysOperationParams,
): Promise<Either<IPartnerUserKeys, TKeysManagerSetupPartnerUserKeysOperationErrors>> => {
  try {
    const { userId, password } = params;

    const derivedMasterPassword = await derivePasswordKey(password, userId);

    const fetchPartnerUserKeysResult = await Operations.fetchPartnerUserKeysOperation();
    if (fetchPartnerUserKeysResult instanceof BaseEitherError) {
      const createPartnerUserKeysResult = await Operations.createPartnerUserKeysOperation({
        derivedMasterPassword,
      });
      return createPartnerUserKeysResult;
    }

    const { privateKey: encryptedPrivateKey } = fetchPartnerUserKeysResult;

    const decryptPartnerUserPrivateKeyResult = Operations.decryptPartnerUserPrivateKeyOperation({
      derivedMasterPassword,
      encryptedPrivateKey,
    });
    if (decryptPartnerUserPrivateKeyResult instanceof BaseEitherError) {
      const createPartnerUserKeysResult = await Operations.createPartnerUserKeysOperation({
        derivedMasterPassword,
      });
      return createPartnerUserKeysResult;
    }

    const { privateKey } = decryptPartnerUserPrivateKeyResult;
    const { publicKey } = fetchPartnerUserKeysResult;

    return {
      privateKey,
      publicKey,
    };
  } catch (e) {
    return new KeysManagerSetupPartnerUserKeysUnknownError();
  }
};

export default setupPartnerUserKeysOperation;
