import { useMemo, memo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BreadcrumbsContainer,
  Breadcrumbs,
  useSnackbar,
  useTranslations,
} from '@uniqkey-frontend/shared-app';
import PageRouteEnum from '../../../../enums/PageRouteEnum';
import { useGetGroupById, useGetOrganizationById } from '../../../../hooks/reactQuery';

interface IGroupOrganizationPageBreadcrumbsProps {
  groupId: string;
  organizationId: string;
}

const GroupOrganizationPageBreadcrumbs = (props: IGroupOrganizationPageBreadcrumbsProps) => {
  const {
    groupId, organizationId,
  } = props;
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { showError } = useSnackbar();

  const {
    data: group,
    isLoading: isGroupLoading,
    isError: isGroupError,
  } = useGetGroupById(
    { groupId },
  );

  const {
    data: organization,
    isLoading: isOrganizationLoading,
    isError: isOrganizationError,
  } = useGetOrganizationById(
    { organizationId },
  );

  const isLoading = isGroupLoading || isOrganizationLoading;

  const breadcrumbsNodes = useMemo(() => {
    if (isLoading || !group || !organization) {
      return [];
    }
    return [
      {
        title: t('navigation.groups'),
        onClick: () => navigate(PageRouteEnum.Groups),
      },
      {
        title: t(group.name),
        onClick: () => navigate(`${PageRouteEnum.Groups}/${groupId}`),
      },
      {
        title: t(organization.name),
      },
    ];
  }, [group, groupId, isLoading, navigate, organization, t]);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (isGroupError) {
      showError({ text: t('common.somethingWentWrong') });
      navigate(PageRouteEnum.Groups);
      return;
    }
    if (isOrganizationError) {
      showError({ text: t('common.somethingWentWrong') });
      navigate(`${PageRouteEnum.Groups}/${groupId}`);
    }
  }, [groupId, isGroupError, isLoading, isOrganizationError, navigate, showError, t]);

  return (
    <BreadcrumbsContainer>
      <Breadcrumbs
        nodes={breadcrumbsNodes}
        isLoading={isLoading}
      />
    </BreadcrumbsContainer>
  );
};

export default memo(GroupOrganizationPageBreadcrumbs);
