import { Suspense } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import {
  getQueryClientDefaultOptions,
  loadCustomScrollbarPlugins,
  loadStyles,
} from '@uniqkey-frontend/shared-app';
import Router from './Router';
import APIClientsProviderContext from './contexts/APIClientsContext/provider';
import UserProviderContext from './contexts/UserContext/provider';
import { listenRefreshTokensEvents } from './services/tokensManager';
import InitializationContainer from './components/InitializationContainer';

loadStyles({
  themeColors: true,
  dateRangePicker: true,
  snackbar: true,
  customScrollbar: true,
});

loadCustomScrollbarPlugins();

listenRefreshTokensEvents();

const ReactQueryClient = new QueryClient({
  defaultOptions: getQueryClientDefaultOptions(),
});

const App = () => (
  <QueryClientProvider client={ReactQueryClient}>
    <APIClientsProviderContext>
      <UserProviderContext>
        <InitializationContainer>
          <Suspense fallback={null}>
            <Router />
          </Suspense>
        </InitializationContainer>
        <ReactQueryDevtools />
      </UserProviderContext>
    </APIClientsProviderContext>
  </QueryClientProvider>
);
export default App;
