import type { Either } from '@uniqkey-frontend/shared-app';
import type { GetPartnerUserKeyResponse } from '@uniqkey-backend-partner/api-client';
import {
  KeysManagerFetchPartnerUserKeysError,
  type TKeysManagerFetchPartnerUserKeysOperationErrors,
} from './errors';
import ApiClientsProvider from '../../../../../common/apiClientsProvider';
import { axiosInstance } from '../../../../../axios';
import { dataExtractor } from '../../../../../helpers/apiClients';

const fetchPartnerUserKeysOperation = async (): Promise<
  Either<GetPartnerUserKeyResponse, TKeysManagerFetchPartnerUserKeysOperationErrors>
> => {
  try {
    const result = await ApiClientsProvider.getPartnerUserKeysAPIClient(axiosInstance)
      .apiV1PartnerUserKeysGet()
      .then(dataExtractor<GetPartnerUserKeyResponse>());
    return result;
  } catch (e) {
    return new KeysManagerFetchPartnerUserKeysError();
  }
};

export default fetchPartnerUserKeysOperation;
