import { memo } from 'react';
import {
  Grid,
  Typography,
  TypographyWithHelp,
  TypographyWithCountUp,
  Divider,
  S6,
} from '@uniqkey-frontend/shared-app';
import ACLEnum from '../../../../../../../../enums/ACLEnum';
import { useUser } from '../../../../../../../../contexts/UserContext';

interface IOrganizationUserDetailsWidgetItemProps {
  title: string;
  titleHelperText: string;
  linkText: string;
  count: number;
  onClick: () => void;
  showDivider: boolean;
}

const TITLE_TYPOGRAPHY_PROPS = {
  variant: 'caption', noWrap: true,
};

const OrganizationUserDetailsWidgetItem = (props: IOrganizationUserDetailsWidgetItemProps) => {
  const {
    onClick,
    title,
    titleHelperText,
    linkText,
    count,
    showDivider,
  } = props;

  const { userCan } = useUser();

  return (
    <Grid item container xs={6} flexDirection="column" alignItems="center">
      <Grid item container flexDirection="column" alignItems="center">
        <Grid item mt={2}>
          <TypographyWithHelp helperText={titleHelperText} TypographyProps={TITLE_TYPOGRAPHY_PROPS}>
            {title}
          </TypographyWithHelp>
        </Grid>
        <Grid item mt={1}>
          <TypographyWithCountUp variant="h3" value={count} color={S6} />
        </Grid>
      </Grid>
      <Grid item mt={4} mb={1}>
        {userCan(ACLEnum.EmployeeAccountView) && (
          <Typography
            asLink
            onClick={onClick}
            variant="subtitle1"
          >
            {linkText}
          </Typography>
        )}
      </Grid>
      {showDivider && <Divider orientation="vertical" />}
    </Grid>
  );
};

export default memo(OrganizationUserDetailsWidgetItem);
