import { useCallback } from 'react';
import { type AxiosRequestConfig } from 'axios';
import {
  GroupsResponse,
  CreateGroupRequest,
  NoContentResult,
  GroupByIdResponse,
  UpdateGroupRequest,
  AddUsersToGroupRequest,
  AddUsersToGroupResponse,
  GroupUsersResponse,
  RemoveUsersFromGroupRequest,
  RemoveUsersFromGroupResponse,
  GroupOrganizationsResponse,
  AddOrganizationsToGroupRequest,
  AddOrganizationsToGroupResponse,
  RemoveOrganizationsFromGroupRequest,
  RemoveOrganizationsFromGroupResponse,
  GroupOrganizationsForAdminResponse,
  GroupLatestOrganizationsResponse,
  GroupLatestOrganizationsForAdminResponse,
  GetUsersForAddLookupResponse,
  GetOrganizationsForAddLookupResponse,
} from '@uniqkey-backend-partner/api-client';
import { useApiClients } from '../../contexts/APIClientsContext';
import { dataExtractor } from '../../helpers/apiClients';
import {
  TGetGroupsMethod,
  TGetGroupUsersMethod,
  TGetGroupOrganizationsMethod,
  TGetGroupOrganizationsForAdminMethod,
  TGetGroupLatestOrganizationsMethod,
  TGetGroupLatestOrganizationsForAdminMethod,
  TGetGroupUsersForLookupMethod,
  TGetGroupOrganizationsForLookupMethod,
} from './interfaces';

const useGroupsAPI = () => {
  const { groupsAPIClient } = useApiClients();

  const getGroups = useCallback<TGetGroupsMethod>(
    (params, axiosRequestConfig) => {
      const {
        page,
        pageLength,
        partnerId = undefined,
        searchQuery = undefined,
        orderPropertyName = undefined,
        isDescending = undefined,
      } = params;
      return groupsAPIClient.apiV1GroupsGet(
        page,
        pageLength,
        partnerId,
        searchQuery,
        orderPropertyName,
        isDescending,
        axiosRequestConfig,
      ).then(dataExtractor<GroupsResponse>());
    },
    [groupsAPIClient],
  );

  const createGroup = useCallback(
    (params: CreateGroupRequest, options?: AxiosRequestConfig)
      : Promise<NoContentResult> => groupsAPIClient
      .apiV1GroupsPost(params, options)
      .then(dataExtractor<NoContentResult>()),
    [groupsAPIClient],
  );

  const getGroupById = useCallback(
    (groupId: string, options?: AxiosRequestConfig)
      : Promise<GroupByIdResponse> => groupsAPIClient
      .apiV1GroupsGroupIdGet(groupId, options)
      .then(dataExtractor<GroupByIdResponse>()),
    [groupsAPIClient],
  );

  const updateGroup = useCallback(
    (updateGroupRequest: UpdateGroupRequest, options?: AxiosRequestConfig)
      :Promise<NoContentResult> => groupsAPIClient
      .apiV1GroupsPut(updateGroupRequest, options)
      .then(dataExtractor<NoContentResult>()),
    [groupsAPIClient],
  );

  const addUsersToGroup = useCallback(
    (addUsersToGroupRequest: AddUsersToGroupRequest, options?: AxiosRequestConfig)
      :Promise<AddUsersToGroupResponse> => groupsAPIClient
      .apiV1GroupsAddUsersToGroupPost(addUsersToGroupRequest, options)
      .then(dataExtractor<AddUsersToGroupResponse>()),
    [groupsAPIClient],
  );

  const getGroupUsers = useCallback<TGetGroupUsersMethod>(
    (params, axiosRequestConfig) => {
      const {
        page,
        pageLength,
        groupId = undefined,
        orderPropertyName = undefined,
        isDescending = undefined,
      } = params;
      return groupsAPIClient.apiV1GroupsGroupUsersGet(
        page,
        pageLength,
        groupId,
        orderPropertyName,
        isDescending,
        axiosRequestConfig,
      ).then(dataExtractor<GroupUsersResponse>());
    },
    [groupsAPIClient],
  );

  const removeUsersFromGroup = useCallback(
    (removeUsersFromGroupRequest: RemoveUsersFromGroupRequest, options?: AxiosRequestConfig)
      :Promise<RemoveUsersFromGroupResponse> => groupsAPIClient
      .apiV1GroupsRemoveUsersFromGroupPost(removeUsersFromGroupRequest, options)
      .then(dataExtractor<RemoveUsersFromGroupResponse>()),
    [groupsAPIClient],
  );

  const getGroupOrganizations = useCallback<TGetGroupOrganizationsMethod>(
    (params, axiosRequestConfig) => {
      const {
        page,
        pageLength,
        groupId = undefined,
        orderPropertyName = undefined,
        isDescending = undefined,
      } = params;
      return groupsAPIClient.apiV1GroupsGroupOrganizationsGet(
        page,
        pageLength,
        groupId,
        orderPropertyName,
        isDescending,
        axiosRequestConfig,
      ).then(dataExtractor<GroupOrganizationsResponse>());
    },
    [groupsAPIClient],
  );

  const addOrganizationsToGroup = useCallback(
    (
      addOrganizationsToGroupRequest: AddOrganizationsToGroupRequest,
      options?: AxiosRequestConfig,
    ): Promise<AddOrganizationsToGroupResponse> => groupsAPIClient
      .apiV1GroupsAddOrganizationsToGroupPost(addOrganizationsToGroupRequest, options)
      .then(dataExtractor<AddOrganizationsToGroupResponse>()),
    [groupsAPIClient],
  );

  const removeOrganizationsFromGroup = useCallback(
    (
      removeOrganizationsFromGroupRequest: RemoveOrganizationsFromGroupRequest,
      options?: AxiosRequestConfig,
    ): Promise<RemoveOrganizationsFromGroupResponse> => groupsAPIClient
      .apiV1GroupsRemoveOrganizationsFromGroupPost(removeOrganizationsFromGroupRequest, options)
      .then(dataExtractor<RemoveOrganizationsFromGroupResponse>()),
    [groupsAPIClient],
  );

  const getGroupOrganizationsForAdmin = useCallback<TGetGroupOrganizationsForAdminMethod>(
    (params, axiosRequestConfig) => {
      const {
        page,
        pageLength,
        groupId = undefined,
        orderPropertyName = undefined,
        isDescending = undefined,
      } = params;
      return groupsAPIClient.apiV1GroupsGroupOrganizationsForAdminGet(
        page,
        pageLength,
        groupId,
        orderPropertyName,
        isDescending,
        axiosRequestConfig,
      ).then(dataExtractor<GroupOrganizationsForAdminResponse>());
    },
    [groupsAPIClient],
  );

  const getGroupLatestOrganizations = useCallback<TGetGroupLatestOrganizationsMethod>(
    (params, axiosRequestConfig) => {
      const {
        groupId,
        page,
        pageLength,
      } = params;
      return groupsAPIClient.apiV1GroupsGroupLatestOrganizationsGet(
        groupId,
        page,
        pageLength,
        axiosRequestConfig,
      ).then(dataExtractor<GroupLatestOrganizationsResponse>());
    },
    [groupsAPIClient],
  );

  const getGroupLatestOrganizationsForAdmin = useCallback<
    TGetGroupLatestOrganizationsForAdminMethod
  >(
    (params, axiosRequestConfig) => {
      const {
        groupId,
        page,
        pageLength,
      } = params;
      return groupsAPIClient.apiV1GroupsGroupLatestOrganizationsForAdminGet(
        groupId,
        page,
        pageLength,
        axiosRequestConfig,
      ).then(dataExtractor<GroupLatestOrganizationsForAdminResponse>());
    },
    [groupsAPIClient],
  );

  const getGroupUsersForLookup = useCallback<TGetGroupUsersForLookupMethod>(
    (params, axiosRequestConfig) => {
      const {
        page,
        pageLength,
        groupId,
        searchQuery = undefined,
      } = params;
      return groupsAPIClient.apiV1GroupsGetUsersForAddLookupGet(
        page,
        pageLength,
        groupId,
        searchQuery,
        axiosRequestConfig,
      ).then(dataExtractor<GetUsersForAddLookupResponse>());
    },
    [groupsAPIClient],
  );

  const getGroupOrganizationsForLookup = useCallback<TGetGroupOrganizationsForLookupMethod>(
    (params, axiosRequestConfig) => {
      const {
        page,
        pageLength,
        groupId = undefined,
        searchQuery = undefined,
      } = params;
      return groupsAPIClient.apiV1GroupsGetOrganizationsForAddLookupGet(
        page,
        pageLength,
        groupId,
        searchQuery,
        axiosRequestConfig,
      ).then(dataExtractor<GetOrganizationsForAddLookupResponse>());
    },
    [groupsAPIClient],
  );

  return ({
    getGroups,
    createGroup,
    getGroupById,
    updateGroup,
    addUsersToGroup,
    getGroupUsers,
    removeUsersFromGroup,
    getGroupOrganizations,
    addOrganizationsToGroup,
    removeOrganizationsFromGroup,
    getGroupOrganizationsForAdmin,
    getGroupLatestOrganizations,
    getGroupLatestOrganizationsForAdmin,
    getGroupUsersForLookup,
    getGroupOrganizationsForLookup,
  });
};

export default useGroupsAPI;
