import { useEffect } from 'react';
import { ExportLicenseDataResultNotification } from '@uniqkey-backend-partner/api-client';
import { downloadCSV, useSnackbar, useTranslations } from '@uniqkey-frontend/shared-app';
import { subscribeToRealtimeAPIEvent } from '../../services/webSocketsManager';
import RealtimeAPIEventTypeEnum from '../../enums/RealtimeAPIEventTypeEnum';
import { logException } from '../../services/sentryService';
import usePartnerOrganizationsAPI from '../usePartnerOrganizationsAPI';

const CSV_NAME = 'organizations_license_data';

const FILE_NOT_FOUND_ERROR = 'File_Not_Found';

interface IUseSubscribeToExportLicenseDataResultNotificationEventParams {
  from: string;
  onSuccess: () => void;
  onError: () => void;
}

const useSubscribeToExportLicenseDataResultNotificationEvent = (
  params: IUseSubscribeToExportLicenseDataResultNotificationEventParams,
) => {
  const { from, onSuccess, onError } = params;
  const { showSuccess, showError } = useSnackbar();
  const { t } = useTranslations();
  const { getExportDataResult } = usePartnerOrganizationsAPI();

  useEffect(() => {
    const unsubscribe = subscribeToRealtimeAPIEvent<ExportLicenseDataResultNotification>(
      RealtimeAPIEventTypeEnum.ExportLicenseDataResultNotification,
      async (event) => {
        const { isSuccessful, fileName } = event;
        try {
          if (!isSuccessful) {
            showError({ text: t('downloadCSVModal.exportFailedMessage') });
            return;
          }
          const csv = await getExportDataResult(fileName!);
          downloadCSV({ csv, name: CSV_NAME });
          onSuccess();
          showSuccess({ text: t('downloadCSVModal.successMessage') });
        } catch (e: any) {
          onError();
          let key = 'common.somethingWentWrong';
          if (e?.response?.data?.includes(FILE_NOT_FOUND_ERROR)) {
            key = 'downloadCSVModal.fileNotFoundMessage';
          }
          showError({ text: t(key) });
          logException(e, {
            // eslint-disable-next-line max-len
            message: `${from}/RealtimeAPIEventTypeEnum.ExportLicenseDataResultNotification exception`,
          });
        }
      },
    );
    return () => {
      unsubscribe();
    };
  }, [from, getExportDataResult, onSuccess, onError, showError, showSuccess, t]);
};

export default useSubscribeToExportLicenseDataResultNotificationEvent;
