import { memo, useState, useCallback } from 'react';
import {
  Grid,
  Typography,
  TypographyWithTooltip,
  S5,
  S6,
  useTranslations,
  EntityDetailsContainer,
  useSnackbar,
} from '@uniqkey-frontend/shared-app';
import {
  useGetGroupById,
  useUpdateGroup,
} from '../../../../../../hooks/reactQuery';
import EditGroupModal, { IEditGroupModalReturnValue } from '../EditGroupModal';
import { logException } from '../../../../../../services/sentryService';
import { useUser } from '../../../../../../contexts/UserContext';
import ACLEnum from '../../../../../../enums/ACLEnum';

interface IGroupDetailsWidgetProps {
  groupId: string;
}

const GroupDetailsWidget = (props: IGroupDetailsWidgetProps) => {
  const { groupId } = props;
  const { t } = useTranslations();
  const { showError, showSuccess } = useSnackbar();
  const { userCan } = useUser();

  const [isEditGroupModalOpen, setIsEditGroupModalOpen] = useState(false);

  const handleEditGroupModalOpen = useCallback(
    () => setIsEditGroupModalOpen(true),
    [],
  );
  const handleEditGroupModalClose = useCallback(
    () => setIsEditGroupModalOpen(false),
    [],
  );

  const { data: group, isError, isLoading: isGroupLoading } = useGetGroupById(
    { groupId },
  );

  const { mutate: mutateGroup, isLoading: isUpdateGroupLoading } = useUpdateGroup({
    groupId,
    useOptimisticUpdates: true,
  });

  const {
    name: groupName,
    description: groupDescription,
  } = group ?? {};

  const handleUpdateGroup = useCallback((value: IEditGroupModalReturnValue) => {
    mutateGroup(
      { groupId, ...value },
      {
        onError: (e) => {
          showError({ text: t('common.somethingWentWrong') });
          logException(e, { message: 'GroupDetailsWidget/handleUpdateGroup exception' });
        },
        onSuccess: () => {
          showSuccess({ text: t('editGroupModal.groupEdited') });
          handleEditGroupModalClose();
        },
      },
    );
  }, [mutateGroup, groupId, handleEditGroupModalClose, showError, showSuccess, t]);

  if (isError) {
    return null;
  }

  return (
    <>
      <EntityDetailsContainer
        container
        isLoading={isGroupLoading}
        onClick={handleEditGroupModalOpen}
        editable={userCan(ACLEnum.GroupEdit)}
        justifyContent="space-between"
        rowGap={3}
      >
        <Grid container item flexDirection="column">
          <Typography variant="caption" color={S5} mb={1}>
            {t('groupDetailsWidget.name')}
          </Typography>
          <TypographyWithTooltip variant="h6" color={S6} breakWord>
            {groupName}
          </TypographyWithTooltip>
        </Grid>
        <Grid container item flexDirection="column">
          <Typography variant="caption" color={S5} mb={1}>
            {t('groupDetailsWidget.description')}
          </Typography>
          <TypographyWithTooltip color={S5} breakWord>
            {groupDescription}
          </TypographyWithTooltip>
        </Grid>
      </EntityDetailsContainer>
      {isEditGroupModalOpen && (
        <EditGroupModal
          isOpen={isEditGroupModalOpen}
          isLoading={isUpdateGroupLoading}
          onClose={handleEditGroupModalClose}
          onSubmit={handleUpdateGroup}
          group={group!}
        />
      )}
    </>
  );
};

export default memo(GroupDetailsWidget);
