import { useCallback, useMemo, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TypographyWithTooltip,
  useTranslations,
  IUseTableReturn,
  IUseQueryTableFetchReturn,
  IUseTableLocalizationReturn,
  IMaterialTableOptions,
  IMaterialTableProps,
} from '@uniqkey-frontend/shared-app';
import {
  GetPartnerUsersForAdminResponsePartnerUser, KeysState,
} from '@uniqkey-backend-partner/api-client';
import PageRouteEnum from '../../../enums/PageRouteEnum';
import { USER_ROLES_TRANSLATION_KEYS, KEYS_STATE_TRANSLATION_KEYS } from '../../../constants';
import { TGetPartnerUsersForAdminMethod } from '../../../hooks/usePartnerUsersAPI/interfaces';

export interface IPartnerUsersForAdminTableRow extends GetPartnerUsersForAdminResponsePartnerUser {}

interface IPartnerUsersForAdminTableProps extends
  Omit<IUseTableReturn<IPartnerUsersForAdminTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Omit<
    IUseQueryTableFetchReturn<TGetPartnerUsersForAdminMethod>,
    'data' | 'resetQuery' | 'isFetchedAfterMount'
  >
{
  partnerUsersForAdmin: IUseQueryTableFetchReturn<TGetPartnerUsersForAdminMethod>['data'];
  selectedPartnerUsersForAdmin: IUseTableReturn<IPartnerUsersForAdminTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
  options?: IMaterialTableOptions<IPartnerUsersForAdminTableRow>;
}

const KEYS_STATE_TOOLTIP_TRANSLATION_KEYS = {
  [KeysState.AllKeysAvailable]: 'keysState.tooltip.allKeysAvailable',
  [KeysState.UserKeyUnavailable]: 'keysState.tooltip.userKeyUnavailable',
  [KeysState.PartnerKeyUnavailable]: 'keysState.tooltip.partnerKeyUnavailable',
  [KeysState.PartnerUserToPartnerKeyUnavailable]:
    'keysState.tooltip.partnerUserToPartnerKeyUnavailable',
};

const PartnerUsersForAdminTable = (props: IPartnerUsersForAdminTableProps) => {
  const {
    partnerUsersForAdmin,
    isLoading,
    total,
    localization,
    activePage,
    setActivePage,
    perPage,
    onPerPageChange,
    columnOrderBy,
    onColumnOrderByChange,
    columnOrderDirection,
    onColumnOrderDirectionChange,
    selectedPartnerUsersForAdmin,
    onRowSelectionChange,
    createColumns,
    options,
  } = props;

  const { t } = useTranslations();
  const navigate = useNavigate();

  const columns = useMemo(() => createColumns([
    {
      title: t('partnerUsersForAdminPage.name'),
      field: 'name',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.name}
        </TypographyWithTooltip>
      ),
      width: '25%',
    },
    {
      title: t('partnerUsersForAdminPage.email'),
      field: 'email',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.email}
        </TypographyWithTooltip>
      ),
      width: '35%',
    },
    {
      title: t('partnerUsersForAdminPage.role'),
      field: 'role',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {t(USER_ROLES_TRANSLATION_KEYS[rowData.role as keyof typeof USER_ROLES_TRANSLATION_KEYS])}
        </TypographyWithTooltip>
      ),
      width: '15%',
      sorting: false,
    },
    {
      title: t('partnerUsersForAdminPage.keysState'),
      field: 'keysState',
      render: (rowData) => (
        <TypographyWithTooltip
          variant="body2"
          title={t(KEYS_STATE_TOOLTIP_TRANSLATION_KEYS[rowData.keysState])}
        >
          {t(KEYS_STATE_TRANSLATION_KEYS[rowData.keysState])}
        </TypographyWithTooltip>
      ),
      width: '25%',
      sorting: false,
    },
  ]), [createColumns, t]);

  const handlePartnerUserRowClick = useCallback<
    NonNullable<IMaterialTableProps<IPartnerUsersForAdminTableRow>['onRowClick']>
  >((event, partnerUser) => {
    navigate(`${PageRouteEnum.PartnerUsers}/${partnerUser!.partnerUserId}`);
  }, [navigate]);

  return (
    <Table
      t={t}
      totalItems={total}
      columns={columns}
      data={partnerUsersForAdmin}
      isLoading={isLoading}
      options={options}
      activePage={activePage}
      onActivePageChange={setActivePage}
      perPage={perPage}
      onPerPageChange={onPerPageChange}
      columnOrderBy={columnOrderBy}
      onColumnOrderByChange={onColumnOrderByChange}
      columnOrderDirection={columnOrderDirection}
      onColumnOrderDirectionChange={onColumnOrderDirectionChange}
      selectedRows={selectedPartnerUsersForAdmin}
      onSelectionChange={onRowSelectionChange}
      localization={localization}
      onRowClick={handlePartnerUserRowClick}
      rowSelectByKey="partnerUserId"
      rowHeight={51}
    />
  );
};

export default memo(PartnerUsersForAdminTable);
