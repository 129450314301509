import { memo } from 'react';
import {
  ScrollablePanelContent, Grid,
} from '@uniqkey-frontend/shared-app';
import { useGetOrganizationById } from '../../../../hooks/reactQuery';
import OrganizationUserDetailsWidget from './components/OrganizationUserDetailsWidget';
import OrganizationDetailsWidget from './components/OrganizationDetailsWidget';
import RequestAccessWidget from './components/RequestAccessWidget';

interface IOrganizationDetailsTabProps {
  organizationId: string;
}

const OrganizationDetailsTab = (props: IOrganizationDetailsTabProps) => {
  const { organizationId } = props;

  const { data: organization, isLoading, isError } = useGetOrganizationById(
    { organizationId },
  );

  if (isError) {
    return null;
  }

  return (
    <ScrollablePanelContent p={3} pt={1}>
      <Grid container rowGap={3}>
        <RequestAccessWidget organizationId={organizationId} />
        <OrganizationUserDetailsWidget
          organizationId={organizationId}
          organization={organization}
          isLoading={isLoading}
          isError={isError}
        />
        <OrganizationDetailsWidget
          organizationId={organizationId}
          organization={organization}
          isLoading={isLoading}
          isError={isError}
        />
      </Grid>
    </ScrollablePanelContent>
  );
};

export default memo(OrganizationDetailsTab);
