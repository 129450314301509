import { memo } from 'react';
import {
  Grid,
  ScrollablePanelContent,
} from '@uniqkey-frontend/shared-app';
import PartnerUserDetailsWidget from './components/PartnerUserDetailsWidget';
import PartnerUserResetWidget from './components/PartnerUserResetWidget';

interface IPartnerUserDetailsTabProps {
  partnerUserId: string;
}

const PartnerUserDetailsTab = (props: IPartnerUserDetailsTabProps) => {
  const { partnerUserId } = props;

  return (
    <ScrollablePanelContent p={3} pt={1}>
      <Grid container spacing={2}>
        <Grid container item xs={6} rowGap={3}>
          <PartnerUserDetailsWidget partnerUserId={partnerUserId} />
          <PartnerUserResetWidget partnerUserId={partnerUserId} />
        </Grid>
        <Grid item xs={6} />
      </Grid>
    </ScrollablePanelContent>
  );
};

export default memo(PartnerUserDetailsTab);
