import {
  useCallback, Dispatch, SetStateAction, useState,
} from 'react';
import {
  useSnackbar,
  useTable,
  useQueryTableFetch,
  useTranslations,
  useTableLocalization,
  useTablePreserverActivePageValidator,
  useTablePreserverDecoder,
  useTablePreserverEncoder,
  usePubSub,
  IUseTableReturn,
  IUseTableResetTableMethodsReturn,
  IUseQueryTableFetchReturn,
  IUseTableLocalizationReturn,
  TUseTablePreserverDecoderConfigs,
  TSetSearchParams,
  IUseFilterButtonReturn,
  buildTablePreserverDecoderConfigItem,
  TablePreserverDecoderConfigItemTypeEnum,
  TablePreserverCommonInitialTableValues,
  useFilterButton,
} from '@uniqkey-frontend/shared-app';
import { useSearchParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import PubSubEventEnum from '../../../enums/PubSubEventEnum';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';
import {
  IOrganizationAuditLogsTableRow,
} from '../../../components/tables/OrganizationAuditLogsTable';
import {
  IGetOrganizationAuditLogsParams,
  TGetOrganizationAuditLogsMethod,
} from '../../usePartnerOrganizationsAPI/interfaces';
import usePartnerOrganizationsAPI from '../../usePartnerOrganizationsAPI';
// eslint-disable-next-line max-len
import { IOrganizationAuditLogsListFilterSubmitResult } from '../../../pages/OrganizationPage/components/OrganizationAuditLogsTab/components/OrganizationAuditLogsListFilter';

export const REACT_QUERY_ORGANIZATION_AUDIT_LOGS_KEY = [ReactQueryKeyEnum.OrganizationAuditLogs];

interface IPersistentFilters extends Pick<
  IGetOrganizationAuditLogsParams, 'organizationId'
> {}

export interface IUseOrganizationAuditLogsTableParams {
  persistentFilters: IPersistentFilters;
  noDataMessageKey: string;
}

export interface IUseOrganizationAuditLogsTableReturn extends
  Omit<
    IUseTableReturn<IOrganizationAuditLogsTableRow>,
    'columnOrderBy' | 'columnOrderDirection' | 'selectedRows' | 'resetTableMethods'
  >,
  Pick<IUseTableResetTableMethodsReturn, 'resetActivePage'>,
  Omit<IUseQueryTableFetchReturn<TGetOrganizationAuditLogsMethod>, 'data' | 'isFetchedAfterMount'>,
  IUseFilterButtonReturn
{
  organizationAuditLogs: IUseQueryTableFetchReturn<TGetOrganizationAuditLogsMethod>['data'];
  selectedOrganizationAuditLogs: IUseTableReturn<IOrganizationAuditLogsTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
  filterValues: IOrganizationAuditLogsListFilterSubmitResult;
  setFilterValues: Dispatch<SetStateAction<IOrganizationAuditLogsListFilterSubmitResult>>;
}

interface ITablePreservationConfigs extends
  Omit<IGetOrganizationAuditLogsParams,
    'organizationId' | 'page' | 'pageLength' | 'employeeAccountId' | 'showOnlySupporters' |
    'beforeDate' | 'afterDate'
  >
{
  activePage: IGetOrganizationAuditLogsParams['page'];
  perPage: IGetOrganizationAuditLogsParams['pageLength'];
  employeeAccount: IOrganizationAuditLogsListFilterSubmitResult['employeeAccount'];
  dateRange: IOrganizationAuditLogsListFilterSubmitResult['dateRange'];
  showOnlySupporters: IOrganizationAuditLogsListFilterSubmitResult['showOnlySupporters'];
}

const PRESERVATION_CONFIGS: TUseTablePreserverDecoderConfigs<ITablePreservationConfigs> = {
  activePage: true,
  perPage: true,
  employeeAccount: true,
  dateRange: true,
  showOnlySupporters: buildTablePreserverDecoderConfigItem(
    TablePreserverDecoderConfigItemTypeEnum.BOOLEAN,
  ),
};

const useOrganizationAuditLogsTable = (
  params: IUseOrganizationAuditLogsTableParams,
): IUseOrganizationAuditLogsTableReturn => {
  const {
    persistentFilters,
    noDataMessageKey,
  } = params;

  const queryClient = useQueryClient();
  const { t } = useTranslations();
  const { showError } = useSnackbar();
  const { getOrganizationAuditLogs } = usePartnerOrganizationsAPI();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOnRequestError = useCallback(() => {
    showError({ text: t('common.somethingWentWrong') });
  }, [showError, t]);

  const {
    activePage: initialActivePage,
    perPage: initialPerPage,
    employeeAccount: initialEmployeeAccount,
    dateRange: initialDateRange,
    showOnlySupporters: initialShowOnlySupporters,
  } = useTablePreserverDecoder<ITablePreservationConfigs>(searchParams, PRESERVATION_CONFIGS);

  const [filterValues, setFilterValues] = useState<
    IOrganizationAuditLogsListFilterSubmitResult
  >({
    employeeAccount: initialEmployeeAccount,
    dateRange: initialDateRange,
    showOnlySupporters: initialShowOnlySupporters,
  });

  const { isFilterActive, numberOfActiveFilters } = useFilterButton(filterValues);

  const {
    activePage,
    perPage,
    selectedRows: selectedOrganizationAuditLogs,
    resetTableMethods,
    ...restTableProps
  } = useTable<IOrganizationAuditLogsTableRow>({
    initialActivePage,
    initialPerPage,
  });

  const {
    resetTable,
    resetActivePage,
  } = resetTableMethods;

  const handleTableReset = useCallback(() => {
    resetTable();
    setFilterValues({
      employeeAccount: undefined,
      dateRange: TablePreserverCommonInitialTableValues.dateRange,
      showOnlySupporters: undefined,
    });
  }, [resetTable]);
  usePubSub(PubSubEventEnum.RESET_TABLE, handleTableReset);

  const {
    data: organizationAuditLogs, isLoading, isFetchedAfterMount, total, resetQuery,
  } = useQueryTableFetch({
    queryKey: REACT_QUERY_ORGANIZATION_AUDIT_LOGS_KEY,
    queryClient,
    request: getOrganizationAuditLogs,
    params: {
      page: activePage,
      pageLength: perPage,
      employeeAccountId: filterValues.employeeAccount?.value,
      afterDate: filterValues.dateRange?.from ?? undefined,
      beforeDate: filterValues.dateRange?.to ?? undefined,
      showOnlySupporters: filterValues.showOnlySupporters,
      ...persistentFilters,
    },
    onRequestError: handleOnRequestError,
    autoRefetch: true,
  });

  const { localization } = useTableLocalization({
    isFilterActive,
    noDataMessageKey,
    isLoading,
  });

  useTablePreserverActivePageValidator({
    activePage, resetActivePage, perPage, total, isFetchedAfterMount,
  });

  useTablePreserverEncoder<ITablePreservationConfigs>(setSearchParams as TSetSearchParams, {
    activePage,
    perPage,
    employeeAccount: {
      value: filterValues.employeeAccount,
      parser: JSON.stringify,
    },
    dateRange: {
      value: filterValues.dateRange,
      parser: JSON.stringify,
    },
    showOnlySupporters: filterValues.showOnlySupporters,
  });

  return {
    organizationAuditLogs,
    isLoading,
    total,
    resetQuery,
    activePage,
    perPage,
    selectedOrganizationAuditLogs,
    localization,
    filterValues,
    setFilterValues,
    isFilterActive,
    numberOfActiveFilters,
    resetActivePage,
    ...restTableProps,
  };
};

export default useOrganizationAuditLogsTable;
