import { memo } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  ReactHookFormTextField,
  useTranslations,
  emptyStringValidator,
} from '@uniqkey-frontend/shared-app';
import { useForm } from 'react-hook-form';
import {
  GroupByIdResponse,
} from '@uniqkey-backend-partner/api-client';

interface IFormValue {
  name: GroupByIdResponse['name'];
  description: GroupByIdResponse['description'];
}

export interface IEditGroupModalReturnValue extends IFormValue {}

interface IEditGroupModalProps {
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: (params: IEditGroupModalReturnValue) => Promise<void> | void;
  group: GroupByIdResponse;
}

const EditGroupModal = (props: IEditGroupModalProps) => {
  const {
    isOpen, isLoading, onClose, onSubmit, group,
  } = props;

  const { t } = useTranslations();
  const { name, description } = group;

  const {
    handleSubmit, formState: { isDirty, errors }, control,
  } = useForm<IFormValue>({
    defaultValues: { name, description },
    mode: 'all',
  });

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      clickOutsideToClose={!isLoading}
      fullWidth
      maxWidth="sm"
    >
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <DialogTitle isLoading={isLoading} onClose={onClose}>
          {t('editGroupModal.title')}
        </DialogTitle>
        <DialogContent>
          <Grid item container flexDirection="column" rowGap={4} pt={1}>
            <Grid item>
              <ReactHookFormTextField
                control={control}
                name="name"
                fullWidth
                autoFocus
                label={`${t('editGroupModal.name.label')}*`}
                placeholder={t('editGroupModal.name.placeholder')}
                error={!!errors.name}
                helperText={errors.name?.message}
                rules={{
                  required: t('validation.required'),
                  validate: (value) => (
                    emptyStringValidator(value) ? t('validation.required') : true
                  ),
                }}
              />
            </Grid>
            <Grid item>
              <ReactHookFormTextField
                control={control}
                name="description"
                fullWidth
                label={t('editGroupModal.description.label')}
                placeholder={t('editGroupModal.description.placeholder')}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container columnSpacing={2}>
            <Grid item xs={6}>
              <Button fullWidth isLoading={isLoading} disabled={!isDirty} type="submit">
                {t('common.save')}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth variant="outlined" disabled={isLoading} onClick={onClose}>
                {t('common.cancel')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default memo(EditGroupModal);
