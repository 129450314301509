import {
  PanelContent,
  Grid,
  Divider,
  FilterButton,
  Tooltip,
  useTranslations,
  usePopper,
  IFilterButtonProps,
} from '@uniqkey-frontend/shared-app';
import { useCallback, memo } from 'react';
import useOrganizationAuditLogsTable from '../../../../hooks/tables/useOrganizationAuditLogsTable';
import OrganizationAuditLogsTable from '../../../../components/tables/OrganizationAuditLogsTable';
import OrganizationAuditLogsListFilter, {
  IOrganizationAuditLogsListFilterSubmitResult,
} from './components/OrganizationAuditLogsListFilter';

interface IOrganizationAuditLogsTabProps {
  organizationId: string;
}

const OrganizationAuditLogsTab = (props: IOrganizationAuditLogsTabProps) => {
  const { organizationId } = props;
  const { t } = useTranslations();

  const {
    isOpen: isFilterOpen,
    anchorEl: filterAnchorEl,
    setPopperIsOpen: setIsFilterOpen,
  } = usePopper();
  const toggleIsFilterOpen = useCallback<NonNullable<IFilterButtonProps['onChange']>>(
    (event) => setIsFilterOpen(!isFilterOpen, event),
    [setIsFilterOpen, isFilterOpen],
  );
  const handleFilterClose = useCallback(() => setIsFilterOpen(false), [setIsFilterOpen]);

  const {
    filterValues,
    setFilterValues,
    isFilterActive,
    numberOfActiveFilters,
    resetActivePage,
    ...restTableProps
  } = useOrganizationAuditLogsTable({
    noDataMessageKey: 'table.noData.default',
    persistentFilters: { organizationId },
  });

  const handleFilterSubmit = useCallback((
    updatedValues: IOrganizationAuditLogsListFilterSubmitResult,
  ) => {
    setFilterValues(updatedValues);
    resetActivePage();
  }, [setFilterValues, resetActivePage]);

  return (
    <PanelContent p={0}>
      <Grid container justifyContent="space-between" alignItems="stretch" p={1}>
        <Grid item xs={4} container flexWrap="nowrap" spacing={1}>
          <Grid item>
            <Tooltip title={t('common.filter')}>
              <FilterButton
                isFilterActive={isFilterActive}
                numberOfActiveFilters={numberOfActiveFilters}
                selected={isFilterOpen}
                onChange={toggleIsFilterOpen}
              />
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <OrganizationAuditLogsTable {...restTableProps} />
      <OrganizationAuditLogsListFilter
        organizationId={organizationId}
        isOpen={isFilterOpen}
        anchorEl={filterAnchorEl}
        onSubmit={handleFilterSubmit}
        onClose={handleFilterClose}
        initialValues={filterValues}
      />
    </PanelContent>
  );
};

export default memo(OrganizationAuditLogsTab);
