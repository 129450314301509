import { useCallback, memo } from 'react';
import {
  ReactHookFormAutocomplete,
  Button,
  Grid,
  Popper,
  PopperActions,
  PopperContent,
  PopperTitle,
  Typography,
  useTranslations,
  usePubSub, useSnackbar,
} from '@uniqkey-frontend/shared-app';
import { useForm } from 'react-hook-form';
import {
  GetExtendedOrganizationsResponseModel,
  GetPartnersForLookupResponseModel,
} from '@uniqkey-backend-partner/api-client';
import PubSubEventEnum from '../../../../enums/PubSubEventEnum';
import {
  IGetExtendedOrganizationsParams,
} from '../../../../hooks/usePartnerOrganizationsAPI/interfaces';
import { COUNTRIES_LIST } from '../../../../helpers/countries';
import usePartnersAPI from '../../../../hooks/usePartnersAPI';
import { logException } from '../../../../services/sentryService';

export interface IOrganizationsExtendedListFilterSubmitResult {
  countryFilter: IGetExtendedOrganizationsParams['countryFilter'];
  partner: IPartnerOption | undefined;
}

interface IPartnerOption {
  value: GetPartnersForLookupResponseModel['partnerId'];
  label: GetPartnersForLookupResponseModel['partnerName'];
}

interface IOrganizationsExtendedListFilterProps {
  isOpen: boolean;
  anchorEl: HTMLElement | null;
  onSubmit: (result: IOrganizationsExtendedListFilterSubmitResult) => void;
  onClose: () => void;
  initialValues: IOrganizationsExtendedListFilterSubmitResult;
}

interface IFormValues {
  countryFilter: GetExtendedOrganizationsResponseModel['country'] | null;
  partner: IPartnerOption | null;
}

const POPPER_MIN_WIDTH = { minWidth: 500 };

const OrganizationsExtendedListFilter = (props: IOrganizationsExtendedListFilterProps) => {
  const {
    isOpen, anchorEl, onSubmit, onClose, initialValues,
  } = props;
  const { t } = useTranslations();
  const { showError } = useSnackbar();
  const { getPartnersForLookup } = usePartnersAPI();

  const {
    handleSubmit, control, reset, setValue, formState: { isDirty },
  } = useForm<IFormValues>({
    defaultValues: {
      countryFilter: initialValues.countryFilter ?? null,
      partner: initialValues.partner ?? null,
    },
  });

  const handleOnSubmit = useCallback((value: IFormValues): void => {
    const { countryFilter, partner } = value;
    onSubmit({
      countryFilter: countryFilter ?? undefined,
      partner: partner ?? undefined,
    });
    onClose();
    reset(value); // set default values to the selected ones to reset isDirty
  }, [reset, onSubmit, onClose]);

  const handleGetPartnersForLookupRequest = useCallback(async (searchQuery: string) => {
    try {
      const { data } = await getPartnersForLookup({ page: 1, pageLength: 100, searchQuery });
      return data;
    } catch (e) {
      showError({
        text: t('common.somethingWentWrong'),
      });
      logException(e, {
        message: 'OrganizationsExtendedListFilter/handleGetPartnersForLookupRequest exception',
      });
      return [];
    }
  }, [getPartnersForLookup, showError, t]);

  const handleGetPartnersForLookupParser = useCallback(
    (partnersToParse: GetPartnersForLookupResponseModel[]) => partnersToParse.map(
      (partner) => ({
        value: partner.partnerId,
        label: partner.partnerName,
      }),
    ),
    [],
  );

  const clearAll = useCallback(() => {
    setValue('countryFilter', null, { shouldDirty: true });
    setValue('partner', null, { shouldDirty: true });
  }, [setValue]);

  const handleOnFadeExited = useCallback(() => {
    reset(); // reset form values when Popper is closed and animation has finished
  }, [reset]);

  const handleReset = useCallback(() => {
    reset({
      countryFilter: null,
      partner: null,
    });
  }, [reset]);
  usePubSub(PubSubEventEnum.RESET_FILTER, handleReset);

  return (
    <Popper
      anchorEl={anchorEl}
      offsetX={-8}
      offsetY={16}
      placement="bottom-start"
      open={isOpen}
      sx={POPPER_MIN_WIDTH}
      onFadeExited={handleOnFadeExited}
    >
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <PopperTitle onClose={onClose}>
          {t('common.filterBy')}
        </PopperTitle>
        <PopperContent>
          <Grid container direction="column" rowSpacing={2}>
            <Grid item container alignItems="center">
              <Grid item xs={3}>
                <Typography>
                  {t('organizationsExtendedFilter.country.label')}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <ReactHookFormAutocomplete
                  name="countryFilter"
                  placeholder={t('organizationsExtendedFilter.country.placeholder')}
                  options={COUNTRIES_LIST}
                  control={control}
                />
              </Grid>
            </Grid>
            <Grid item container alignItems="center">
              <Grid item xs={3}>
                <Typography>
                  {t('organizationsExtendedFilter.partner.label')}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <ReactHookFormAutocomplete
                  t={t}
                  name="partner"
                  placeholder={t('organizationsExtendedFilter.partner.placeholder')}
                  dataSourceRequest={handleGetPartnersForLookupRequest}
                  dataSourceResponseParser={handleGetPartnersForLookupParser}
                  control={control}
                />
              </Grid>
            </Grid>
          </Grid>
        </PopperContent>
        <PopperActions>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button disabled={!isDirty} fullWidth type="submit">{t('common.apply')}</Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="outlined"
                onClick={clearAll}
              >
                {t('common.clearAll')}
              </Button>
            </Grid>
          </Grid>
        </PopperActions>
      </form>
    </Popper>
  );
};

export default memo(OrganizationsExtendedListFilter);
