import {
  encryptSymmetric,
  generateAsymmetricKeys,
  type Either,
} from '@uniqkey-frontend/shared-app';
import type { IPartnerUserKeys } from '../../../common/interfaces';
import {
  KeysManagerFailedToCreatePartnerUserKeysError,
  type TKeysManagerCreatePartnerUserKeysOperationErrors,
} from './errors';
import ApiClientsProvider from '../../../../../common/apiClientsProvider';
import { axiosInstance } from '../../../../../axios';

interface ICreatePartnerUserKeysOperationParams {
  derivedMasterPassword: string;
}

const createPartnerUserKeysOperation = async (
  params: ICreatePartnerUserKeysOperationParams,
): Promise<Either<IPartnerUserKeys, TKeysManagerCreatePartnerUserKeysOperationErrors>> => {
  try {
    const { derivedMasterPassword } = params;

    const {
      privateKey: generatedPrivateKey,
      publicKey: generatedPublicKey,
    } = await generateAsymmetricKeys();

    const encryptedPrivateKey = encryptSymmetric({
      string: generatedPrivateKey,
      key: derivedMasterPassword,
    });

    await ApiClientsProvider.getPartnerUserKeysAPIClient(axiosInstance)
      .apiV1PartnerUserKeysPut({
        privateKey: encryptedPrivateKey,
        publicKey: generatedPublicKey,
      });

    return {
      privateKey: generatedPrivateKey,
      publicKey: generatedPublicKey,
    };
  } catch (e) {
    return new KeysManagerFailedToCreatePartnerUserKeysError();
  }
};

export default createPartnerUserKeysOperation;
