import { memo } from 'react';
import { PanelContent } from '@uniqkey-frontend/shared-app';
import OrganizationAdminsTable from '../../../../components/tables/OrganizationAdminsTable';
import useOrganizationAdminsTable from '../../../../hooks/tables/useOrganizationAdminsTable';

interface IOrganizationAdminsTabProps {
  organizationId: string;
}

const OrganizationAdminsTab = (props: IOrganizationAdminsTabProps) => {
  const { organizationId } = props;

  const tableProps = useOrganizationAdminsTable({
    persistentFilters: { organizationId },
    noDataMessageKey: 'organizationPage.adminsTab.noData',
  });
  return (
    <PanelContent p={0}>
      <OrganizationAdminsTable
        {...tableProps}
      />
    </PanelContent>
  );
};

export default memo(OrganizationAdminsTab);
