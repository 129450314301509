import {
  resetReactQuery,
  TUseQueryOptions,
  TUseMutationOptions,
  TUseMutationContext,
} from '@uniqkey-frontend/shared-app';
import {
  GroupByIdResponse,
  NoContentResult,
  UpdateGroupRequest,
  GroupUsersResponse,
  RemoveUsersFromGroupRequest,
  RemoveUsersFromGroupResponse,
  RemoveOrganizationsFromGroupResponse,
  RemoveOrganizationsFromGroupRequest,
  GroupLatestOrganizationsResponse,
  GroupLatestOrganizationsForAdminResponse,
} from '@uniqkey-backend-partner/api-client';
import {
  useQuery,
  useQueryClient,
  useMutation,
  type QueryKey,
  type QueryClient,
} from 'react-query';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';
import useGroupsAPI from '../../useGroupsAPI';
import {
  IGetGroupUsersParams,
  IGetGroupLatestOrganizationsParams,
  IGetGroupLatestOrganizationsForAdminParams,
} from '../../useGroupsAPI/interfaces';
import {
  REACT_QUERY_GROUP_ORGANIZATIONS_FOR_ADMIN_KEY,
} from '../../tables/useGroupOrganizationsForAdminTable';
import { REACT_QUERY_GROUP_ORGANIZATIONS_KEY } from '../../tables/useGroupOrganizationsTable';
import { REACT_QUERY_GROUPS_KEY } from '../../tables/useGroupsTable';
import { REACT_QUERY_GROUP_USERS_KEY } from '../../tables/useGroupUsersTable';

export const REACT_QUERY_GROUP_KEY = [ReactQueryKeyEnum.Group];

export const REACT_QUERY_GROUP_LATEST_ORGANIZATIONS_KEY = [
  ReactQueryKeyEnum.GroupLatestOrganizations,
];

export const REACT_QUERY_GROUP_LATEST_ORGANIZATIONS_FOR_ADMIN_KEY = [
  ReactQueryKeyEnum.GroupLatestOrganizationsForAdmin,
];

interface IUseGetGroupByIdParams {
  groupId: string;
}

export const useGetGroupById = (
  params: IUseGetGroupByIdParams,
  options: TUseQueryOptions<GroupByIdResponse> = {},
) => {
  const { groupId } = params;
  const { getGroupById } = useGroupsAPI();
  return useQuery<GroupByIdResponse>(
    (REACT_QUERY_GROUP_KEY as QueryKey[]).concat([groupId]),
    ({ signal }) => getGroupById(groupId, { signal }),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};

interface IUseUpdateGroupParams {
  groupId: string;
  useOptimisticUpdates?: boolean;
}
export const useUpdateGroup = (
  params: IUseUpdateGroupParams,
  options: TUseMutationOptions<
    NoContentResult,
    unknown,
    UpdateGroupRequest,
    TUseMutationContext<GroupByIdResponse>
  > = {},
) => {
  const { groupId, useOptimisticUpdates = false } = params;
  const queryClient = useQueryClient();
  const { updateGroup } = useGroupsAPI();
  const mutationKey = (REACT_QUERY_GROUP_KEY as QueryKey[]).concat([groupId]);
  return useMutation(
    mutationKey,
    (group) => updateGroup({ ...group, groupId }),
    {
      onMutate: async (newGroup) => {
        if (!useOptimisticUpdates) {
          return null;
        }
        await queryClient.cancelQueries(mutationKey);
        const previousValue = queryClient.getQueryData<
          GroupByIdResponse
        >(mutationKey);
        queryClient.setQueryData<GroupByIdResponse>(
          mutationKey,
          (oldGroup) => ({
            ...oldGroup,
            ...newGroup as GroupByIdResponse,
          }),
        );
        return { previousValue: previousValue as GroupByIdResponse };
      },
      onError: (err, group, context) => {
        if (context?.previousValue) {
          queryClient.setQueryData<GroupByIdResponse>(
            mutationKey,
            context.previousValue,
          );
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(mutationKey);
        resetReactQuery(queryClient, REACT_QUERY_GROUPS_KEY);
      },
      ...options,
    },
  );
};

export const useGetGroupUsers = (
  params: IGetGroupUsersParams,
  options: TUseQueryOptions<GroupUsersResponse> = {},
) => {
  const { getGroupUsers } = useGroupsAPI();
  return useQuery<GroupUsersResponse>(
    (REACT_QUERY_GROUP_USERS_KEY as QueryKey[]).concat([params]),
    ({ signal }) => getGroupUsers(params, { signal }),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};

export const resetGroupUsersRelatedQueries = (
  queryClient: QueryClient,
  groupId: string,
) => {
  resetReactQuery(queryClient, (REACT_QUERY_GROUP_USERS_KEY as QueryKey[]).concat([{ groupId }]));
  resetReactQuery(queryClient, REACT_QUERY_GROUPS_KEY);
};

export const useRemoveUsersFromGroup = (
  options: TUseMutationOptions<
    RemoveUsersFromGroupResponse,
    unknown,
    RemoveUsersFromGroupRequest,
    void
  > = {},
) => {
  const queryClient = useQueryClient();
  const { removeUsersFromGroup } = useGroupsAPI();
  return useMutation((
    removeUsersFromGroupRequest,
  ) => removeUsersFromGroup(removeUsersFromGroupRequest), {
    onSettled: (data, error, variables) => {
      const { groupId } = variables;
      resetGroupUsersRelatedQueries(queryClient, groupId);
    },
    ...options,
  });
};

export const resetGroupOrganizationsRelatedQueries = (
  queryClient: QueryClient,
  groupId: string,
  isForAdmin: boolean,
) => {
  const latestGroupOrganizationsQueryKey = isForAdmin
    ? (REACT_QUERY_GROUP_LATEST_ORGANIZATIONS_FOR_ADMIN_KEY as QueryKey[]).concat([{ groupId }])
    : (REACT_QUERY_GROUP_LATEST_ORGANIZATIONS_KEY as QueryKey[]).concat([{ groupId }]);
  const groupOrganizationsQueryKey = isForAdmin
    ? (REACT_QUERY_GROUP_ORGANIZATIONS_FOR_ADMIN_KEY as QueryKey[]).concat([{ groupId }])
    : (REACT_QUERY_GROUP_ORGANIZATIONS_KEY as QueryKey[]).concat([{ groupId }]);
  resetReactQuery(queryClient, latestGroupOrganizationsQueryKey);
  resetReactQuery(queryClient, groupOrganizationsQueryKey);
  resetReactQuery(queryClient, REACT_QUERY_GROUPS_KEY);
};

interface IUseRemoveOrganizationsFromGroupParams {
  isForAdmin: boolean;
}
export const useRemoveOrganizationsFromGroup = (
  params: IUseRemoveOrganizationsFromGroupParams,
  options: TUseMutationOptions<
    RemoveOrganizationsFromGroupResponse,
    unknown,
    RemoveOrganizationsFromGroupRequest,
    void
  > = {},
) => {
  const { isForAdmin } = params;
  const queryClient = useQueryClient();
  const { removeOrganizationsFromGroup } = useGroupsAPI();
  return useMutation((
    removeOrganizationsFromGroupRequest,
  ) => removeOrganizationsFromGroup(removeOrganizationsFromGroupRequest), {
    onSettled: (data, error, variables) => {
      const { groupId } = variables;
      resetGroupOrganizationsRelatedQueries(queryClient, groupId, isForAdmin);
    },
    ...options,
  });
};

export const useGetGroupLatestOrganizations = (
  params: IGetGroupLatestOrganizationsParams,
  options: TUseQueryOptions<GroupLatestOrganizationsResponse> = {},
) => {
  const { getGroupLatestOrganizations } = useGroupsAPI();
  return useQuery<GroupLatestOrganizationsResponse>(
    (REACT_QUERY_GROUP_LATEST_ORGANIZATIONS_KEY as QueryKey[]).concat([params]),
    ({ signal }) => getGroupLatestOrganizations(params, { signal }),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};

export const useGetGroupLatestOrganizationsForAdmin = (
  params: IGetGroupLatestOrganizationsForAdminParams,
  options: TUseQueryOptions<GroupLatestOrganizationsForAdminResponse> = {},
) => {
  const { getGroupLatestOrganizationsForAdmin } = useGroupsAPI();
  return useQuery<GroupLatestOrganizationsForAdminResponse>(
    (REACT_QUERY_GROUP_LATEST_ORGANIZATIONS_FOR_ADMIN_KEY as QueryKey[]).concat([params]),
    ({ signal }) => getGroupLatestOrganizationsForAdmin(params, { signal }),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};
