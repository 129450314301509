import { useCallback } from 'react';
import { type AxiosRequestConfig } from 'axios';
import {
  GetEmployeeAccountsResponse,
  GetEmployeeAccountByIdResponse,
  GetVaultPasswordsResponse,
  GetEmployeeAccountAuditLogsResponse,
  GetForLookupResponse,
} from '@uniqkey-backend-partner/api-client';
import { useApiClients } from '../../contexts/APIClientsContext';
import { dataExtractor, auditLogPayloadExtractor } from '../../helpers/apiClients';
import {
  TGetOrganizationEmployeeAccountsMethod,
  TGetOrganizationEmployeeAccountVaultPasswordsMethod,
  TGetOrganizationEmployeeAccountAuditLogsMethod,
  TGetEmployeeAccountsForLookupMethod,
} from './interfaces';

const useEmployeeAccountsAPI = () => {
  const { employeeAccountsAPIClient } = useApiClients();

  const getOrganizationEmployeeAccounts = useCallback<TGetOrganizationEmployeeAccountsMethod>(
    (params, axiosRequestConfig) => {
      const {
        organizationId,
        page,
        pageLength,
        typeFilter = undefined,
        statusFilter = undefined,
        searchQuery = undefined,
        orderPropertyName = undefined,
        isDescending = undefined,
      } = params;
      return employeeAccountsAPIClient.apiV1EmployeeAccountsGet(
        organizationId,
        page,
        pageLength,
        typeFilter,
        statusFilter,
        searchQuery,
        orderPropertyName,
        isDescending,
        axiosRequestConfig,
      ).then(dataExtractor<GetEmployeeAccountsResponse>());
    },
    [employeeAccountsAPIClient],
  );

  const getOrganizationEmployeeAccountById = useCallback(
    (
      employeeAccountId: string,
      options?: AxiosRequestConfig,
    ): Promise<GetEmployeeAccountByIdResponse> => employeeAccountsAPIClient
      .apiV1EmployeeAccountsEmployeeAccountIdGet(employeeAccountId, options)
      .then(dataExtractor<GetEmployeeAccountByIdResponse>()),
    [employeeAccountsAPIClient],
  );

  const getOrganizationEmployeeAccountVaultPasswords = useCallback<
    TGetOrganizationEmployeeAccountVaultPasswordsMethod
  >(
    (params, axiosRequestConfig) => {
      const {
        employeeAccountId,
        page,
        pageLength,
        searchQuery = undefined,
        ownership = undefined,
        applicationName = undefined,
        minStrength = undefined,
        maxStrength = undefined,
        orderPropertyName = undefined,
        isDescending = undefined,
      } = params;
      return employeeAccountsAPIClient.apiV1EmployeeAccountsGetVaultPasswordsGet(
        employeeAccountId,
        page,
        pageLength,
        searchQuery,
        ownership,
        applicationName,
        minStrength,
        maxStrength,
        orderPropertyName,
        isDescending,
        axiosRequestConfig,
      ).then(dataExtractor<GetVaultPasswordsResponse>());
    },
    [employeeAccountsAPIClient],
  );

  const getOrganizationEmployeeAccountAuditLogs = useCallback<
    TGetOrganizationEmployeeAccountAuditLogsMethod
  >(
    (params, axiosRequestConfig) => {
      const {
        employeeAccountId,
        page,
        pageLength,
        beforeDate = undefined,
        afterDate = undefined,
      } = params;
      return employeeAccountsAPIClient
        .apiV1EmployeeAccountsGetEmployeeAccountAuditLogsGet(
          employeeAccountId,
          page,
          pageLength,
          beforeDate,
          afterDate,
          axiosRequestConfig,
        )
        .then(dataExtractor<GetEmployeeAccountAuditLogsResponse>())
        .then((response) => ({
          ...response,
          data: response.data.map(((el) => auditLogPayloadExtractor(el))),
        }));
    },
    [employeeAccountsAPIClient],
  );

  const getEmployeeAccountsForLookup = useCallback<TGetEmployeeAccountsForLookupMethod>(
    (params, axiosRequestConfig) => {
      const {
        page,
        pageLength,
        organizationId = undefined,
        searchQuery = undefined,
        showSupporters = undefined,
      } = params;
      return employeeAccountsAPIClient
        .apiV1EmployeeAccountsGetForLookupGet(
          page,
          pageLength,
          organizationId,
          searchQuery,
          showSupporters,
          axiosRequestConfig,
        )
        .then(dataExtractor<GetForLookupResponse>());
    },
    [employeeAccountsAPIClient],
  );

  return ({
    getOrganizationEmployeeAccounts,
    getOrganizationEmployeeAccountById,
    getOrganizationEmployeeAccountVaultPasswords,
    getOrganizationEmployeeAccountAuditLogs,
    getEmployeeAccountsForLookup,
  });
};

export default useEmployeeAccountsAPI;
