import {
  useCallback,
} from 'react';
import { upperFirst } from 'lodash';
import {
  useSnackbar,
  useTable,
  useQueryTableFetch,
  useTranslations,
  useTableLocalization,
  useTablePreserverActivePageValidator,
  useTablePreserverDecoder,
  useTablePreserverEncoder,
  usePubSub,
  buildTablePreserverDecoderConfigItem,
  IUseTableReturn,
  IUseTableResetTableMethodsReturn,
  IUseQueryTableFetchReturn,
  IUseTableLocalizationReturn,
  TUseTablePreserverDecoderConfigs,
  TablePreserverDecoderConfigItemTypeEnum,
  TSetSearchParams,
} from '@uniqkey-frontend/shared-app';
import { OrganizationHistoricLicenseOrderProperty } from '@uniqkey-backend-partner/api-client';
import { useSearchParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import PubSubEventEnum from '../../../enums/PubSubEventEnum';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';
import usePartnerOrganizationsAPI from '../../usePartnerOrganizationsAPI';
import {
  IGetOrganizationLicensesHistoryParams,
  TGetOrganizationLicensesHistoryMethod,
} from '../../usePartnerOrganizationsAPI/interfaces';
import {
  IOrganizationLicensesHistoryTableRow,
} from '../../../components/tables/OrganizationLicensesHistoryTable';

export const REACT_QUERY_ORGANIZATION_LICENSES_HISTORY_KEY = [
  ReactQueryKeyEnum.OrganizationLicensesHistory,
];

interface IPersistentFilters extends Pick<
  IGetOrganizationLicensesHistoryParams, 'organizationId'
> {}

export interface IUseOrganizationLicensesHistoryTableParams {
  persistentFilters: IPersistentFilters;
  noDataMessageKey: string;
}

export interface IUseOrganizationLicensesHistoryTableReturn extends
  Omit<IUseTableReturn<IOrganizationLicensesHistoryTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Pick<IUseTableResetTableMethodsReturn, 'resetActivePage'>,
  Omit<
    IUseQueryTableFetchReturn<TGetOrganizationLicensesHistoryMethod>,
    'data' | 'isFetchedAfterMount'
  >
{
  organizationLicensesHistory: IUseQueryTableFetchReturn<
    TGetOrganizationLicensesHistoryMethod
  >['data'];
  selectedOrganizationLicensesHistory: IUseTableReturn<
    IOrganizationLicensesHistoryTableRow
  >['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
}

interface ITablePreservationConfigs extends
  Omit<IGetOrganizationLicensesHistoryParams,
    'page' | 'pageLength' | 'organizationId' | 'orderPropertyName' | 'isDescending'
  >,
  Pick<IUseTableReturn<
    IOrganizationLicensesHistoryTableRow
  >, 'columnOrderBy' | 'columnOrderDirection'>
{
  activePage: IGetOrganizationLicensesHistoryParams['page'];
  perPage: IGetOrganizationLicensesHistoryParams['pageLength'];
}

const PRESERVATION_CONFIGS: TUseTablePreserverDecoderConfigs<ITablePreservationConfigs> = {
  activePage: true,
  perPage: true,
  columnOrderBy: buildTablePreserverDecoderConfigItem(
    TablePreserverDecoderConfigItemTypeEnum.ENUM,
    { enumToCheck: OrganizationHistoricLicenseOrderProperty },
  ),
  columnOrderDirection: true,
};

const useOrganizationLicensesHistoryTable = (
  params: IUseOrganizationLicensesHistoryTableParams,
): IUseOrganizationLicensesHistoryTableReturn => {
  const {
    persistentFilters,
    noDataMessageKey,
  } = params;

  const queryClient = useQueryClient();
  const { t } = useTranslations();
  const { showError } = useSnackbar();
  const { getOrganizationLicensesHistory } = usePartnerOrganizationsAPI();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOnRequestError = useCallback(() => {
    showError({ text: t('common.somethingWentWrong') });
  }, [showError, t]);

  const {
    activePage: initialActivePage,
    perPage: initialPerPage,
    columnOrderBy: initialColumnOrderBy,
    columnOrderDirection: initialColumnOrderDirection,
  } = useTablePreserverDecoder<ITablePreservationConfigs>(searchParams, PRESERVATION_CONFIGS);

  const {
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedRows: selectedOrganizationLicensesHistory,
    resetTableMethods,
    ...restTableProps
  } = useTable<IOrganizationLicensesHistoryTableRow>({
    initialActivePage,
    initialPerPage,
    initialColumnOrderBy,
    initialColumnOrderDirection,
  });

  const {
    resetTable,
    resetActivePage,
  } = resetTableMethods;

  const handleTableReset = useCallback(() => {
    resetTable();
  }, [resetTable]);
  usePubSub(PubSubEventEnum.RESET_TABLE, handleTableReset);

  const {
    data: organizationLicensesHistory, isLoading, isFetchedAfterMount, total, resetQuery,
  } = useQueryTableFetch({
    queryKey: REACT_QUERY_ORGANIZATION_LICENSES_HISTORY_KEY,
    queryClient,
    request: getOrganizationLicensesHistory,
    params: {
      page: activePage,
      pageLength: perPage,
      orderPropertyName: OrganizationHistoricLicenseOrderProperty[
        upperFirst(columnOrderBy) as keyof typeof OrganizationHistoricLicenseOrderProperty
      ],
      isDescending: columnOrderDirection ? columnOrderDirection === 'desc' : undefined,
      ...persistentFilters,
    },
    onRequestError: handleOnRequestError,
    autoRefetch: true,
  });

  const { localization } = useTableLocalization({
    noDataMessageKey,
    isLoading,
  });

  useTablePreserverActivePageValidator({
    activePage, resetActivePage, perPage, total, isFetchedAfterMount,
  });

  useTablePreserverEncoder<ITablePreservationConfigs>(setSearchParams as TSetSearchParams, {
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
  });

  return {
    organizationLicensesHistory,
    isLoading,
    total,
    resetQuery,
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedOrganizationLicensesHistory,
    localization,
    resetActivePage,
    ...restTableProps,
  };
};

export default useOrganizationLicensesHistoryTable;
