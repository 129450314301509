import { type ReactNode, useEffect } from 'react';
import { useUser } from '../../contexts/UserContext';
import {
  initWebSockets,
  destroyWebSocketConnection,
  DEFAULT_APP_CONNECTION_ID,
} from '../../services/webSocketsManager';

interface IInitializationContainerProps {
  children: ReactNode;
}
const InitializationContainer = (props: IInitializationContainerProps) => {
  const { children } = props;

  const { currentUser, loginVerified } = useUser();
  const { id } = currentUser ?? {};

  useEffect(() => {
    if (loginVerified && id) {
      initWebSockets(id);
    } else {
      destroyWebSocketConnection(DEFAULT_APP_CONNECTION_ID);
    }
  }, [loginVerified, id]);

  return children;
};

export default InitializationContainer;
