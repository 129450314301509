import { memo, useCallback, useMemo } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  getCitiesByCountryName,
  Grid,
  maxLengthValidator,
  ReactHookFormAutocomplete,
  useTranslations,
  validateAddress,
  validateVAT,
  validateZIPCode,
  FormHiddenInput,
  emptyStringValidator,
  ReactHookFormTextField,
} from '@uniqkey-frontend/shared-app';
import { useForm } from 'react-hook-form';
import {
  PartnerType,
  GetPartnerByIdResponse,
} from '@uniqkey-backend-partner/api-client';
import PartnerTypeSelect from '../../../../../PartnersPage/components/PartnerTypeSelect';
import { TPartnerTypeOption } from '../../../../../../helpers/partnerType/interfaces';
import { COUNTRIES_LIST } from '../../../../../../helpers/countries';

interface IEditPartnerDetailsModalFormValue extends Omit<
  GetPartnerByIdResponse, 'country' | 'city' | 'type'
> {
  country: GetPartnerByIdResponse['country'] | null;
  city: GetPartnerByIdResponse['city'] | null;
  type: TPartnerTypeOption;
}

export interface IEditPartnerDetailsModalReturnValue extends IEditPartnerDetailsModalFormValue {
  country: NonNullable<IEditPartnerDetailsModalFormValue['country']>;
  city: NonNullable<IEditPartnerDetailsModalFormValue['city']>;
  type: PartnerType;
}

interface IEditPartnerDetailsModalProps {
  isOpen: boolean;
  isLoading: boolean;
  onSubmit: (params: IEditPartnerDetailsModalReturnValue) => Promise<void> | void;
  onClose: () => void;
  partner: GetPartnerByIdResponse;
  type: PartnerType;
}

const EditPartnerDetailsModal = (props: IEditPartnerDetailsModalProps) => {
  const {
    isOpen, isLoading, onSubmit, onClose, partner, type: partnerType,
  } = props;

  const { t } = useTranslations();

  const {
    name, type, country, city, address, zip, vatNumber,
  } = partner;

  const {
    handleSubmit,
    formState: { isDirty, errors },
    control,
    setValue,
    watch,
  } = useForm<IEditPartnerDetailsModalFormValue>({
    defaultValues: {
      name,
      type,
      country,
      city,
      address,
      zip,
      vatNumber,
    },
    mode: 'all',
  });

  const [
    selectedCountry,
  ] = watch([
    'country',
  ]);

  const citiesOptions = useMemo(() => (selectedCountry
    ? getCitiesByCountryName(selectedCountry)
    : []
  ), [selectedCountry]);

  const handleCountryChange = useCallback(() => {
    setValue('city', null);
  }, [setValue]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      clickOutsideToClose={!isLoading}
    >
      <form
        // @ts-ignore
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
        noValidate
      >
        <DialogTitle isLoading={isLoading} onClose={onClose}>
          {t('editPartnerDetailsModal.title')}
        </DialogTitle>
        <DialogContent>
          <Grid container direction="row" rowSpacing={4} columnSpacing={2}>
            <Grid item xs={6}>
              <FormHiddenInput />
              <ReactHookFormTextField
                name="name"
                control={control}
                fullWidth
                autoFocus
                autoComplete="none"
                label={`${t('editPartnerDetailsModal.name.label')}*`}
                placeholder={t('editPartnerDetailsModal.name.placeholder')}
                error={!!errors.name}
                helperText={errors.name?.message}
                rules={{
                  required: t('validation.required'),
                  validate: (value) => (
                    emptyStringValidator(value as string) ? t('validation.required') : true
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <PartnerTypeSelect
                control={control}
                name="type"
                type={partnerType}
                label={`${t('editPartnerDetailsModal.type.label')}*`}
              />
            </Grid>
            <Grid item xs={6}>
              <ReactHookFormAutocomplete
                t={t}
                name="country"
                onChange={handleCountryChange}
                options={COUNTRIES_LIST}
                control={control}
                label={`${t('editPartnerDetailsModal.country.label')}*`}
                placeholder={t('editPartnerDetailsModal.country.placeholder')}
                error={!!errors.country}
                helperText={errors.country?.message}
                disableAutoFill
                rules={{ required: t('validation.required') }}
              />
            </Grid>
            <Grid item xs={6}>
              <ReactHookFormAutocomplete
                t={t}
                name="city"
                freeSolo
                options={citiesOptions}
                control={control}
                label={`${t('editPartnerDetailsModal.city.label')}*`}
                placeholder={t('editPartnerDetailsModal.city.placeholder')}
                error={!!errors.city}
                helperText={errors.city?.message}
                disableAutoFill
                disabled={!selectedCountry}
                rules={{
                  validate: {
                    isValid: (v) => validateAddress(v as string) || t('validation.invalidCity'),
                    required: (v) => (selectedCountry && !v ? t('validation.required') : true),
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <ReactHookFormTextField
                name="address"
                control={control}
                fullWidth
                autoComplete="none"
                label={`${t('editPartnerDetailsModal.address.label')}*`}
                placeholder={t('editPartnerDetailsModal.address.placeholder')}
                error={!!errors.address}
                helperText={errors.address?.message}
                rules={{
                  required: t('validation.required'),
                  validate: {
                    isValid: (v) => validateAddress(v as string)
                        || t('validation.invalidAddress'),
                    required: (value) => (emptyStringValidator(value as string)
                      ? t('validation.required')
                      : true),
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <ReactHookFormTextField
                name="zip"
                control={control}
                fullWidth
                autoComplete="none"
                label={`${t('editPartnerDetailsModal.zipCode.label')}*`}
                placeholder={t('editPartnerDetailsModal.zipCode.placeholder')}
                error={!!errors.zip}
                helperText={errors.zip?.message}
                rules={{
                  required: t('validation.required'),
                  validate: {
                    isValid: (v) => validateZIPCode(v as string)
                        || t('validation.invalidZIPCode'),
                    maxLength: (v) => maxLengthValidator(12, v as string)
                        || t('validation.maxLength', { length: 12 }),
                    required: (value) => (emptyStringValidator(value as string)
                      ? t('validation.required')
                      : true),
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <ReactHookFormTextField
                name="vatNumber"
                control={control}
                fullWidth
                autoComplete="none"
                label={`${t('editPartnerDetailsModal.vat.label')}*`}
                placeholder={t('editPartnerDetailsModal.vat.placeholder')}
                error={!!errors.vatNumber}
                helperText={errors.vatNumber?.message}
                rules={{
                  required: t('validation.required'),
                  validate: {
                    isValid: (v) => validateVAT(v as string)
                        || t('validation.invalidVAT'),
                    maxLength: (v) => maxLengthValidator(20, v as string)
                        || t('validation.maxLength', { length: 20 }),
                  },
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container columnSpacing={2} justifyContent="flex-end">
            <Grid item xs={2.5}>
              <Button fullWidth isLoading={isLoading} disabled={!isDirty} type="submit">
                {t('common.save')}
              </Button>
            </Grid>
            <Grid item xs={2.5}>
              <Button fullWidth variant="outlined" disabled={isLoading} onClick={onClose}>
                {t('common.cancel')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default memo(EditPartnerDetailsModal);
