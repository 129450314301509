import {
  formatDate,
  DateTimeFormatEnum,
  TFunction,
  formatSupporterEmail,
} from '@uniqkey-frontend/shared-app';
import { AuditLogSourceType } from '@uniqkey-backend-partner/api-client';
import { IAuditLog } from '../../hooks/useAuditLogsAPI/interfaces';
import { IAuditLogsTableRow } from '../../components/tables/AuditLogsTable';
import AuditLogEventsNames from './constants/AuditLogEventsNames';
import AuditLogQueueEventsNames from './constants/AuditLogQueueEventsNames';
import VaultTypeNameEnum from '../../enums/VaultTypeNameEnum';

const getActionDoneBy = (t: TFunction, auditLog: IAuditLog): string | null => {
  if (auditLog.sourceType === AuditLogSourceType.User) {
    return auditLog.actorEmail;
  }
  if (auditLog.sourceType === AuditLogSourceType.Supporter) {
    return formatSupporterEmail(auditLog.actorEmail, 'supporter.emailSuffix');
  }
  if (auditLog.sourceType === AuditLogSourceType.Scim) {
    return t('auditLogsPage.auditLogsTab.actionDoneByTypes.scim');
  }
  if (auditLog.sourceType === AuditLogSourceType.System) {
    return t('auditLogsPage.auditLogsTab.actionDoneByTypes.system');
  }
  return null;
};

const getDescription = (t: TFunction, auditLog: IAuditLog): string => {
  const { actionTypeId } = auditLog;
  const auditLogEventName = AuditLogEventsNames[actionTypeId as keyof typeof AuditLogEventsNames];
  if (!auditLogEventName) {
    return auditLog.actionTypeId;
  }
  const { QueueEvent, VaultType } = auditLog.parsedPayload;
  const queueEventName = AuditLogQueueEventsNames[
    QueueEvent as keyof typeof AuditLogQueueEventsNames
  ];
  const vaultTypeName = VaultTypeNameEnum[
      VaultType as keyof typeof VaultTypeNameEnum
  ];
  return t(`auditLogs.messages.${auditLogEventName}`, {
    queueEventName: t(`auditLogs.queueEvents.${queueEventName}`, {
      payload: auditLog.parsedPayload,
      enrichedPayload: auditLog.parsedEnrichedPayload,
    }),
    payload: auditLog.parsedPayload,
    enrichedPayload: auditLog.parsedEnrichedPayload,
    vaultTypeName: t(`auditLogs.vaultTypes.${vaultTypeName}`),
  });

  // TODO: Check this later and remove or uncomment:
  //  const description = t(`auditLogs.messages.${auditLogEventName}`, {
  //   queueEventName: t(`auditLogs.queueEvents.${queueEventName}`, {
  //     payload: auditLog.parsedPayload,
  //     enrichedPayload: auditLog.parsedEnrichedPayload,
  //   }),
  //   payload: auditLog.parsedPayload,
  //   enrichedPayload: auditLog.parsedEnrichedPayload,
  //   vaultTypeName: t(`auditLogs.vaultTypes.${vaultTypeName}`),
  // });
  // if (process.env.NODE_ENV !== 'production' && /\{\{.+}}/.test(description)) {
  //   /*
  //     check if the final description doesn't contain {{}}
  //     for example, if something hasn't been translated properly, {{payload....}} will appear at
  //     that place
  //   */
  //   return auditLog.actionTypeId;
  // }
  // return description;
};

// eslint-disable-next-line import/prefer-default-export
export const prepareAuditLogRenderData = (
  t: TFunction,
  auditLog: IAuditLog,
): IAuditLogsTableRow => {
  const processedDate = formatDate({ date: auditLog.createdAt }, DateTimeFormatEnum.FULL);
  const processedActionDoneBy = getActionDoneBy(t, auditLog);
  const processedDescription = getDescription(t, auditLog);
  return {
    ...auditLog,
    processedDate,
    processedActionDoneBy,
    processedDescription,
  };
};
