import { memo } from 'react';
import {
  PanelContent,
} from '@uniqkey-frontend/shared-app';
import usePartnerLicensesHistoryTable
  from '../../../../hooks/tables/usePartnerLicensesHistoryTable';
import PartnerLicensesHistoryTable from '../../../../components/tables/PartnerLicensesHistoryTable';

interface IPartnerLicensesHistoryTabProps {
  partnerId: string;
}

const PartnerLicensesHistoryTab = (props: IPartnerLicensesHistoryTabProps) => {
  const { partnerId } = props;

  const tableProps = usePartnerLicensesHistoryTable({
    persistentFilters: { partnerId },
    noDataMessageKey: 'partnerPage.licensesHistoryTab.noData',
  });

  return (
    <PanelContent p={0}>
      <PartnerLicensesHistoryTable
        {...tableProps}
      />
    </PanelContent>
  );
};

export default memo(PartnerLicensesHistoryTab);
