import { Outlet, useParams } from 'react-router-dom';
import { Grid } from '@uniqkey-frontend/shared-app';
import GroupOrganizationEmployeePageBreadcrumbs from
  './components/GroupOrganizationEmployeePageBreadcrumbs';

const GroupOrganizationEmployeePage = () => {
  const params = useParams();
  const { groupId, organizationId, employeeAccountId } = params;

  return (
    <Grid container flexDirection="column" className="height-100-percent">
      <Grid item mb={1}>
        <GroupOrganizationEmployeePageBreadcrumbs
          employeeAccountId={employeeAccountId!}
          organizationId={organizationId!}
          groupId={groupId!}
        />
      </Grid>
      <Grid item xs>
        <Outlet context={{ employeeAccountId, organizationId, groupId }} />
      </Grid>
    </Grid>
  );
};

export default GroupOrganizationEmployeePage;
