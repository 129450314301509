import {
  Dispatch, SetStateAction, useCallback, useState,
} from 'react';
import { upperFirst } from 'lodash';
import {
  useSnackbar,
  useTable,
  useQueryTableFetch,
  useTranslations,
  useTableLocalization,
  useFilterButton,
  useTablePreserverActivePageValidator,
  useTablePreserverDecoder,
  useTablePreserverEncoder,
  usePubSub,
  buildTablePreserverDecoderConfigItem,
  TablePreserverCommonInitialTableValues,
  IUseTableReturn,
  IUseTableResetTableMethodsReturn,
  IUseQueryTableFetchReturn,
  IUseTableLocalizationReturn,
  IUseFilterButtonReturn,
  TUseTablePreserverDecoderConfigs,
  TablePreserverDecoderConfigItemTypeEnum,
  TSetSearchParams,
  TSecurityScoreName,
  getSecurityScoreStrength,
} from '@uniqkey-frontend/shared-app';
import { VaultPasswordsOrderProperty } from '@uniqkey-backend-partner/api-client';
import { useSearchParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import {
  IGetOrganizationEmployeeAccountVaultPasswordsParams,
  TGetOrganizationEmployeeAccountVaultPasswordsMethod,
} from '../../useEmployeeAccountsAPI/interfaces';
import {
  IOrganizationEmployeeLoginsTableRow,
} from '../../../components/tables/OrganizationEmployeeLoginsTable';
import useEmployeeAccountsAPI from '../../useEmployeeAccountsAPI';
import tablePreservationConfigs from '../../../helpers/tablePreservation/configs';
import PubSubEventEnum from '../../../enums/PubSubEventEnum';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';
// eslint-disable-next-line max-len
import { IOrganizationEmployeeLoginsTabFilterSubmitResult } from '../../../pages/OrganizationEmployeePage/components/OrganizationEmployeeLoginsTab/components/OrganizationEmployeeLoginsTabFilter';

export const REACT_QUERY_ORGANIZATION_EMPLOYEE_LOGINS_KEY = [
  ReactQueryKeyEnum.OrganizationEmployeeLogins,
];

interface IPersistentFilters extends Pick<
  IGetOrganizationEmployeeAccountVaultPasswordsParams, 'employeeAccountId'
> {}

export interface IUseOrganizationEmployeeLoginsTableParams {
  persistentFilters: IPersistentFilters;
  noDataMessageKey: string;
}

export interface IUseOrganizationEmployeeLoginsTableReturn extends
  Omit<IUseTableReturn<IOrganizationEmployeeLoginsTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Pick<IUseTableResetTableMethodsReturn, 'resetActivePage'>,
  Omit<
    IUseQueryTableFetchReturn<TGetOrganizationEmployeeAccountVaultPasswordsMethod>,
    'data' | 'isFetchedAfterMount'
  >,
  IUseFilterButtonReturn
{
  logins: IUseQueryTableFetchReturn<TGetOrganizationEmployeeAccountVaultPasswordsMethod>['data'];
  selectedLogins: IUseTableReturn<IOrganizationEmployeeLoginsTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
  searchQuery: IGetOrganizationEmployeeAccountVaultPasswordsParams['searchQuery'];
  setSearchQuery: Dispatch<
    SetStateAction<IGetOrganizationEmployeeAccountVaultPasswordsParams['searchQuery']>
  >;
  filterValues: IOrganizationEmployeeLoginsTabFilterSubmitResult;
  setFilterValues: Dispatch<SetStateAction<IOrganizationEmployeeLoginsTabFilterSubmitResult>>;
}

interface ITablePreservationConfigs extends
  Omit<IGetOrganizationEmployeeAccountVaultPasswordsParams,
    'employeeAccountId' | 'page' | 'pageLength' |
    'applicationName' | 'minStrength' | 'maxStrength' |
    'orderPropertyName' | 'isDescending'
  >,
  Pick<
    IUseTableReturn<IOrganizationEmployeeLoginsTableRow
    >, 'columnOrderBy' | 'columnOrderDirection'>
{
  activePage: IGetOrganizationEmployeeAccountVaultPasswordsParams['page'];
  perPage: IGetOrganizationEmployeeAccountVaultPasswordsParams['pageLength'];
  securityScoreName: TSecurityScoreName;
}

const PRESERVATION_CONFIGS: TUseTablePreserverDecoderConfigs<ITablePreservationConfigs> = {
  activePage: true,
  perPage: true,
  searchQuery: true,
  columnOrderBy: buildTablePreserverDecoderConfigItem(
    TablePreserverDecoderConfigItemTypeEnum.ENUM,
    { enumToCheck: VaultPasswordsOrderProperty },
  ),
  columnOrderDirection: true,
  securityScoreName: true,
  ownership: tablePreservationConfigs.ownership,
};

const useOrganizationEmployeeLoginsTable = (
  params: IUseOrganizationEmployeeLoginsTableParams,
): IUseOrganizationEmployeeLoginsTableReturn => {
  const {
    persistentFilters,
    noDataMessageKey,
  } = params;

  const queryClient = useQueryClient();
  const { t } = useTranslations();
  const { showError } = useSnackbar();
  const { getOrganizationEmployeeAccountVaultPasswords } = useEmployeeAccountsAPI();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOnRequestError = useCallback(() => {
    showError({ text: t('common.somethingWentWrong') });
  }, [showError, t]);

  const {
    activePage: initialActivePage,
    perPage: initialPerPage,
    searchQuery: initialSearchQuery,
    columnOrderBy: initialColumnOrderBy,
    columnOrderDirection: initialColumnOrderDirection,
    securityScoreName: initialSecurityScoreName,
    ownership: initialOwnership,
  } = useTablePreserverDecoder<ITablePreservationConfigs>(searchParams, PRESERVATION_CONFIGS);

  const [searchQuery, setSearchQuery] = useState<
    IGetOrganizationEmployeeAccountVaultPasswordsParams['searchQuery']
  >(() => initialSearchQuery);
  const [filterValues, setFilterValues] = useState<
    IOrganizationEmployeeLoginsTabFilterSubmitResult
  >({
    ownership: initialOwnership,
    securityScoreName: initialSecurityScoreName,
  });

  const { isFilterActive, numberOfActiveFilters } = useFilterButton(filterValues);

  const {
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedRows: selectedLogins,
    resetTableMethods,
    ...restTableProps
  } = useTable<IOrganizationEmployeeLoginsTableRow>({
    initialActivePage,
    initialPerPage,
    initialColumnOrderBy,
    initialColumnOrderDirection,
  });

  const {
    resetTable,
    resetActivePage,
  } = resetTableMethods;

  const handleTableReset = useCallback(() => {
    resetTable();
    setSearchQuery(TablePreserverCommonInitialTableValues.searchQuery);
    setFilterValues({
      ownership: undefined,
      securityScoreName: TablePreserverCommonInitialTableValues.securityScoreName,
    });
  }, [resetTable]);
  usePubSub(PubSubEventEnum.RESET_TABLE, handleTableReset);

  const {
    data: logins, isLoading, isFetchedAfterMount, total, resetQuery,
  } = useQueryTableFetch({
    queryKey: REACT_QUERY_ORGANIZATION_EMPLOYEE_LOGINS_KEY,
    queryClient,
    request: getOrganizationEmployeeAccountVaultPasswords,
    params: {
      page: activePage,
      pageLength: perPage,
      ownership: filterValues.ownership,
      ...getSecurityScoreStrength(filterValues.securityScoreName),
      searchQuery,
      orderPropertyName: VaultPasswordsOrderProperty[
        upperFirst(columnOrderBy) as keyof typeof VaultPasswordsOrderProperty
      ],
      isDescending: columnOrderDirection ? columnOrderDirection === 'desc' : undefined,
      ...persistentFilters,
    },
    onRequestError: handleOnRequestError,
    autoRefetch: true,
  });

  const { localization } = useTableLocalization({
    searchQuery,
    isFilterActive,
    noDataMessageKey,
    isLoading,
  });

  useTablePreserverActivePageValidator({
    activePage, resetActivePage, perPage, total, isFetchedAfterMount,
  });

  useTablePreserverEncoder<ITablePreservationConfigs>(setSearchParams as TSetSearchParams, {
    activePage,
    perPage,
    searchQuery,
    columnOrderBy,
    columnOrderDirection,
    ownership: filterValues.ownership,
    securityScoreName: filterValues.securityScoreName,
  });

  return {
    logins,
    isLoading,
    total,
    resetQuery,
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedLogins,
    localization,
    searchQuery,
    setSearchQuery,
    filterValues,
    setFilterValues,
    isFilterActive,
    numberOfActiveFilters,
    resetActivePage,
    ...restTableProps,
  };
};

export default useOrganizationEmployeeLoginsTable;
