import {
  memo, useCallback, useState,
} from 'react';
import {
  PanelContent,
  Grid,
  Divider,
  Button,
  useTranslations,
  OrganizationIcon,
  useSnackbar,
  useMapKeyValueExtractor,
  Tooltip,
  ActionButton,
  DeleteIcon,
  PromptModal,
} from '@uniqkey-frontend/shared-app';
import { useRemoveOrganizationsFromGroup } from '../../../../hooks/reactQuery';
import { logException } from '../../../../services/sentryService';
import GroupOrganizationsSelectorModal from '../GroupOrganizationsSelectorModal';
import useGroupOrganizationsForAdminTable
  from '../../../../hooks/tables/useGroupOrganizationsForAdminTable';
import GroupOrganizationsForAdminTable, {
  IGroupOrganizationsForAdminTableRow,
} from '../../../../components/tables/GroupOrganizationsForAdminTable';

interface IGroupOrganizationsForAdminTabProps {
  groupId: string;
}

const GroupOrganizationsForAdminTab = (props: IGroupOrganizationsForAdminTabProps) => {
  const { groupId } = props;
  const { t } = useTranslations();
  const { showError, showSuccess } = useSnackbar();

  const [
    isGroupOrganizationsSelectorModalOpen,
    setIsGroupOrganizationsSelectorModalOpen,
  ] = useState(false);
  const [
    isRemoveOrganizationsFromGroupModalOpen,
    setIsRemoveOrganizationsFromGroupModalOpen,
  ] = useState(false);

  const handleGroupOrganizationsSelectorModalOpen = useCallback(
    () => setIsGroupOrganizationsSelectorModalOpen(true),
    [],
  );
  const handleGroupOrganizationsSelectorModalClose = useCallback(
    () => setIsGroupOrganizationsSelectorModalOpen(false),
    [],
  );

  const handleRemoveOrganizationsFromGroupModalOpen = useCallback(
    () => setIsRemoveOrganizationsFromGroupModalOpen(true),
    [],
  );

  const handleRemoveOrganizationsFromGroupModalClose = useCallback(
    () => setIsRemoveOrganizationsFromGroupModalOpen(false),
    [],
  );

  const {
    selectedGroupOrganizationsForAdmin,
    resetSelectedRows,
    resetActivePage,
    ...restTableProps
  } = useGroupOrganizationsForAdminTable({
    persistentFilters: { groupId },
    noDataMessageKey: 'table.noData.default',
  });

  const {
    mutate: mutateRemoveOrganizationsFromGroup,
    isLoading: isRemoveOrganizationsFromGroupLoading,
  } = useRemoveOrganizationsFromGroup({ isForAdmin: true });

  const {
    values: selectedOrganizationsForAdminAsObjects, keys: selectedOrganizationsForAdminIds,
  } = useMapKeyValueExtractor<
    IGroupOrganizationsForAdminTableRow
  >(selectedGroupOrganizationsForAdmin);

  const handleRemoveOrganizationsFromGroup = useCallback(async () => {
    mutateRemoveOrganizationsFromGroup(
      { groupId, organizationIds: selectedOrganizationsForAdminIds },
      {
        onSuccess: ({ failCount, successCount }) => {
          if (successCount) {
            showSuccess({
              text: t(
                'removeOrganizationsFromGroupModal.successMessage',
                { count: successCount },
              ),
            });
          }
          if (failCount) {
            showError({
              text: t(
                'removeOrganizationsFromGroupModal.errorMessage',
                { count: failCount },
              ),
            });
          }
          handleRemoveOrganizationsFromGroupModalClose();
          resetSelectedRows();
          resetActivePage();
        },
        onError: (e) => {
          showError({ text: t('common.somethingWentWrong') });
          logException(
            e,
            {
              message: 'GroupOrganizationsForAdminTab/handleRemoveOrganizationsFromGroup exception',
            },
          );
        },
      },
    );
  }, [
    mutateRemoveOrganizationsFromGroup,
    handleRemoveOrganizationsFromGroupModalClose,
    groupId,
    selectedOrganizationsForAdminIds,
    resetSelectedRows,
    resetActivePage,
    showError,
    showSuccess,
    t,
  ]);

  return (
    <PanelContent p={0}>
      <Grid item container flexWrap="nowrap" p={1}>
        <Grid item xs={4} container flexWrap="nowrap" spacing={1}>
          <Grid item my={0.5}>
            <Divider orientation="vertical" />
          </Grid>
          <Grid item alignSelf="center">
            <Tooltip title={t('common.remove')}>
              <ActionButton
                width={40}
                height={40}
                onClick={handleRemoveOrganizationsFromGroupModalOpen}
                disabled={!selectedOrganizationsForAdminAsObjects.length}
              >
                <DeleteIcon />
              </ActionButton>
            </Tooltip>
          </Grid>
          <Grid item my={0.5}>
            <Divider orientation="vertical" />
          </Grid>
        </Grid>
        <Grid item xs={8} container justifyContent="flex-end" flexWrap="nowrap">
          <Button
            icon={<OrganizationIcon />}
            onClick={handleGroupOrganizationsSelectorModalOpen}
          >
            {t('groupPage.organizationsTab.addOrganizationButton')}
          </Button>
        </Grid>
      </Grid>
      <Divider />
      <GroupOrganizationsForAdminTable
        groupId={groupId}
        selectedGroupOrganizationsForAdmin={selectedGroupOrganizationsForAdmin}
        {...restTableProps}
      />
      {isGroupOrganizationsSelectorModalOpen && (
        <GroupOrganizationsSelectorModal
          isOpen={isGroupOrganizationsSelectorModalOpen}
          onClose={handleGroupOrganizationsSelectorModalClose}
          groupId={groupId}
          isForAdmin
        />
      )}
      {isRemoveOrganizationsFromGroupModalOpen && (
        <PromptModal
          open={isRemoveOrganizationsFromGroupModalOpen}
          onClose={handleRemoveOrganizationsFromGroupModalClose}
          onSubmit={handleRemoveOrganizationsFromGroup}
          title={t(
            'removeOrganizationsFromGroupModal.title',
            { count: selectedGroupOrganizationsForAdmin.size },
          )}
          description={t('removeOrganizationsFromGroupModal.description')}
          approvalButtonText="common.remove"
          list={selectedOrganizationsForAdminAsObjects}
          renderField="name"
          renderKey="organizationId"
          isLoading={isRemoveOrganizationsFromGroupLoading}
        />
      )}
    </PanelContent>
  );
};

export default memo(GroupOrganizationsForAdminTab);
