import type { Either } from '@uniqkey-frontend/shared-app';
import type { GetPartnerKeysResponse } from '@uniqkey-backend-partner/api-client';
import {
  KeysManagerFetchPartnerKeysError,
  type TKeysManagerFetchPartnerKeysOperationErrors,
} from './errors';
import ApiClientsProvider from '../../../../../common/apiClientsProvider';
import { axiosInstance } from '../../../../../axios';
import { dataExtractor } from '../../../../../helpers/apiClients';

const fetchPartnerKeysOperation = async (): Promise<
  Either<Required<GetPartnerKeysResponse>, TKeysManagerFetchPartnerKeysOperationErrors>
> => {
  try {
    const {
      privateKey,
      publicKey,
      partnerKeyId,
    } = await ApiClientsProvider.getPartnerKeysAPIClient(axiosInstance)
      .apiV1PartnerKeysGet()
      .then(dataExtractor<GetPartnerKeysResponse>());

    return {
      privateKey: privateKey ?? null,
      publicKey,
      partnerKeyId,
    };
  } catch (e) {
    return new KeysManagerFetchPartnerKeysError();
  }
};

export default fetchPartnerKeysOperation;
