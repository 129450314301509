import { FALLBACK_LANGUAGE } from '@uniqkey-frontend/shared-app';
import AllowedLanguageCodeEnum from '../../enums/AllowedLanguageCodeEnum';

export const isLanguageCodeAllowed = (
  languageCode: string,
) => !!AllowedLanguageCodeEnum[languageCode as keyof typeof AllowedLanguageCodeEnum];

export const getActualUILanguageCode = (
  languageCode: string,
) => (isLanguageCodeAllowed(languageCode) ? languageCode : FALLBACK_LANGUAGE);
