import {
  useCallback,
} from 'react';
import { upperFirst } from 'lodash';
import {
  useSnackbar,
  useTable,
  useQueryTableFetch,
  useTranslations,
  useTableLocalization,
  useTablePreserverActivePageValidator,
  useTablePreserverDecoder,
  useTablePreserverEncoder,
  usePubSub,
  buildTablePreserverDecoderConfigItem,
  IUseTableReturn,
  IUseTableResetTableMethodsReturn,
  IUseQueryTableFetchReturn,
  IUseTableLocalizationReturn,
  TUseTablePreserverDecoderConfigs,
  TablePreserverDecoderConfigItemTypeEnum,
  TSetSearchParams,
} from '@uniqkey-frontend/shared-app';
import { PartnerHistoricLicenseOrderProperty } from '@uniqkey-backend-partner/api-client';
import { useSearchParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import PubSubEventEnum from '../../../enums/PubSubEventEnum';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';
import {
  IGetPartnerLicensesHistoryParams,
  TGetPartnerLicensesHistoryMethod,
} from '../../usePartnersAPI/interfaces';
import {
  IPartnerLicensesHistoryTableRow,
} from '../../../components/tables/PartnerLicensesHistoryTable';
import usePartnersAPI from '../../usePartnersAPI';

export const REACT_QUERY_PARTNER_LICENSES_HISTORY_KEY = [
  ReactQueryKeyEnum.PartnerLicensesHistory,
];

interface IPersistentFilters extends Pick<
  IGetPartnerLicensesHistoryParams, 'partnerId'
> {}

export interface IUsePartnerLicensesHistoryTableParams {
  persistentFilters?: IPersistentFilters;
  noDataMessageKey: string;
}

export interface IUsePartnerLicensesHistoryTableReturn extends
  Omit<IUseTableReturn<IPartnerLicensesHistoryTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Pick<IUseTableResetTableMethodsReturn, 'resetActivePage'>,
  Omit<IUseQueryTableFetchReturn<TGetPartnerLicensesHistoryMethod>, 'data' | 'isFetchedAfterMount'>
{
  partnerLicensesHistory: IUseQueryTableFetchReturn<TGetPartnerLicensesHistoryMethod>['data'];
  selectedPartnerLicensesHistory: IUseTableReturn<IPartnerLicensesHistoryTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
}

interface ITablePreservationConfigs extends
  Omit<IGetPartnerLicensesHistoryParams,
    'page' | 'pageLength' | 'partnerId' | 'orderPropertyName' | 'isDescending'
  >,
  Pick<IUseTableReturn<
    IPartnerLicensesHistoryTableRow
  >, 'columnOrderBy' | 'columnOrderDirection'>
{
  activePage: IGetPartnerLicensesHistoryParams['page'];
  perPage: IGetPartnerLicensesHistoryParams['pageLength'];
}

const PRESERVATION_CONFIGS: TUseTablePreserverDecoderConfigs<ITablePreservationConfigs> = {
  activePage: true,
  perPage: true,
  columnOrderBy: buildTablePreserverDecoderConfigItem(
    TablePreserverDecoderConfigItemTypeEnum.ENUM,
    { enumToCheck: PartnerHistoricLicenseOrderProperty },
  ),
  columnOrderDirection: true,
};

const usePartnerLicensesHistoryTable = (
  params: IUsePartnerLicensesHistoryTableParams,
): IUsePartnerLicensesHistoryTableReturn => {
  const {
    persistentFilters,
    noDataMessageKey,
  } = params;

  const queryClient = useQueryClient();
  const { t } = useTranslations();
  const { showError } = useSnackbar();
  const { getPartnerLicensesHistory } = usePartnersAPI();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOnRequestError = useCallback(() => {
    showError({ text: t('common.somethingWentWrong') });
  }, [showError, t]);

  const {
    activePage: initialActivePage,
    perPage: initialPerPage,
    columnOrderBy: initialColumnOrderBy,
    columnOrderDirection: initialColumnOrderDirection,
  } = useTablePreserverDecoder<ITablePreservationConfigs>(searchParams, PRESERVATION_CONFIGS);

  const {
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedRows: selectedPartnerLicensesHistory,
    resetTableMethods,
    ...restTableProps
  } = useTable<IPartnerLicensesHistoryTableRow>({
    initialActivePage,
    initialPerPage,
    initialColumnOrderBy,
    initialColumnOrderDirection,
  });

  const {
    resetTable,
    resetActivePage,
  } = resetTableMethods;

  const handleTableReset = useCallback(() => {
    resetTable();
  }, [resetTable]);
  usePubSub(PubSubEventEnum.RESET_TABLE, handleTableReset);

  const {
    data: partnerLicensesHistory, isLoading, isFetchedAfterMount, total, resetQuery,
  } = useQueryTableFetch({
    queryKey: REACT_QUERY_PARTNER_LICENSES_HISTORY_KEY,
    queryClient,
    request: getPartnerLicensesHistory,
    params: {
      page: activePage,
      pageLength: perPage,
      orderPropertyName: PartnerHistoricLicenseOrderProperty[
        upperFirst(columnOrderBy) as keyof typeof PartnerHistoricLicenseOrderProperty
      ],
      isDescending: columnOrderDirection ? columnOrderDirection === 'desc' : undefined,
      ...persistentFilters,
    },
    onRequestError: handleOnRequestError,
    autoRefetch: true,
  });

  const { localization } = useTableLocalization({
    noDataMessageKey,
    isLoading,
  });

  useTablePreserverActivePageValidator({
    activePage, resetActivePage, perPage, total, isFetchedAfterMount,
  });

  useTablePreserverEncoder<ITablePreservationConfigs>(setSearchParams as TSetSearchParams, {
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
  });

  return {
    partnerLicensesHistory,
    isLoading,
    total,
    resetQuery,
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedPartnerLicensesHistory,
    localization,
    resetActivePage,
    ...restTableProps,
  };
};

export default usePartnerLicensesHistoryTable;
