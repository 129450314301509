import {
  useCallback,
} from 'react';
import { upperFirst } from 'lodash';
import {
  useSnackbar,
  useTable,
  useQueryTableFetch,
  useTranslations,
  useTableLocalization,
  useTablePreserverActivePageValidator,
  useTablePreserverDecoder,
  useTablePreserverEncoder,
  usePubSub,
  buildTablePreserverDecoderConfigItem,
  IUseTableReturn,
  IUseTableResetTableMethodsReturn,
  IUseQueryTableFetchReturn,
  IUseTableLocalizationReturn,
  TUseTablePreserverDecoderConfigs,
  TablePreserverDecoderConfigItemTypeEnum,
  TSetSearchParams,
} from '@uniqkey-frontend/shared-app';
import { useSearchParams } from 'react-router-dom';
import { GroupOrganizationOrderProperty } from '@uniqkey-backend-partner/api-client';
import { useQueryClient } from 'react-query';
import PubSubEventEnum from '../../../enums/PubSubEventEnum';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';
import {
  IGetGroupOrganizationsParams,
  TGetGroupOrganizationsMethod,
} from '../../useGroupsAPI/interfaces';
import { IGroupOrganizationsTableRow } from '../../../components/tables/GroupOrganizationsTable';
import useGroupsAPI from '../../useGroupsAPI';

export const REACT_QUERY_GROUP_ORGANIZATIONS_KEY = [ReactQueryKeyEnum.GroupOrganizations];

interface IPersistentFilters extends Pick<IGetGroupOrganizationsParams, 'groupId'> {}

export interface IUseGroupOrganizationsTableParams {
  persistentFilters?: IPersistentFilters;
  noDataMessageKey: string;
}

export interface IUseGroupOrganizationsTableReturn extends
  Omit<IUseTableReturn<IGroupOrganizationsTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Pick<IUseTableResetTableMethodsReturn, 'resetActivePage' | 'resetSelectedRows'>,
  Omit<IUseQueryTableFetchReturn<TGetGroupOrganizationsMethod>, 'data' | 'isFetchedAfterMount'>
{
  groupOrganizations: IUseQueryTableFetchReturn<TGetGroupOrganizationsMethod>['data'];
  selectedGroupOrganizations: IUseTableReturn<IGroupOrganizationsTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
}

interface ITablePreservationConfigs extends
  Omit<IGetGroupOrganizationsParams,
    'page' | 'pageLength' | 'groupId' | 'orderPropertyName' | 'isDescending'
  >,
  Pick<IUseTableReturn<IGroupOrganizationsTableRow>, 'columnOrderBy' | 'columnOrderDirection'>
{
  activePage: IGetGroupOrganizationsParams['page'];
  perPage: IGetGroupOrganizationsParams['pageLength'];
}

const PRESERVATION_CONFIGS: TUseTablePreserverDecoderConfigs<ITablePreservationConfigs> = {
  activePage: true,
  perPage: true,
  columnOrderBy: buildTablePreserverDecoderConfigItem(
    TablePreserverDecoderConfigItemTypeEnum.ENUM,
    { enumToCheck: GroupOrganizationOrderProperty },
  ),
  columnOrderDirection: true,
};

const useGroupOrganizationsTable = (
  params: IUseGroupOrganizationsTableParams,
): IUseGroupOrganizationsTableReturn => {
  const {
    persistentFilters,
    noDataMessageKey,
  } = params;

  const queryClient = useQueryClient();
  const { t } = useTranslations();
  const { showError } = useSnackbar();
  const { getGroupOrganizations } = useGroupsAPI();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOnRequestError = useCallback(() => {
    showError({ text: t('common.somethingWentWrong') });
  }, [showError, t]);

  const {
    activePage: initialActivePage,
    perPage: initialPerPage,
    columnOrderBy: initialColumnOrderBy,
    columnOrderDirection: initialColumnOrderDirection,
  } = useTablePreserverDecoder<ITablePreservationConfigs>(searchParams, PRESERVATION_CONFIGS);

  const {
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedRows: selectedGroupOrganizations,
    resetTableMethods,
    ...restTableProps
  } = useTable<IGroupOrganizationsTableRow>({
    initialActivePage,
    initialPerPage,
    initialColumnOrderBy,
    initialColumnOrderDirection,
  });

  const {
    resetTable,
    resetActivePage,
    resetSelectedRows,
  } = resetTableMethods;

  const handleTableReset = useCallback(() => resetTable(), [resetTable]);
  usePubSub(PubSubEventEnum.RESET_TABLE, handleTableReset);

  const {
    data: groupOrganizations, isLoading, isFetchedAfterMount, total, resetQuery,
  } = useQueryTableFetch({
    queryKey: REACT_QUERY_GROUP_ORGANIZATIONS_KEY,
    queryClient,
    request: getGroupOrganizations,
    params: {
      page: activePage,
      pageLength: perPage,
      orderPropertyName: GroupOrganizationOrderProperty[
        upperFirst(columnOrderBy) as keyof typeof GroupOrganizationOrderProperty
      ],
      isDescending: columnOrderDirection ? columnOrderDirection === 'desc' : undefined,
      ...persistentFilters,
    },
    onRequestError: handleOnRequestError,
    autoRefetch: true,
  });

  const { localization } = useTableLocalization({
    noDataMessageKey,
    isLoading,
  });

  useTablePreserverActivePageValidator({
    activePage, resetActivePage, perPage, total, isFetchedAfterMount,
  });

  useTablePreserverEncoder<ITablePreservationConfigs>(setSearchParams as TSetSearchParams, {
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
  });

  return {
    groupOrganizations,
    isLoading,
    total,
    resetQuery,
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedGroupOrganizations,
    localization,
    resetActivePage,
    resetSelectedRows,
    ...restTableProps,
  };
};

export default useGroupOrganizationsTable;
