import { memo } from 'react';
import {
  ScrollablePanelContent,
  Grid,
} from '@uniqkey-frontend/shared-app';
import PartnerDetailsWidget from './components/PartnerDetailsWidget';
import ContactPersonDetailsWidget from './components/ContactPersonDetailsWidget';

interface IPartnerDetailsTabProps {
  partnerId: string;
}

const PartnerDetailsTab = (props: IPartnerDetailsTabProps) => {
  const { partnerId } = props;

  return (
    <ScrollablePanelContent p={3} pt={1}>
      <Grid container rowGap={3}>
        <PartnerDetailsWidget partnerId={partnerId} />
        <ContactPersonDetailsWidget partnerId={partnerId} />
      </Grid>
    </ScrollablePanelContent>
  );
};

export default memo(PartnerDetailsTab);
